import React from "react";
import ReactDOM from "react-dom";
import Routing from "./Routing";
import {
  AuthContextProvider,
  DatabaseContextProvider,
} from "./Context/Context";
import { SnackbarProvider } from "notistack";

ReactDOM.render(
  <AuthContextProvider>
    <DatabaseContextProvider initalValue="2022-2023">
      <SnackbarProvider maxSnack={3}>
        <Routing />
      </SnackbarProvider>
    </DatabaseContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);

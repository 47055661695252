import { Button, CircularProgress, Grid } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { DatabaseContext } from "../../Context/Context";
import Calendar from "./Calendar";
import ResponsiveDataPie from "./ResponsivePie";
import CachedIcon from "@material-ui/icons/Cached";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateMenu from "./DateMenu";
import differenceInCalendarYears from "date-fns/differenceInCalendarYears";
import RadBar from "./RadBar";
import {
  fetchCollection,
  fetchIntranetData,
  initDateRange,
  statusList,
} from "./utils";
import {
  buildCalendarStats,
  buildCumulIntranetStats,
  buildCumulStats,
  buildIntranetStats,
  buildLineStats,
  buildSchoolsStats,
  buildStatusStats,
} from "./statsBuilder";
import CustomLine from "./Line";
import CumulativeLine from "./CumulativeLine";
import InfoTiles from "./InfoTiles";

const Stats = () => {
  const { db } = useContext(DatabaseContext);
  const [loading, setLoading] = useState(false);
  const [intraLoading, setIntraLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [cities, setCities] = useState([]);
  const [intra, setIntra] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState(null);
  const [dataBySchool, setDataBySchool] = useState([]);
  const [dataByStatus, setDataByStatus] = useState([]);
  const [dataIntra, setDataIntra] = useState([]);
  const [dataByDay, setDataByDay] = useState([]);
  const [dataLine, setDataLine] = useState([]);
  const [cumulStats, setCumulStats] = useState([]);
  const [cumulIntranetStats, setCumulIntranetStats] = useState([]);
  const [dateRange, setDateRange] = useState(initDateRange);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("links")) {
      setContracts(JSON.parse(localStorage.getItem("links")));
      return;
    }
    fetchCollection("links", setContracts, setLoading, db);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fetch schools
    if (localStorage.getItem("schools")) {
      setSchools(JSON.parse(localStorage.getItem("schools")));
      return;
    }
    fetchCollection("schools", setSchools, setLoading, db);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fetch cities
    if (localStorage.getItem("cities")) {
      setCities(JSON.parse(localStorage.getItem("cities")));
      return;
    }
    fetchCollection("cities", setCities, setLoading, db);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!intra.length) fetchIntranetData(setIntraLoading, setIntra, schools);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schools]);

  useEffect(() => {
    // Use intranet data for stats
    if (!intra.length) return;

    const intranetStats = buildIntranetStats(
      schoolSelected,
      intra,
      contracts,
      schools,
      dateRange
    );
    setDataIntra(intranetStats);
  }, [intra, schoolSelected, dateRange]);

  useEffect(() => {
    if (!schools.length || !contracts.length) return;
    // format data
    const schoolsStats = buildSchoolsStats(schools, contracts, dateRange);
    setDataBySchool(schoolsStats);

    const statusStats = buildStatusStats(
      statusList,
      contracts,
      schoolSelected,
      dateRange
    );
    setDataByStatus(statusStats);

    const calendarStats = buildCalendarStats(
      contracts,
      schoolSelected,
      dateRange
    );
    setDataByDay(calendarStats);

    const lineStats = buildLineStats(
      contracts,
      dateRange,
      schools,
      schoolSelected
    );
    setDataLine(lineStats);

    const cumulStats = buildCumulStats(
      statusList,
      contracts,
      dateRange,
      schools,
      schoolSelected,
      cities
    );
    setCumulStats(cumulStats);

    const cumulIntraStats = buildCumulIntranetStats(
      statusList,
      contracts,
      dateRange,
      schools,
      schoolSelected,
      cities,
      intra
    );
    setCumulIntranetStats(cumulIntraStats);
  }, [schools, contracts, schoolSelected, dateRange, intra]);

  return (
    <Grid container style={{ overflow: "scroll" }}>
      <Grid item xs={12}>
        <h1 style={{ width: "fit-content", margin: "auto", marginBottom: 30 }}>
          Stats
        </h1>
        <Button
          startIcon={
            loading || intraLoading ? (
              <CircularProgress size={20} />
            ) : (
              <CachedIcon />
            )
          }
          style={{ position: "absolute", right: 25, top: 15, zIndex: 9 }}
          variant="outlined"
          onClick={() => {
            setLoading(true);
            setIntraLoading(true);
            fetchCollection("links", setContracts, setLoading, db);
            fetchCollection("schools", setSchools, setLoading, db);
            fetchIntranetData(setIntraLoading, setIntra, schools, true);
          }}
          disabled={loading || intraLoading}
        >
          {loading || intraLoading ? "Chargement" : "Rafraîchir"}
        </Button>
      </Grid>
      {/* Butttons */}
      <Grid
        container
        justifyContent="space-evenly"
        style={{ display: "flex", zIndex: 9 }}
      >
        <Button
          variant="outlined"
          color={!schoolSelected ? "secondary" : "default"}
          onClick={() => {
            setSchoolSelected(null);
          }}
        >
          Tout
        </Button>
        {schools.map((school) => (
          <Button
            key={school.id}
            variant="outlined"
            color={
              schoolSelected === school.school_short_name
                ? "secondary"
                : "default"
            }
            onClick={() => {
              setSchoolSelected(school.school_short_name);
            }}
          >
            {school.school_short_name}
          </Button>
        ))}
        <Button
          style={{ zIndex: 9 }}
          variant="outlined"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          color={dateRange[0].startDate ? "secondary" : "inherit"}
        >
          plage de dates
          {dateRange[0].startDate && dateRange[0].endDate && (
            <>
              <br />
              {new Date(dateRange[0].startDate).toLocaleDateString("fr")} -
              {new Date(dateRange[0].endDate).toLocaleDateString("fr")}
            </>
          )}
        </Button>
      </Grid>
      {/* End buttons */}

      <DateMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        dateRange={dateRange}
        setDateRange={setDateRange}
        initDateRange={initDateRange}
      />
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            // style={{
            //   height: 250,
            // }}
          >
            <InfoTiles
              contracts={contracts}
              intra={intra}
              schoolSelected={schoolSelected}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            xl={6}
            style={{
              height: 550,
            }}
          >
            <CumulativeLine
              data={cumulStats}
              contracts={contracts}
              schoolSelected={schoolSelected}
              title={`Répartition des fiches ${
                schoolSelected ? schoolSelected : ""
              } par statut`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            style={{
              height: 550,
            }}
          >
            <CumulativeLine
              data={cumulIntranetStats}
              contracts={contracts}
              schoolSelected={schoolSelected}
              isIntra={true}
              title={`Fiches ${
                schoolSelected ? schoolSelected : ""
              } crées vs intranet`}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            style={{
              height: 350,
            }}
          >
            <CustomLine data={dataLine} />
          </Grid> */}
          <Grid
            item
            xs={12}
            style={{
              // Add 250px for each calendar year selected in range
              height:
                dateRange[0].startDate &&
                differenceInCalendarYears(
                  dateRange[0].endDate,
                  dateRange[0].startDate
                ) >= 1
                  ? 250 *
                    (differenceInCalendarYears(
                      dateRange[0].endDate,
                      dateRange[0].startDate
                    ) +
                      1)
                  : 250,
            }}
          >
            <Calendar
              title="Contrats créés par jour"
              data={dataByDay}
              fromDate={
                dateRange[0].startDate
                  ? new Date(dateRange[0].startDate).toLocaleDateString("en")
                  : null
              }
              toDate={
                dateRange[0].endDate
                  ? new Date(dateRange[0].endDate).toLocaleDateString("en")
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: 480 }}>
            <ResponsiveDataPie title="Contrats par école" data={dataBySchool} />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: 480 }}>
            <ResponsiveDataPie
              title="Contrats par statut"
              data={dataByStatus}
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4} style={{ height: 430 }}>
            <RadBar data={dataIntra} title="Contrats totaux" />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Stats;

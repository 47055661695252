/* eslint-disable wrap-iife */
import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./app.scss";
import firebase from "firebase/app";
import { useSnackbar } from "notistack";

import AdminForm from "./Components/Form/AdminForm";
import AdminLogin from "./Components/Login/AdminLogin";
import Admin from "./Components/Admin/Admin";
// import Home from './Components/Home/Home';

import Loader from "./Services/Loader";
// import fsDatabase from "./Services/firestoreFunctions";
import Rncp from "./Views/Rncp";
import AdminMenu from "./Components/Admin/Menu/AdminMenu";
import AdminDetails from "./Views/AdminDetails";
import Cfa from "./Views/Cfa";
import Schools from "./Views/Schools";
import Campus from "./Views/Campus";
import Formations from "./Views/Formations";
import SchoolDetails from "./Views/SchoolDetails";
import { CssBaseline } from "@material-ui/core";
import { DatabaseContext } from "./Context/Context";
import RefTables from "./Views/RefTables";
import TableRefDetails from "./Views/RefTables/TableRefDetails";
import Users from "./Components/Admin/Dashboard/Administration/ManageUsers/ManageUsers";
import Stats from "./Views/Stats";

let user;
let perms;
let mainPerm = null;

const Routing = () => {
  // const { db } = useContext(DatabaseContext);
  const { enqueueSnackbar } = useSnackbar();
  const [auth, setAuthState] = useState({
    authenticated: false,
    initializing: true,
  });
  const [uid, setUid] = useState();

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "e01e3db7-e6b8-4963-8fdb-bc2a12b6d92f";

    // eslint-disable-next-line func-names
    (function () {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  const getUsersDatas = async (userId) => {
    const globalDb = firebase.firestore();
    try {
      user = await globalDb.collection("users").doc(userId).get();
      if (user.exists) {
        user = user.data();
        mainPerm = user.main_perm;
      } else user = false;
      perms = await globalDb.collection("perms").doc("perms").get();
      if (perms.exists) perms = perms.data();
      else perms = false;
    } catch (err) {
      console.error(err);
    }

    // user = await fsDatabase.dbGet(db, "users", userId);
    // perms = await fsDatabase.dbGet(db, "perms", "perms");

    if (Object.keys(user).length !== 0 && Object.keys(perms).length !== 0) {
      setAuthState({
        authenticated: true,
        initializing: false,
      });
    } else {
      enqueueSnackbar("Erreur lors de la récupération de l'utilisateur", {
        variant: "error",
      });
      setAuthState({
        authenticated: false,
        initializing: false,
      });
    }
  };

  useEffect(
    () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      firebase.auth().onAuthStateChanged((authDatas) => {
        if (authDatas) {
          setUid(authDatas.uid);
          getUsersDatas(authDatas.uid);
        } else {
          setAuthState({
            authenticated: false,
            initializing: false,
          });
        }
      }),
    [setAuthState]
  );

  return (
    <div className="main-layout">
      <CssBaseline />
      {auth.initializing ? (
        <Loader />
      ) : (
        // TODO : à revoir tout ça
        <Router>
          <div
            className={`admin-interface ${
              auth.authenticated ? "authenticated" : "login"
            }`}
          >
            {auth.authenticated && <AdminMenu user={user} perms={perms} />}
            <Switch>
              {/* <Route path='/login' exact component={AdminLogin} /> */}
              <Route
                path={["/form/:slug"]}
                exact
                render={(props) => (
                  <AdminForm {...props} isAuthed={auth.authenticated} />
                )}
              />
              {/* Super admin */}
              {auth.authenticated && mainPerm === 2 && (
                <>
                  <Route path="/stats" render={(props) => <Stats />} />
                  <Route path="/ref-tables" render={(props) => <RefTables />} />
                  <Route
                    path="/refDetails/:category/"
                    render={(props) => <TableRefDetails {...props} />}
                  />
                  <Route path="/rncp" render={(props) => <Rncp />} />
                  <Route path="/cfa" render={(props) => <Cfa />} />
                  <Route path="/schools" render={(props) => <Schools />} />
                  <Route path="/campus" render={(props) => <Campus />} />
                  <Route
                    path="/admindetails/:category/:id/:formation_id?/"
                    render={(props) => <AdminDetails {...props} />}
                  />
                  <Route
                    path="/schoolDetails/:id"
                    render={(props) => <SchoolDetails {...props} />}
                  />
                  <Route
                    path="/users"
                    render={(props) => <Users {...props} />}
                  />
                  <Route
                    path="/formations"
                    render={(props) => <Formations />}
                  />
                  <Route
                    path="/contracts"
                    render={(props) => (
                      <Admin {...props} user={user} uid={uid} perms={perms} />
                    )}
                  />
                  <Route path="/" exact>
                    <Redirect from="/" to="/contracts" />
                  </Route>
                </>
              )}
              {/* Admin */}
              {auth.authenticated && user?.main_perm === 0 && (
                <>
                  <Route path="/rncp" render={(props) => <Rncp />} />
                  <Route path="/cfa" render={(props) => <Cfa />} />
                  <Route path="/schools" render={(props) => <Schools />} />
                  <Route path="/campus" render={(props) => <Campus />} />
                  <Route
                    path="/admindetails/:category/:id/:formation_id?/"
                    render={(props) => <AdminDetails {...props} />}
                  />
                  <Route
                    path="/schoolDetails/:id"
                    render={(props) => <SchoolDetails {...props} />}
                  />
                  <Route
                    path="/users"
                    render={(props) => <Users {...props} />}
                  />
                  <Route
                    path="/formations"
                    render={(props) => <Formations />}
                  />
                  <Route
                    path="/contracts"
                    render={(props) => (
                      <Admin {...props} user={user} uid={uid} perms={perms} />
                    )}
                  />
                  <Route path="/" exact>
                    <Redirect from="/" to="/contracts" />
                  </Route>
                </>
              )}
              {/* RE */}
              {auth.authenticated && user?.main_perm === 1 ? (
                <>
                  {/* TODO => TO REACTIVATE LATER */}
                  {/* <Route
                    path="/formations"
                    render={(props) => <Formations />}
                  />
                  <Route
                    path="/admindetails/:category/:id/:formation_id?/"
                    render={(props) => <AdminDetails {...props} />}
                  /> */}
                  <Route
                    path="/contracts"
                    render={(props) => (
                      <Admin {...props} user={user} uid={uid} perms={perms} />
                    )}
                  />
                  <Route path="/" exact>
                    <Redirect from="/" to="/contracts" />
                  </Route>
                  {/* <Route
                    path={["/:slug"]}
                    exact
                    render={(props) => <Admin {...props} user={user} uid={uid} perms={perms} />}
                  /> */}
                </>
              ) : (
                <>
                  <Route path="/login" component={AdminLogin} />
                  <Route path="/">
                    <Redirect from="/" to="/login" />
                  </Route>
                </>
              )}
            </Switch>
          </div>
        </Router>
      )}
    </div>
  );
};

export default Routing;

import Grid from "@material-ui/core/Grid";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Search from "@material-ui/icons/Search";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import firebase from "../../../firebase";
import { useSnackbar } from "notistack";
import AddForm from "../AddForm";
import CustomTable from "../../CustomTable";
import { DatabaseContext } from "../../../Context/Context";

const fields = [
  // {
  //   label: "Niveau",
  //   id: "academic_grade",
  //   type: "select",
  //   list: GRADES,
  // },
  {
    label: "Niveau",
    id: "academic_grade",
    type: "select",
    remote: "/grades",
    endpoint: "grade_name",
  },
  {
    id: "academic_short_title",
    label: "Titre court",
  },
  {
    id: "academic_title",
    label: "Titre académique",
  },
  {
    id: "academic_code",
    label: "Code Intranet",
  },
  {
    label: "Titre RNCP",
    id: "linked_rncp",
    type: "select",
    remote: "/rncp",
    endpoint: "rncp_exact_title",
  },
];

const columns = [
  {
    title: "Niveau",
    field: "academic_grade",
    filtering: false,
    // width: "8%",
  },
  {
    title: "Titre court",
    field: "academic_short_title",
    filtering: false,
  },
  {
    title: "Libellé formation",
    field: "academic_exact_title",
    filtering: false,
  },
];

const Formations = ({ schoolDocRef, sigle, school }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { db } = useContext(DatabaseContext);

  const formatData = async (result) => {
    if (!result) return [];
    const grades = [];
    let formattedData = await db
      .collection("grades")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          grades.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        return result.map((formation) => {
          let output = { ...formation };

          output.academic_grade = grades.find(
            (grade) => grade.id === formation.academic_grade
          )?.grade_name;
          return output;
        });
      });
    setData(formattedData);
  };

  const actions = [
    {
      icon: Search,
      tooltip: "Voir les détails de la formation",
      onClick: (_event, rowData) =>
        history.push(
          `/admindetails/formations_canvas/${rowData.id}/${school.id}`
        ),
    },
    {
      icon: DeleteOutline,
      tooltip: "Supprimer la formation",
      onClick: (_event, rowData) =>
        history.push(`/admindetails/formations_canvas/${rowData.id}`),
    },
  ];

  const getDatas = () => {
    const formationsData = [];
    // formationsRef
    db.collection("formations_canvas")
      .where("school_id", "==", school.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          formationsData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        formatData(formationsData);
      });
  };

  useEffect(() => {
    getDatas();
  }, []);

  const createFormationsInCampus = async (data, formationId, grade) => {
    try {
      let batch = firebase.firestore().batch();
      let formationsCampusRef;
      db.collection("campus")
        .where("school_id", "==", school.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            formationsCampusRef = db
              .collection("campus")
              .doc(doc.id)
              .collection("formations")
              .doc(formationId);

            batch.set(formationsCampusRef, {
              ...data,
              campus_city: doc.data().city,
              school_id: school.id,
              school_abbreviation: school.school_short_name,
              school_label: school.school_full_name,
              academic_exact_title: `${grade.grade_name} ${data.academic_title}`,
              is_active: false,
              formation_id: formationId,
            });
          });
        })
        .then(() =>
          batch.commit().then(() => {
            enqueueSnackbar(
              "Formations créées dans les campus de cette école",
              {
                variant: "success",
              }
            );
          })
        );
    } catch (err) {
      console.log("error creating formations in campus", err);
      enqueueSnackbar(
        "Les formations n'ont pas été créées dans les campus de cette école, contacter un super administrateur.",
        {
          variant: "error",
        }
      );
    }
  };

  const handleAdd = async (data) => {
    const grade = await db
      .collection("grades")
      .doc(data.academic_grade)
      .get()
      .then((doc) => doc.data());

    const formatedData = {
      ...data,
      school_id: school.id,
      school_abbreviation: school.school_short_name,
      school_label: school.school_full_name,
      academic_exact_title: `${grade.grade_name} ${data.academic_title}`,
    };

    db.collection("formations_canvas")
      .add(formatedData)
      .then((formationRef) => {
        enqueueSnackbar("Formation ajoutée", {
          variant: "success",
        });
        // Increment formation counter
        schoolDocRef.update({
          nbr_formations: firebase.firestore.FieldValue.increment(1),
        });

        createFormationsInCampus(data, formationRef.id, grade);

        setShow(false);
        getDatas();
      });
  };

  return (
    <Grid container className="school-details-formations">
      <Grid item xs={12} className="formations-item">
        {/* <CustomBreadcrumb breadcrumbPath={breadcrumbPath} /> */}
        {/* <Button>Ajouter un titre RNPC</Button> */}
        <AddForm
          fields={fields}
          onSubmit={handleAdd}
          show={show}
          setShow={setShow}
        />
        <CustomTable
          title="Liste des formations"
          columns={columns}
          data={data}
          actions={actions}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalElements={totalElements}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filter={false}
          // noSearch={true}
          autoFocus={true}
        />
      </Grid>
    </Grid>
  );
};

export default Formations;

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import AutoCompleteUser from "./AutocompleteUser";
import { fields } from "./utils";
import CustomField from "../../../DetailsContent/CustomField";

const ModifyUser = ({ globalDb, enqueueSnackbar, perms }) => {
  const [users, setUsers] = useState([]);
  const [mainPerm, setMainPerm] = useState([]);
  const [schoolPerms, setSchoolPerms] = useState([]);
  const [cityPerms, setCityPerms] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    globalDb
      .collection("users")
      .get()
      .then((snapshot) => {
        const remoteUsers = [];
        snapshot.forEach((el) =>
          remoteUsers.push({
            ...el.data(),
            id: el.id,
          })
        );
        return remoteUsers;
      })
      .then((el) => setUsers(el));
  }, [globalDb]);

  useEffect(() => {
    if (!selectedUser) return;
    setMainPerm(selectedUser.main_perm);
    setSchoolPerms(selectedUser.school_perms);
    setCityPerms(selectedUser.city_perms);
  }, [selectedUser]);

  const onChange = (e, id) => {
    setSelectedUser({
      ...selectedUser,
      [id]: e.target.value,
    });
  };

  const handleSave = () => {
    globalDb
      .collection("users")
      .doc(selectedUser.id)
      .update({
        ...selectedUser,
        city_perms: cityPerms,
        school_perms: schoolPerms,
        main_perm: mainPerm,
        fullname: `${selectedUser.firstname} ${selectedUser.lastname}`,
      })
      .then(() => {
        enqueueSnackbar(`Profil de l'utilisateur mis à jour.`, {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(`Erreur : ${err}`, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <AutoCompleteUser
        selectedUsers={users}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      {selectedUser && (
        <Grid container>
          {selectedUser &&
            fields.map((field) => {
              return (
                <Grid
                  key={field.id}
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ paddingRight: 10 }}
                >
                  <CustomField
                    type={field?.type}
                    field={field}
                    value={selectedUser?.[field.id] || ""}
                    onChange={(e) => onChange(e, field.id)}
                  />
                </Grid>
              );
            })}
          <Grid container style={{ marginTop: 30 }}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl style={{ width: "90%" }}>
                <InputLabel id="main-perm-select-label">
                  Permission principale
                </InputLabel>
                <Select
                  labelId="main-perm-select-label"
                  id="main-perm-select"
                  name="main_perm"
                  value={mainPerm}
                  onChange={(e) => setMainPerm(e.target.value)}
                  fullWidth
                >
                  {perms &&
                    perms.main_perm.map((perm, index) => (
                      <MenuItem value={index} key={perm}>
                        {perm}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl style={{ width: "90%" }}>
                <InputLabel id="school-perm-select-label">
                  Permission d'école
                </InputLabel>
                <Select
                  labelId="school-perm-select-label"
                  id="school-perm-select"
                  name="school_perms"
                  value={schoolPerms}
                  onChange={(e) => setSchoolPerms(e.target.value)}
                  fullWidth
                  multiple
                >
                  {perms &&
                    perms.school_perms.map((perm, index) => (
                      <MenuItem value={index} key={perm}>
                        {perm}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl style={{ width: "90%" }}>
                <InputLabel id="city-perm-select-label">
                  Permission de ville
                </InputLabel>
                <Select
                  labelId="city-perm-select-label"
                  id="city-perm-select"
                  name="city_perms"
                  value={cityPerms}
                  onChange={(e) => setCityPerms(e.target.value)}
                  fullWidth
                  multiple
                >
                  {perms &&
                    perms.city_perms.map((perm, index) => (
                      <MenuItem value={index} key={perm}>
                        {perm}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Button
              // type="submit"
              onClick={handleSave}
              variant="outlined"
              style={{
                margin: "auto",
                width: "fit-content",
                marginTop: 20,
              }}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ModifyUser;

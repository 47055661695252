// import LockOpenIcon from '@material-ui/icons/LockOpen';
// import LockIcon from '@material-ui/icons/Lock';
// import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { GRADES } from "../utils";
// import PrintIcon from "@mui/icons-material/Print";

const detailsStructure = {
  rncp: {
    msgDelete:
      "Vous vous apprêtez à supprimer les données de ce titre RNCP ainsi que de tous les lots qui y sont associés. Souhaitez-vous poursuivre ?",
    options: [
      {
        id: "delete",
        color: "#ee4646",
        name: "Supprimer",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    content: [
      {
        title: "Informations générales",
        fields: [
          {
            label: "Niveau",
            id: "rcnp_level",
            type: "select",
            remote: "/rncp_titles",
            endpoint: "rncp_level_label",
          },
          { label: "Intitulé du titre", id: "rncp_title" },
          { label: "Titre RNCP", id: "rncp_exact_title", disabled: true },
          { label: "Numéro RNCP", id: "rncp_number" },
          { label: "Code diplôme", id: "rncp_diploma_code" },
          {
            label: "Niveau du titre visé",
            id: "rncp_goal",
            type: "select",
            remote: "/rncp_goals",
            endpoint: "rncp_goal_label",
          },
        ],
      },
    ],
  },
  cfa: {
    msgDelete:
      "Vous vous apprêtez à supprimer les données de ce CFA ainsi que de tous les lots qui y sont associés. Souhaitez-vous poursuivre ?",
    options: [
      {
        id: "delete",
        color: "#ee4646",
        name: "Supprimer",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    content: [
      {
        title: "Informations générales",
        fields: [
          { label: "Nom du CFA", id: "cfa_name" },
          {
            label: "CFA d'entreprise",
            id: "cfa_type",
            type: "boolean",
            unrequired: true,
          },
          {
            label: "Numéro Siret",
            id: "cfa_siret",
            type: "siret",
            format: "### ### ### ### ##",
          },
          {
            label: "Capital social",
            id: "cfa_capital",
            type: "euros",
            // format: "# ### ###,##",
          },
          {
            label: "Numéro Siren",
            id: "cfa_siren",
            type: "siren",
            format: "### ### ###",
            disabled: true,
          },
          {
            label: "Numéro NDA",
            id: "cfa_nda_code",
            type: "nda",
            format: "## ### ### ###",
          },
          { label: "Numéro UAI", id: "cfa_uai_code" },
          { label: "Numéro APE", id: "cfa_naf_code" },
        ],
      },
      {
        title: "Adresse",
        fields: [
          { label: "Représentant du CFA", id: "cfa_director_name" },
          { label: "Fonction du représentant", id: "cfa_director_function" },
          { label: "Adresse", id: "cfa_adress" },
          { label: "Ville", id: "cfa_city" },
          { label: "Code postal", id: "cfa_zip_code", type: "zip" },
          { label: "Région", id: "cfa_state" },
          {
            label: "Téléphone",
            id: "cfa_tel",
            type: "phone",
            format: "## ## ## ## ##",
          },
          { label: "eMail", id: "cfa_mail", type: "email" },
        ],
      },
      {
        title: "Responsable RGPD",
        fields: [
          { label: "Nom DPO", id: "cfa_dpo_name" },
          {
            label: "Téléphone DPO",
            id: "cfa_dpo_tel",
            type: "phone",
            format: "## ## ## ## ##",
          },
          { label: "eMail DPO", id: "cfa_dpo_mail", type: "email" },
        ],
      },
      {
        title: "Éléments liés",
        fields: [
          {
            label: "École(s)",
            id: "schools_short_name",
            type: "multiselect",
            remote: "/schools",
            endpoint: "school_short_name",
          },
          {
            label: "Titre(s) RNCP rattaché(s)",
            id: "cfa_rncp",
            type: "multiselect",
            remote: "/rncp",
            endpoint: "rncp_exact_title",
          },
        ],
      },
    ],
  },
  campus: {
    msgDelete:
      "Vous vous apprêtez à supprimer les données de cette école ainsi que de tous les lots qui y sont associés. Souhaitez-vous poursuivre ?",
    options: [
      {
        id: "delete",
        color: "#ee4646",
        name: "Supprimer",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    content: [
      {
        title: "Représentant",
        fields: [
          { label: "Représentant", id: "school_director_name" },
          { label: "Titre du représentant", id: "school_director_function" },
        ],
      },
      {
        title: "Adresse",
        fields: [
          { label: "Adresse", id: "school_adress" },
          {
            label: "Ville",
            id: "city",
            remote: "/cities",
            endpoint: "label",
            disabled: true,
          },
          { label: "Code postal", id: "school_zip_code", type: "zip" },
          { label: "École", id: "school_abbreviation", disabled: true },
          {
            label: "Téléphone",
            id: "school_tel",
            type: "phone",
            format: "## ## ## ## ##",
          },
          { label: "eMail", id: "school_mail", type: "email" },
        ],
      },
      {
        title: "Formations",
        id: "formations",
      },
    ],
  },
  formations: {
    msgDelete:
      "Vous vous apprêtez à supprimer les données de cette formation ainsi que de tous les lots qui y sont associés. Souhaitez-vous poursuivre ?",
    options: [
      {
        id: "delete",
        color: "#ee4646",
        name: "Supprimer",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    content: [
      {
        title: "Formation",
        fields: [
          {
            label: "Niveau",
            id: "academic_grade",
            type: "select",
            remote: "/grades",
            endpoint: "grade_name",
            disabled: true,
          },
          {
            label: "Libellé Court",
            id: "academic_short_title",
            disabled: true,
          },
          {
            label: "Libéllé de la formation",
            id: "academic_exact_title",
            disabled: true,
          },
        ],
      },
      {
        title: "Rythme & Coût",
        fields: [
          {
            label: "Date de début de formation",
            id: "academic_start_date",
            type: "date",
          },
          {
            label: "Date de fin de formation",
            id: "academic_end_date",
            type: "date",
          },
          {
            label: "Durée de la formation",
            id: "academic_hours",
            type: "days",
          },
          {
            label: "Rythme de la formation",
            id: "academic_rythm",
            type: "textarea",
          },
          {
            label: "Coût de la formation",
            id: "cost_year_1",
            type: "euros",
          },
          {
            label: "Coût de la 2ème année de formation",
            id: "cost_year_2",
            type: "euros",
            remoteCondition: "grades",
            remoteConditionEndpoint: "nbr_year",
            conditionValue: "academic_grade",
            unrequired: true,
          },
        ],
      },
      {
        title: "Documents",
        fields: [
          {
            label: "Calendrier Entreprise",
            id: "academic_calendar",
            type: "upload",
            // unrequired: true,
          },
          // {
          //   label: "Calendrier Entreprise 23-24",
          //   id: "academic_calendar_year_2",
          //   type: "upload",
          //   remoteCondition: "grades",
          //   remoteConditionEndpoint: "nbr_year",
          //   conditionValue: "academic_grade",
          //   unrequired: true,

          // },
          {
            label: "Programme de Formation",
            id: "academic_programm",
            type: "upload",
            // unrequired: true,
          },
          // {
          //   label: "Programme de Formation 23-24",
          //   id: "academic_programm_year_2",
          //   type: "upload",
          //   remoteCondition: "grades",
          //   remoteConditionEndpoint: "nbr_year",
          //   conditionValue: "academic_grade",
          //   unrequired: true,
          // },
        ],
      },
      {
        title: "Responsable de la formation",
        fields: [
          {
            label: "Nom",
            id: "academic_campus_contact",
            type: "select",
            fromOrigin: true,
            remote: "/users",
            endpoint: "fullname",
            unrequired: true,
          },
          {
            label: "Fonction",
            id: "academic_campus_contact_function",
            disabled: true,
            unrequired: true,
          },
          {
            label: "Téléphone",
            id: "academic_campus_contact_tel",
            disabled: true,
            unrequired: true,
          },
          {
            label: "eMail",
            id: "academic_campus_contact_mail",
            disabled: true,
            unrequired: true,
          },
        ],
      },
    ],
  },
  formations_canvas: {
    msgDelete:
      "Vous vous apprêtez à supprimer les données de cette formation ainsi que de tous les lots qui y sont associés. Souhaitez-vous poursuivre ?",
    options: [
      {
        id: "delete",
        color: "#ee4646",
        name: "Supprimer",
        icon: <DeleteIcon />,
      },
      {
        id: "back",
        name: "Retour à la liste",
        icon: <KeyboardBackspaceIcon />,
      },
    ],
    content: [
      {
        title: "informations",
        fields: [
          {
            label: "Niveau",
            id: "academic_grade",
            type: "select",
            remote: "/grades",
            endpoint: "grade_name",
          },
          { label: "Titre court", id: "academic_short_title" },
          { label: "Titre académique", id: "academic_title" },
          {
            label: "Libellé formation",
            id: "academic_exact_title",
            disabled: true,
          },
          {
            id: "academic_code",
            label: "Code Intranet",
            unrequired: true,
          },
          {
            label: "Titre RNCP",
            id: "linked_rncp",
            type: "select",
            remote: "/rncp",
            endpoint: "rncp_exact_title",
          },
        ],
      },
    ],
  },
};

const interpretContent = (val, name, category, isAccess, type, suffixe, id) => {
  if (val === true || (type === "boolean" && val)) {
    return "Oui";
  } else if (val === false || (type === "boolean" && !val)) {
    return "Non";
  } else if (!val && category === "residences" && !isAccess) {
    return `Aucun(e) ${name?.toLowerCase()} n'est rattaché à cette résidence`;
  } else if (type === "date") {
    return isNaN(Date.parse(val))
      ? "Aucune date définie"
      : new Date(val).toLocaleDateString("fr");
  } else if (val === undefined) {
    if (category === "pdf") return "?";
    return "Non renseigné";
  } else if (id === "noteFin") {
    let result = "";
    switch (val) {
      case "N10":
        result = "Client transféré au responsable des visites";
        break;
      case "N20":
        result = "Numéro du chargé de visite communiqué";
        break;
      case "N30":
        result = "Client non intéressé";
        break;
      case "N40":
        result = "Client hors cible (hors critère d'éligibilité)";
        break;
      case "N50":
        result = "Client à recontacter";
        break;
      case "N99":
        result = "Autres motifs";
        break;

      default:
        result = "Information manquante";
        break;
    }
    return result;
  } else {
    return `${val}${
      suffixe
        ? type === "number" &&
          (parseFloat(val) % 1 === 0 || parseInt(val) === 0)
          ? ".00" + suffixe
          : " " + suffixe
        : ""
    }`;
  }
};

export { detailsStructure, interpretContent };

import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { useSnackbar } from "notistack";
import fsDatabase from "../../../Services/firestoreFunctions";
import { DatabaseContext } from "../../../Context/Context";

const EditIdentity = ({ identity, closeModal, slug }) => {
  const { db } = useContext(DatabaseContext);
  const { enqueueSnackbar } = useSnackbar();
  const [formDatas, setFormDatas] = useState({
    lastname: identity.apprentice_last_name.value,
    firstname: identity.apprentice_first_name.value,
  });

  const handleChangeInput = (event) => {
    const key = event.target.name;
    let value = event.target.value;
    if (key === "firstname") {
      if (value.length >= 1) {
        let words = value.split(" ");
        for (let i = 0; i < words.length; i++) {
          console.log(words.length);
          words[i].length >= 1 &&
            (words[i] = words[i][0].toUpperCase() + words[i].substr(1));
        }
        value = words.join(" ");
      }
    } else if (key === "lastname") {
      value = value.toUpperCase();
    }
    setFormDatas({
      ...formDatas,
      [key]: value,
    });
  };

  const setNewIdentity = () => {
    let fsDatasLinks = {
      identity: {
        apprentice_last_name: {
          value: formDatas.lastname,
        },
        apprentice_first_name: {
          value: formDatas.firstname,
        },
      },
    };
    let fsDatasApprentices = {
      general: {
        apprentice_last_name: {
          value: formDatas.lastname,
        },
        apprentice_first_name: {
          value: formDatas.firstname,
        },
      },
    };
    fsDatabase.dbWrite(db, "links", slug, fsDatasLinks);
    fsDatabase.dbWrite(db, "apprentices", slug, fsDatasApprentices);
    enqueueSnackbar("Les données de l'étudiant ont bien été mise à jours", {
      variant: "success",
    });
    closeModal(false);
  };

  return (
    <div className="modal-change-infos">
      <div className="modal-header">
        <h1 className="title">Modification du contrat</h1>
      </div>
      <div className="modal-form">
        <div className="input-line">
          <div className="ctn-input-label">
            <InputLabel className="label">
              {identity.apprentice_last_name.label}
            </InputLabel>
          </div>
          <div className="ctn-input">
            <TextField
              name="lastname"
              autoComplete="no"
              value={formDatas.lastname}
              variant="outlined"
              onChange={handleChangeInput}
              fullWidth={true}
            />
          </div>
        </div>
        <div className="input-line">
          <div className="ctn-input-label">
            <InputLabel className="label">
              {identity.apprentice_first_name.label}
            </InputLabel>
          </div>
          <div className="ctn-input">
            <TextField
              name="firstname"
              autoComplete="no"
              value={formDatas.firstname}
              variant="outlined"
              onChange={handleChangeInput}
              fullWidth={true}
            />
          </div>
        </div>
      </div>
      <div className="buttons">
        <div className="ctn-button">
          <Button
            size="large"
            color="primary"
            className="button button-pass"
            onClick={() => closeModal(false)}
          >
            Annuler
          </Button>
          <Button
            disabled={
              formDatas.firstname.length > 0 && formDatas.lastname.length > 0
                ? false
                : true
            }
            size="large"
            variant="contained"
            color="primary"
            className="button button-change"
            onClick={setNewIdentity}
          >
            Mettre à jour
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditIdentity;

// import React from 'react';
import fsDatabase from "./firestoreFunctions";

const sectionAdd = (db, datas, collection, state, slug, path) => {
  const newDatas = {
    ...datas,
    [collection[state]]: {
      ...datas[collection[state]],
      section_settings: {
        ...datas[collection[state]].section_settings,
        is_active: true,
      },
    },
  };
  fsDatabase.dbWrite(db, path, slug, newDatas);
};

const sectionRemove = (
  db,
  datas,
  collection,
  state,
  slug,
  path,
  sectionClear
) => {
  const nbSectionActive = state - 1;
  const newDatas = {
    ...datas,
    [collection[nbSectionActive]]: sectionClear[collection[nbSectionActive]],
  };
  fsDatabase.dbWrite(db, path, slug, newDatas);
};

const sectionDisplayOne = (
  db,
  datas,
  collection,
  state,
  slug,
  path,
  active
) => {
  const newDatas = {
    ...datas,
    [collection]: {
      ...datas[collection],
      section_settings: {
        ...datas[collection].section_settings,
        is_active: active,
      },
    },
  };
  fsDatabase.dbWrite(db, path, slug, newDatas);
};

const sectionClearOne = (db, datas, collection, slug, path, sectionClear) => {
  const newDatas = {
    ...datas,
    [collection]: sectionClear[collection],
  };
  fsDatabase.dbWrite(db, path, slug, newDatas);
};

const manageFormSection = {
  sectionAdd,
  sectionRemove,
  sectionDisplayOne,
  sectionClearOne,
};

export default manageFormSection;

import React, { createContext, useState, useEffect } from "react";
import firebase from "../firebase";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [auth, setAuth] = useState(() => {
    const localData = localStorage.getItem("auth");
    return localData ? JSON.parse(localData) : false;
  });

  useEffect(() => {
    localStorage.setItem("auth", JSON.parse(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
};

const DatabaseContext = createContext();

const DatabaseContextProvider = ({ initalValue, children }) => {
  const [year, setYear] = useState(initalValue);
  const [db, setDb] = useState(
    firebase.firestore().collection("academic_year").doc(year)
  );

  useEffect(() => {
    const academic_year = sessionStorage.getItem("academic_year");
    if (!academic_year) sessionStorage.setItem("academic_year", year);
    else setYear(academic_year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDb(firebase.firestore().collection("academic_year").doc(year));
    sessionStorage.setItem("academic_year", year);
  }, [year]);

  return (
    <DatabaseContext.Provider value={{ db: db, year: year, setYear: setYear }}>
      {children}
    </DatabaseContext.Provider>
  );
};

export { AuthContextProvider, DatabaseContext, DatabaseContextProvider };

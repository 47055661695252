import React, { useState, useEffect, useContext } from "react";
import Form from "../FormStructure";
import Button from "@material-ui/core/Button";
import sectionClear from "../../../Templates/renumeration.json";
import mfs from "../../../Services/manageFormSection";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { DatabaseContext } from "../../../Context/Context";

const Remuneration = ({ slug, datas, path, filter }) => {
  const { db } = useContext(DatabaseContext);
  const collectionDuration = ["contract_date_duration"];
  const collectionSalary = ["apprentice_salary"];
  const collectionYears = [
    "first_year",
    "second_year",
    "third_year",
    "fourth_year",
  ];
  const remMin = 1;
  const remMax = 4;
  const [remState, setRem] = useState();
  const [salaryState, setSalary] = useState(0);

  useEffect(() => {
    getActiveRem();
    getActiveSalary();
  }, [datas]);

  const getActiveRem = () => {
    let nbSectionActive = 0;
    for (var key in datas) {
      if (collectionYears.filter((col) => col == key).length == 1) {
        datas[key].section_settings.is_active && nbSectionActive++;
      }
    }
    setRem(nbSectionActive);
  };

  const getActiveSalary = () => {
    let nbSectionActive = 0;
    for (var key in datas) {
      if (collectionSalary.filter((col) => col == key).length == 1) {
        datas[key].section_settings.is_active && nbSectionActive++;
      }
    }
    setSalary(nbSectionActive);
  };

  const addRem = () => {
    if (remState <= remMax) {
      mfs.sectionAdd(db, datas, collectionYears, remState, slug, path);
    }
  };

  const removeRem = () => {
    if (remState >= remMin) {
      mfs.sectionRemove(
        db,
        datas,
        collectionYears,
        remState,
        slug,
        path,
        sectionClear
      );
    }
  };

  const changeSalary = () => {
    if (salaryState == 0) {
      mfs.sectionDisplayOne(
        db,
        datas,
        collectionSalary,
        salaryState,
        slug,
        path,
        true
      );
    } else {
      mfs.sectionClearOne(
        db,
        datas,
        collectionSalary,
        slug,
        path,
        sectionClear
      );
    }
  };

  return (
    <div className="ctn-remuneration">
      {collectionDuration.map((item) => (
        <Form
          key={item}
          newDatas={datas[item]}
          path={path}
          objectName={item}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      ))}
      <FormControl
        className={
          salaryState == 0 ? "display-section close" : "display-section open"
        }
      >
        <FormLabel className="display-section-title">
          L'apprenti bénéficie t'il d'avantages en nature ?
        </FormLabel>
        <RadioGroup
          className="display-section-radio"
          row
          value={salaryState}
          onChange={changeSalary}
        >
          <FormControlLabel
            className="display-section-radio-label"
            key="yes"
            value={1}
            control={<Radio />}
            label="Oui"
          />
          <FormControlLabel
            className="display-section-radio-label"
            key="no"
            value={0}
            control={<Radio />}
            label="Non"
          />
        </RadioGroup>
      </FormControl>
      {salaryState >= 1 && (
        <Form
          key={collectionSalary[0]}
          newDatas={datas[collectionSalary[0]]}
          path={path}
          objectName={collectionSalary[0]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      {remState >= 1 && (
        <Form
          key={collectionYears[0]}
          newDatas={datas[collectionYears[0]]}
          path={path}
          objectName={collectionYears[0]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      {remState >= 2 && (
        <Form
          key={collectionYears[1]}
          newDatas={datas[collectionYears[1]]}
          path={path}
          objectName={collectionYears[1]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      {remState >= 3 && (
        <Form
          key={collectionYears[2]}
          newDatas={datas[collectionYears[2]]}
          path={path}
          objectName={collectionYears[2]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      {remState >= 4 && (
        <Form
          key={collectionYears[3]}
          newDatas={datas[collectionYears[3]]}
          path={path}
          objectName={collectionYears[3]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      <div className="ctn-button">
        <Button
          className="button delete-button"
          disabled={remState == remMin ? true : false}
          disize="large"
          color="primary"
          onClick={removeRem}
        >
          <DeleteOutlineRoundedIcon className="icon delete-icon" />
          Supprimer la dernière année
        </Button>
        <Button
          className="button add-button"
          disabled={remState == remMax ? true : false}
          disize="large"
          color="primary"
          onClick={addRem}
        >
          <AddRoundedIcon className="icon add-icon" />
          Ajouter une année
        </Button>
      </div>
    </div>
  );
};

export default Remuneration;

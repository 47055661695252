import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from '@material-ui/core/FormLabel';
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
// import InputAdornment from '@material-ui/core/InputAdornment';
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import { useSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
// import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import FilterContext from "../../Context/Filter";
import fsDatabase from "../../Services/firestoreFunctions";
import { DatabaseContext } from "../../Context/Context";
import { computeIdccCost, updatePriceInfo } from "./utils";

const Form = ({
  newDatas,
  slug,
  path,
  objectName,
  copyBdd,
  singleInput,
  formEdit,
  diplomaCode,
  formation,
}) => {
  const { db } = useContext(DatabaseContext);
  const { enqueueSnackbar } = useSnackbar();
  const { filter } = useContext(FilterContext);
  const [fields, setFields] = useState([]);
  const [currentFocus, setCurrentFocus] = useState(null);
  // const actualFocus = null;
  const [datas, setDatas] = useState(newDatas);
  // const [, setUpdates] = useState({});
  let fsDatas;
  const inputs = [];
  const lock = !singleInput ? newDatas.section_settings.is_locked : false;
  const sectionActive = !singleInput && newDatas.section_settings.is_active;
  const isVisible = !singleInput && newDatas.section_settings.is_visible;

  // COPY DATAS
  const copyDatas = (tempDatas) => {
    let copiedDatas = {};
    // if (copyBdd.collection === "company_billing_adress") {
    if (copyBdd.collection.includes("company_billing_adress")) {
      copiedDatas = {
        company_billing_number_street: tempDatas.company_number_street,
        company_billing_complement: tempDatas.company_complement,
        company_billing_zip_code: tempDatas.company_zip_code,
        company_billing_city: tempDatas.company_city,
        company_billing_phone: tempDatas.company_phone,
        company_billing_mail: tempDatas.company_mail,
      };
    } else if (copyBdd.collection.includes("company_admin_representant")) {
      copiedDatas = {
        company_admin_civil: tempDatas.company_legal_representant_civil,
        company_admin_first_name:
          tempDatas.company_legal_representant_first_name,
        company_admin_last_name: tempDatas.company_legal_representant_last_name,
        company_admin_function: tempDatas.company_legal_representant_function,
        company_admin_mail: tempDatas.company_legal_representant_mail,
        company_admin_phone: tempDatas.company_legal_representant_phone,
      };
    }
    return copiedDatas;
  };

  const updateFirestore = () => {
    if (formEdit) {
      fsDatabase.dbWrite(db, path, slug, fsDatas);
    }
  };

  const mergeData = (key, value, merge, valid) => {
    let tempDatas = {
      ...datas,
      [key]: {
        ...datas[key],
        value,
      },
    };

    setDatas(tempDatas);
    // BUG SIRET
    if (merge && formEdit) {
      tempDatas = {
        ...tempDatas,
        [key]: {
          ...tempDatas[key],
          is_valid: valid,
        },
      };
      if (copyBdd && copyBdd.active) {
        const copiedDatas = copyDatas(tempDatas);
        tempDatas = {
          [objectName]: tempDatas,
          [copyBdd.collection]: copiedDatas,
        };
        fsDatas = tempDatas;
      } else {
        fsDatas = { [objectName]: tempDatas };
      }

      updateFirestore();
    }
  };

  // UPDATE BDD
  const handleChange = (event) => {
    if (!lock) {
      const key = event.target.name;
      const { value } = event.target;
      if (value > 0 || value.length > 0) {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
  };

  const handleChangeInput = async (key, value, id) => {
    if (!lock) {
      if (value.length > 0) {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
    if (id === "company_idcc_code" && diplomaCode) {
      const siret = newDatas?.company_siret?.value;
      if (!value.length) value = "0";
      const supportedCost = await computeIdccCost(siret, diplomaCode, value);
      let cfa = await db
        .collection("formations")
        .doc(slug)
        .get()
        .then((el) => el.data());
      let gradeRef = await db
        .collection("grades")
        .doc(formation.formation_info.grade.grade_id)
        .get()
        .then((el) => el.data());
      await updatePriceInfo(supportedCost, gradeRef, cfa, slug, db);
    }
  };

  const handleChangeDate = (event, dateKey) => {
    if (!lock) {
      const key = dateKey;
      const value = event ? moment(event).format("DD/MM/YYYY") : "";
      if (value !== "00-00-0000" && value !== "" && value !== "Invalid date") {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
  };

  const handleChangeCheckbox = (event) => {
    if (!lock) {
      const key = event.target.name;
      let value;
      if (event.target.checked) value = true;
      else value = false;
      if (value === true) {
        mergeData(key, value, true, true);
      } else {
        mergeData(key, value, true, false);
      }
    }
  };

  const unlockOnLeaving = async (e) => {
    if (currentFocus === null) return;
    e.preventDefault();
    e.returnValue = "";
    const tempDatas = await {
      ...datas,
      [currentFocus]: {
        ...datas[currentFocus],
        is_locked: false,
      },
    };
    await setDatas(tempDatas);
    fsDatas = { [objectName]: tempDatas };
    await fsDatabase.dbWrite(db, path, slug, fsDatas);
  };

  const alertUser = useCallback((e) => {
    setCurrentFocus(null);
    document.activeElement.blur();
    e = e || window.event;
    unlockOnLeaving(e);
    e.preventDefault();
    e.returnValue = "";
    return "";
  }, []);

  useEffect(() => {
    if (currentFocus !== null) {
      window.addEventListener("beforeunload", alertUser);
    } else {
      window.removeEventListener("beforeunload", alertUser);
    }
  }, [currentFocus, alertUser]);

  const blur = (event) => {
    const key = event.target.name;
    setCurrentFocus(null);
    const { value } = event.target;
    if (value.length > 0) {
      mergeData(key, value, true, true);
    } else {
      mergeData(key, value, true, false);
    }
  };

  const focus = () => {
    // const key = event.target.name;
    // setCurrentFocus(key);
    // let tempDatas = {
    //   ...datas,
    //   [key]: {
    //     ...datas[key],
    //     is_locked: true,
    //   },
    // };
    // setDatas(tempDatas);
    // fsDatas = { [objectName]: tempDatas };
    // fsDatabase.dbWrite(db, path, slug, fsDatas);
  };

  const handleLock = () => {
    let tempDatas = datas;
    const nobodyModify = [];
    if (!newDatas.section_settings.is_locked) {
      for (const [id] of Object.entries(newDatas)) {
        if (newDatas[id].is_locked) {
          if (
            !nobodyModify.filter(
              (item) =>
                !item.includes("apprentice_last_name") ||
                item.includes("apprentice_first_name")
            )
          ) {
            nobodyModify.push(id);
          }
        }
      }
    }
    if (nobodyModify.length === 0 && path !== "formations") {
      for (const [id] of Object.entries(newDatas)) {
        if (id !== "apprentice_last_name" && id !== "apprentice_first_name") {
          tempDatas = {
            ...tempDatas,
            [id]: {
              ...tempDatas[id],
              is_locked: !lock,
            },
          };
        }
      }
      setDatas(tempDatas);
      fsDatas = { [objectName]: tempDatas };
      fsDatabase.dbWrite(db, path, slug, fsDatas);
      if (newDatas.section_settings.is_locked) {
        enqueueSnackbar("La section a bien été déverrouillée", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Verrouillage de la section", {
          variant: "success",
        });
      }
    }
    if (path === "formations") {
      enqueueSnackbar(
        "Vous n'avez pas l'autorisation de modifier ces données",
        {
          variant: "error",
        }
      );
    } else if (nobodyModify.length > 0) {
      enqueueSnackbar("Un utilisateur est entrain de modifier les données", {
        variant: "warning",
      });
    }
  };

  // FUNCTIONS

  useEffect(() => {
    setDatas(newDatas);
  }, [newDatas]);

  const setInputs = (obj, id) => {
    if (obj.filter.includes(filter)) {
      const key = id;
      const objValue = obj;

      if (document.activeElement.name === key) {
        objValue.is_locked = false;
      }

      const filterHtml = [];
      const filterList = ["Cerfa", "Convention"];

      if (objValue.hide) return <></>;

      if (objValue.filter.length > 0) {
        objValue.filter.forEach((filterId) => {
          let filterName;
          if (filterId > 0) {
            filterName = filterList[filterId - 1];
            filterHtml.push(
              <div className="ctn-filter" key={filterName}>
                <Tooltip
                  title={`Champ nécessaire pour valider le formulaire : ${filterName}`}
                  arrow
                >
                  <div className={`cte-filter-pin ${filterName}`} />
                  {/* <p className={`cte-filter ${filterName}`}>{filterName}</p> */}
                </Tooltip>
              </div>
            );
          }
        });
      }

      if (formEdit) {
        // 0 => Input text
        // 1 => Select
        // 2 => Date picker
        // 3 => Radio group
        // 4 => Checkbox
        // 5 => Multiline
        // else ???
        if (objValue.type === 0) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <TextField
                  key={key}
                  helperText={objValue.helper_text && objValue.helper_text}
                  placeholder={objValue.placeholder}
                  disabled={!singleInput ? objValue.is_locked : false}
                  name={key}
                  autoComplete="no"
                  value={datas[key].value}
                  variant="outlined"
                  onFocus={focus}
                  onChange={(e) =>
                    handleChangeInput(e.target.name, e.target.value, id)
                  }
                  onBlur={blur}
                  fullWidth
                  InputProps={{
                    startAdornment: objValue.start_adornment && (
                      <InputAdornment>
                        {objValue.start_adornment}
                      </InputAdornment>
                    ),
                    endAdornment: objValue.end_adornment && (
                      <InputAdornment>{objValue.end_adornment}</InputAdornment>
                    ),
                  }}
                  type={objValue.field_type && objValue.field_type}
                />
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 1) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <FormControl
                  key={key}
                  variant="outlined"
                  disabled={!singleInput ? objValue.is_locked : false}
                  fullWidth
                >
                  <Select
                    key={key}
                    name={key}
                    value={datas[key].value}
                    onChange={handleChange}
                  >
                    {objValue.selects.map((index) => (
                      <MenuItem
                        key={index.display}
                        name={index.display}
                        value={index.display}
                      >
                        {index.value}
                      </MenuItem>
                    ))}
                  </Select>
                  {objValue.helper_text && (
                    <FormHelperText>{objValue.helper_text}</FormHelperText>
                  )}
                </FormControl>
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 2) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <MuiPickersUtilsProvider
                  key={key}
                  locale={fr}
                  utils={DateFnsUtils}
                  format="dd/MM/yyyy"
                >
                  <KeyboardDatePicker
                    disabled={!singleInput ? objValue.is_locked : false}
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    // margin="normal"
                    key={key}
                    value={
                      datas[key].value.length
                        ? moment(datas[key].value, "DD MM YYYY")
                        : null
                    }
                    format="dd/MM/yyyy"
                    onChange={(e) => handleChangeDate(e, key)}
                    invalidDateMessage={"La date sélectionnée n'est pas valide"}
                    autoOk
                    KeyboardButtonProps={{
                      "aria-label": "Changer la date",
                    }}
                    fullWidth
                    margin="none"
                    locale="fr"
                    cancelLabel="Annuler"
                    okLabel="Valider"
                  />
                </MuiPickersUtilsProvider>
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 3) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div className="ctn-input">
                <FormControl
                  disabled={!singleInput ? objValue.is_locked : false}
                  name={key}
                  component="fieldset"
                  fullWidth
                >
                  {objValue.helper_text && (
                    <FormHelperText>{objValue.helper_text}</FormHelperText>
                  )}
                  <RadioGroup
                    row
                    key={key}
                    label={objValue.label}
                    name={key}
                    value={datas[key].value}
                    onChange={handleChange}
                  >
                    {objValue.selects.map((index) => (
                      <FormControlLabel
                        key={index.display}
                        value={index.display}
                        control={<Radio />}
                        label={index.value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 4) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input">
                <FormControlLabel
                  disabled={!singleInput ? objValue.is_locked : false}
                  name={key}
                  component="fieldset"
                  fullWidth
                  control={
                    <Checkbox
                      checked={objValue.value}
                      onChange={handleChangeCheckbox}
                      name={key}
                      color="primary"
                    />
                  }
                  label={
                    <div className="ctn-input-label">
                      <InputLabel>{objValue.label}</InputLabel>
                      {formEdit && (
                        <div className="input-filter">{filterHtml}</div>
                      )}
                    </div>
                  }
                />
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        } else if (objValue.type === 5) {
          inputs.push(
            <Grid
              key={key}
              item
              xs={12}
              sm={12 / objValue.col}
              order={objValue.order}
            >
              <div className="ctn-input-label">
                <InputLabel>{objValue.label}</InputLabel>
                {formEdit && <div className="input-filter">{filterHtml}</div>}
              </div>
              <div>
                <TextField
                  multiline
                  minRows={3}
                  key={key}
                  helperText={objValue.helper_text && objValue.helper_text}
                  placeholder={objValue.placeholder}
                  disabled={!singleInput ? objValue.is_locked : false}
                  name={key}
                  autoComplete="no"
                  value={datas[key].value}
                  variant="outlined"
                  onFocus={focus}
                  onChange={(e) =>
                    handleChangeInput(e.target.name, e.target.value, id)
                  }
                  onBlur={blur}
                  fullWidth
                  InputProps={{
                    startAdornment: objValue.start_adornment && (
                      <InputAdornment>
                        {objValue.start_adornment}
                      </InputAdornment>
                    ),
                    endAdornment: objValue.end_adornment && (
                      <InputAdornment>{objValue.end_adornment}</InputAdornment>
                    ),
                  }}
                  type={objValue.field_type && objValue.field_type}
                />
                {objValue.tooltip && (
                  <div className="ctn-tooltip">
                    <Tooltip title={objValue.tooltip} aria-label="aide">
                      <IconButton aria-label="aide-icon">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          );
        }
      } else {
        inputs.push(
          <Grid
            key={key}
            item
            xs={12}
            sm={12 / objValue.col}
            order={objValue.order}
          >
            <div
              className={`ctn-input-label value-${!!(key ===
                "price_difference_1" || key === "price_difference_2"
                ? true
                : objValue.value)} is_require-${objValue.filter !== 0}`}
            >
              <InputLabel>{objValue.label}</InputLabel>
            </div>
            <ArrowRightRoundedIcon />
            <div
              className={`ctn-input value-${!!(key === "price_difference_1" ||
              key === "price_difference_2"
                ? true
                : objValue.value)} is_require-${objValue.filter !== 0}`}
            >
              <p className="input">
                {objValue.value ||
                key === "price_difference_1" ||
                key === "price_difference_2" ? (
                  objValue.value === true || objValue.value === false ? (
                    objValue === true ? (
                      "Vrai"
                    ) : (
                      "Faux"
                    )
                  ) : (
                    objValue.value
                  )
                ) : objValue.filter != 0 ? (
                  <div className="error">
                    <p className="message">Champ requis manquant</p>
                  </div>
                ) : (
                  "Champ optionnel non rempli"
                )}
              </p>
            </div>
          </Grid>
        );
      }
    }
    inputs.sort((a, b) => a.props.order - b.props.order);
    setFields(inputs);
  };

  useEffect(() => {
    const getDatas = () => {
      for (const [id] of Object.entries(newDatas)) {
        if (id !== "section_settings") {
          setInputs(datas[id], id);
        }
      }
    };
    getDatas();
  }, [filter, datas]);

  return (
    <div className={`form-section lock-${lock} edit-form-${formEdit}`}>
      {!singleInput &&
      (formEdit === true || isVisible !== 0 || sectionActive === true) ? (
        <Paper className="ctn-inputs" elevation={0}>
          <div className="section-header">
            <div className="title">
              {newDatas.section_settings.title && (
                <h2 className="section-title">
                  {newDatas.section_settings.title}
                </h2>
              )}
              {newDatas.section_settings.subtitle && (
                <h3 className="section-subtitle">
                  {newDatas.section_settings.subtitle}
                </h3>
              )}
            </div>
            {formEdit && (
              <div className="lock-icon">
                <IconButton aria-label="lock-icon" onClick={handleLock}>
                  {lock ? (
                    <LockRoundedIcon className="lock" />
                  ) : (
                    <LockOpenRoundedIcon className="open" />
                  )}
                </IconButton>
              </div>
            )}
          </div>
          <Grid className="section-grid" container spacing={3}>
            {fields.length > 0 ? fields : <p>Pas de champs</p>}
          </Grid>
        </Paper>
      ) : (
        formEdit === true && (
          <div className="singleInput">
            {fields.length > 0 ? fields : <p>Pas de champs</p>}
          </div>
        )
      )}
    </div>
  );
};

Form.defaultProps = {
  singleInput: false,
};

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newDatas: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  objectName: PropTypes.string.isRequired,
  copyBdd: PropTypes.object,
  singleInput: PropTypes.bool,
  formEdit: PropTypes.bool.isRequired,
};

export default Form;

import {
  // Button,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
// import DetailsContent from "../../Components/Admin/Dashboard/DetailsContent";
import { useHistory } from "react-router-dom";
// import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DetailsContent from "../../Components/Admin/DetailsContent";
import { DatabaseContext } from "../../Context/Context";
import { adminDetailsTitle } from "./utils";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

const AdminDetails = ({ match }) => {
  const history = useHistory();
  // schoolId is only used to retrieve the formation's brand
  const { category, id, formation_id } = match.params;
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [forceReload, setForceReload] = useState(false);

  const { db } = useContext(DatabaseContext);
  const docRef =
    category === "formations"
      ? db.collection("campus").doc(id).collection(category).doc(formation_id)
      : db.collection(category).doc(id);

  const capitalize = (name) => name[0].toUpperCase() + name.slice(1);
  const [breadcrumbPath, setBreadcrumbPath] = useState();

  useEffect(() => {
    docRef.get().then((doc) => {
      if (!doc.exists) {
        console.error("Le document n'existe pas");
      }

      const datas = doc.data();

      let fetchedData = {
        ...datas,
        id: doc.id,
        title: "",
      };

      if (category === "campus") {
        const cityRef = doc.data()[adminDetailsTitle[category].title.city];
        const remoteRef = db.collection("cities").doc(cityRef);
        remoteRef.get().then((doc) => {
          if (!doc.exists) {
            console.error("Le document n'existe pas");
          }
          const city = doc.data().label;
          fetchedData.title = `${
            datas[adminDetailsTitle[category].title.school]
          } ${city}`;
          setContent(fetchedData);
        });
      } else if (category === "rncp") {
        const levelRef = datas.rcnp_level;
        const remoteRef = db.collection("rncp_titles").doc(levelRef);
        remoteRef.get().then((doc) => {
          if (!doc.exists) {
            console.error("Le document n'existe pas");
          }
          const level = doc.data().rncp_level_label;
          fetchedData.title = `${level} - ${
            datas[adminDetailsTitle[category].title]
          }`;
          setContent(fetchedData);
        });
      } else if (category === "formations") {
        const schoolRef = datas.school_id;
        const remoteRef = db.collection("schools").doc(schoolRef);
        remoteRef.get().then((city) => {
          if (!city.exists) {
            console.error("Le document n'existe pas");
          }

          fetchedData.school_logo = city.data().school_logo;
          fetchedData.title = datas[adminDetailsTitle[category].title];

          const cityRef = datas.campus_city;
          const cityRemote = db.collection("cities").doc(cityRef);
          cityRemote.get().then((city) => {
            if (!doc.exists) {
              console.error("Le document n'existe pas");
            }

            fetchedData.title =
              fetchedData.school_abbreviation +
              " " +
              city.data().label +
              " - " +
              fetchedData.title;
            setContent(fetchedData);
          });
        });
      } else {
        fetchedData.title = datas[adminDetailsTitle[category].title];
        setContent(fetchedData);
      }
    });
  }, [category, id, forceReload]);

  useEffect(() => {
    if (content) {
      category === "formations_canvas"
        ? setBreadcrumbPath({
            path: [
              { name: "Administration" },
              { name: "Écoles", href: "/schools" },
              {
                name: `${content.school_abbreviation}`,
                href: `/schoolDetails/${formation_id}`,
              },
            ],
          })
        : setBreadcrumbPath({
            path: [
              { name: "Administration" },
              {
                name: capitalize(category),
                href: `/${category}`,
              },
            ],
          });
      setLoading(false);
      setForceReload(false);
    }
  }, [content]);

  if (!content) return <>Chargement</>;

  return (
    <Grid container className="admin-details">
      {loading && <LinearProgress />}
      <Grid item xs={12} className="admin-view-header">
        {breadcrumbPath && (
          <div className="admin-infos">
            <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
            <div className="admin-infos-return">
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  history.push(
                    category === "formations_canvas"
                      ? `/schoolDetails/${formation_id}`
                      : `/${category}`
                  );
                }}
                className="ad-stroke-button dark-color icon-button"
              >
                <ArrowBackIosRoundedIcon />
              </IconButton>
              <Typography variant="h4" className="header-title">
                {content.school_logo && (
                  <img
                    className="school-logo"
                    src={`${content.school_logo}`}
                    alt="Logo école"
                  />
                )}
                {content.title}
              </Typography>
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12} className="admin-detail-content">
        <Grid container justifyContent="space-between">
          {content && (
            <DetailsContent
              category={category}
              content={content}
              setContent={setContent}
              loading={loading}
              formationId={formation_id}
              isFormation={formation_id ? docRef : false}
              setForceReload={setForceReload}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminDetails;

import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import logo from "../../Assets/AD_Logo_Primary.svg";
import "./adminLogin.scss";
import { Typography } from "@material-ui/core";
// import { AuthContext } from '../../Context/Context';

const AdminLogin = () => {
  // const { setAuth } = useContext(AuthContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();
  const [pwdForget, setPwdForget] = useState(false);

  const routing = () => {
    // setAuth(true);
    history.push("/contracts");
  };

  const onSubmit = handleSubmit(({ email, password }) => {
    // const email = data.email; // "test1@example.com"
    // const password = data.password; // "zzzzzz"

    if (pwdForget) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          enqueueSnackbar(`Lien de réinitialisation envoyé à ${email}`, {
            variant: "success",
          });
          setPwdForget(false);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    } else {
      firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        enqueueSnackbar(`Bienvenue ${email}`, {
          variant: "success",
        });
        routing();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
    }
  });
  
  return (
    <div className="admin-login">
      <div className="ctn-form">
        <img className="logo" alt="Logo AD Education" src={logo} />
        <div className="header">
          <h1 className="title">
            Bienvenue sur votre application relation entreprise v2
          </h1>
          <h2 className="subtitle">
            { pwdForget
            ? "Veuillez renseigner votre email afin de pouvoir réinitialiser votre mot de passe"
            : "Veuillez renseigner votre email et mot de passe transmis par l'administrateur"}
            
          </h2>
        </div>
        <form className="cte-form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("email")}
            name="email"
            label="Email"
            variant="outlined"
          />
          {!pwdForget && (
            <TextField
            {...register("password")}
            name="password"
            type="password"
            label="Mot de passe"
            variant="outlined"
            />
          )}
          <Button variant="contained" color="primary" type="submit">
          { pwdForget ? "Réinialiser le mot de passe" : "Se connecter"}
          </Button>
          <Typography variant="body2" style={{cursor: "pointer"}} onClick={() => setPwdForget(!pwdForget)}>
            {pwdForget ? "Se connecter" : "Mot de passe oublié ?"}
          </Typography>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;

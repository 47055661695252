import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Form from "../FormStructure";
import sectionClear from "../../../Templates/company.json";
import mfs from "../../../Services/manageFormSection";
import { DatabaseContext } from "../../../Context/Context";

const Company = ({ slug, datas, path }) => {
  const { db } = useContext(DatabaseContext);
  const [adressState, setAdress] = useState(1);
  const adressMin = 1;
  const adressMax = 2;
  const collection = ["general"];
  const collectionAdress = ["company_adress", "company_billing_adress"];
  const collectionRepresentant = [
    "company_legal_representant",
    "company_admin_representant",
  ];
  const representantMin = 1;
  const representantMax = 2;
  const [representantState, setRepresentant] = useState(1);
  const [diplomaCode, setDiplomaCode] = useState(null);
  const [formation, setFormation] = useState(null);

  const getActive = () => {
    let activeAdress = 0;
    let activeRepresentant = 0;
    for (const key in datas) {
      if (collectionAdress.includes(key)) {
        datas[key].section_settings?.is_active && activeAdress++;
      }
      if (collectionRepresentant.includes(key)) {
        datas[key].section_settings?.is_active && activeRepresentant++;
      }
    }
    setAdress(activeAdress);
    setRepresentant(activeRepresentant);
  };

  // Get diploma code
  useEffect(() => {
    db.collection("formations")
      .doc(slug)
      .get()
      .then((doc) => {
        console.log("formation", doc.data());
        setFormation(doc.data());
        setDiplomaCode(doc.data().formation_info.diploma_code.value);
      });
  }, []);

  useEffect(() => {
    getActive();
  }, [datas]);

  const changeAdress = () => {
    if (adressState === adressMin) {
      const clearDatas = {
        ...datas,
        [collectionAdress[adressState]]:
          sectionClear[collectionAdress[adressState]],
      };
      // setCopyAdress(clearDatas);
      mfs.sectionAdd(db, clearDatas, collectionAdress, adressState, slug, path);
    } else if (adressState === adressMax) {
      mfs.sectionRemove(
        db,
        datas,
        collectionAdress,
        adressState,
        slug,
        path,
        sectionClear
      );
    }
  };

  const changeRepresentant = () => {
    if (representantState === representantMin) {
      const clearDatas = {
        ...datas,
        [collectionRepresentant[representantState]]:
          sectionClear[collectionRepresentant[representantState]],
      };
      // setCopyAdress(clearDatas);
      mfs.sectionAdd(
        db,
        clearDatas,
        collectionRepresentant,
        representantState,
        slug,
        path
      );
    } else if (representantState === representantMax) {
      mfs.sectionRemove(
        db,
        datas,
        collectionRepresentant,
        representantState,
        slug,
        path,
        sectionClear
      );
    }
  };

  return (
    <div>
      {formation &&
        collection.map((item) => {
          return (
            <Form
              key={item}
              newDatas={datas[item]}
              path={path}
              objectName={item}
              slug={slug}
              diplomaCode={diplomaCode}
              formation={formation}
              formEdit
            />
          );
        })}
      {/* Why was this section off?? */}
      {adressState >= 1 && (
        <Form
          key={collectionAdress[0]}
          copyBdd={{
            active: adressState === 1,
            collection: [collectionAdress[1]],
          }}
          newDatas={datas[collectionAdress[0]]}
          path={path}
          objectName={collectionAdress[0]}
          slug={slug}
          formEdit
        />
      )}
      <FormControl
        className={
          adressState === 1
            ? `display-section copy close form-parent-lock-${
                datas[collectionAdress[0]].section_settings?.is_locked
              } child-lock-${
                datas[collectionAdress[1]].section_settings?.is_locked
              }`
            : `display-section copy open form-parent-lock-${
                datas[collectionAdress[0]].section_settings?.is_locked
              } child-lock-${
                datas[collectionAdress[1]].section_settings?.is_locked
              }`
        }
      >
        <FormLabel className="display-section-title">
          Est-ce que l'adresse de facturation est différente ?
        </FormLabel>
        <RadioGroup
          className="display-section-radio"
          row
          value={adressState}
          onChange={changeAdress}
        >
          <FormControlLabel
            className="display-section-radio-label"
            key="yes"
            value={2}
            control={<Radio />}
            label="Oui"
          />
          <FormControlLabel
            className="display-section-radio-label"
            key="no"
            value={1}
            control={<Radio />}
            label="Non"
          />
        </RadioGroup>
      </FormControl>
      {adressState >= 2 && (
        <Form
          key={collectionAdress[1]}
          newDatas={datas[collectionAdress[1]]}
          path={path}
          objectName={collectionAdress[1]}
          slug={slug}
          formEdit
        />
      )}
      {representantState >= 1 && (
        <Form
          key={collectionRepresentant[0]}
          copyBdd={{
            active: representantState === 1,
            collection: [collectionRepresentant[1]],
          }}
          newDatas={datas[collectionRepresentant[0]]}
          path={path}
          objectName={collectionRepresentant[0]}
          slug={slug}
          formEdit
        />
      )}
      <FormControl
        className={
          representantState === 1
            ? `display-section copy close form-parent-lock-${
                datas[collectionRepresentant[0]].section_settings?.is_locked
              } child-lock-${
                datas[collectionRepresentant[1]].section_settings?.is_locked
              }`
            : `display-section copy open form-parent-lock-${
                datas[collectionRepresentant[0]].section_settings?.is_locked
              } child-lock-${
                datas[collectionRepresentant[1]].section_settings?.is_locked
              }`
        }
      >
        <FormLabel className="display-section-title">
          Est-ce que le contact pour la facturation est différent ?
        </FormLabel>
        <RadioGroup
          className="display-section-radio"
          row
          value={representantState}
          onChange={changeRepresentant}
        >
          <FormControlLabel
            className="display-section-radio-label"
            key="yes"
            value={2}
            control={<Radio />}
            label="Oui"
          />
          <FormControlLabel
            className="display-section-radio-label"
            key="no"
            value={1}
            control={<Radio />}
            label="Non"
          />
        </RadioGroup>
      </FormControl>
      {representantState >= 2 && (
        <Form
          key={collectionRepresentant[1]}
          newDatas={datas[collectionRepresentant[1]]}
          path={path}
          objectName={collectionRepresentant[1]}
          slug={slug}
          formEdit
        />
      )}
    </div>
  );
};
// datas, path, filter
Company.propTypes = {
  slug: PropTypes.string.isRequired,
  datas: PropTypes.objectOf(PropTypes.object).isRequired,
  path: PropTypes.string.isRequired,
};

export default Company;

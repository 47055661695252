import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "../../../Services/Loader";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import "./menu.scss";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CloudDoneRoundedIcon from "@material-ui/icons/CloudDoneRounded";

const MenuTabs = ({ setTab, tab, tabsToDisplay, switchTab, progress, isCompany, isLogin }) => {
  const [saving, setSaving] = useState();

  useEffect(() => {
    saveMessage();
    // eslint-disable-next-line
  }, [setTab]);

  const saveMessage = () => {
    setSaving(true);
    setTimeout(() => {
      setSaving(false);
    }, 1000);
  };

  const handleChange = (event, newValue) => {
    let tabValue = newValue;
    setTab(tabValue);
  };

  const changeTab = (event) => {
    const eventValue = event.target.id;
    switchTab(eventValue);
  };

  return (
    <div className="ctn-tabs">
      <div className="menu-bar-top"></div>
      <div className="menu-bar">
        <div className="cte-tabs">
          <Tabs value={tab} onChange={handleChange} aria-label="ant example">
            {tabsToDisplay.map((tab) => (
              <Tab
                key={tab.label}
                value={tab.label}
                label={
                  <div className="tab-value">
                    {tab.label}
                    {progress[tab.name].total - progress[tab.name].is_valid != 0 && (
                      <p className="tab-number">
                        {progress[tab.name].total - progress[tab.name].is_valid}
                      </p>
                    )}
                  </div>
                }
              />
            ))}
          </Tabs>
        </div>
        {(isCompany || isLogin) && (
          <div className="change-tab">
            <div className="cte-change">
              <KeyboardArrowLeftIcon className="save-message-icon" />
              <p className="previous change" id="substract" onClick={changeTab}>
                Précédent
              </p>
            </div>
            <div className="cte-change">
              <p className="next change" id="add" onClick={changeTab}>
                Suivant
              </p>
              <KeyboardArrowRightIcon className="save-message-icon" />
            </div>
          </div>
        )}
        <div className="save-message">
          {saving ? (
            <Loader className="save-message-loader" />
          ) : (
            <CloudDoneRoundedIcon className="save-message-icon" />
          )}
        </div>
      </div>
    </div>
  );
};
export default MenuTabs;

import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomTable from "../../Components/CustomTable";
import Edit from "@material-ui/icons/EditRounded";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddForm from "../../Components/Admin/AddForm";
import { useSnackbar } from "notistack";
import { DatabaseContext } from "../../Context/Context";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import { Chip, Typography, Grid } from "@material-ui/core";

const fields = [
  {
    label: "Niveau",
    id: "rcnp_level",
    type: "select",
    remote: "/rncp_titles",
    endpoint: "rncp_level_label",
  },
  { label: "Intitulé du titre", id: "rncp_title", align: "center" },
  // { label: "Titre RNCP", id: "rncp_exact_title" },
  { label: "Numéro RNCP", id: "rncp_number" },
  { label: "Code diplôme", id: "rncp_diploma_code" },
  {
    label: "Niveau du titre visé",
    id: "rncp_goal",
    type: "select",
    remote: "/rncp_goals",
    endpoint: "rncp_goal_label",
  },
];

const columns = [
  {
    title: "Niveau",
    field: "rncp_level",
    filtering: false,
    align: "center",
    cellStyle: {
      // minWidth: "auto",
    },
  },
  {
    title: "Libellé",
    field: "rncp_title",
    filtering: false,
    cellStyle: {
      width: "100%",
      minWidth: "200px",
    },
  },
  {
    title: "Numéro RNCP",
    field: "rncp_number",
    align: "center",
    filtering: false,
  },
  {
    title: "Code diplôme",
    field: "rncp_diploma_code",
    align: "center",
    filtering: false,
  },
];

const breadcrumbPath = {
  path: [{ name: "Administration" }],
};

const Rncp = () => {
  const { db } = useContext(DatabaseContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // const db = firebase.firestore().collection("academic_year").doc(year);
  const rncpRef = db.collection("rncp");
  const actions = [
    {
      icon: Edit,
      tooltip: "Voir les détails du titre RNCP",
      onClick: (_event, rowData) =>
        history.push(`/admindetails/rncp/${rowData.id}/false`),
    },
    // {
    //   icon: DeleteOutline,
    //   tooltip: "Supprimer le titre RNCP",
    //   onClick: (_event, rowData) => history.push(`/admindetails/rncp/${rowData.id}/false/true`),
    // },
  ];

  useEffect(() => {
    let rncpData = [];

    rncpRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          rncpData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        console.log();
        setData(formatData(rncpData));
      });
  }, []);

  const formatData = (result) => {
    if (!result) return [];
    return result.map((rncp) => {
      let output = { ...rncp };

      const index = output.rncp_exact_title.search(/[0-9]/);
      const level = Number(output.rncp_exact_title[index]);

      output.rncp_level = (
        <Chip
          label={`Niv ${level}`}
          variant="outlined"
          className={`chip md niv niv-${level}`}
        />
      );
      return output;
    });
  };

  const handleAdd = async (data) => {
    const formattedData = await db
      .collection("rncp_titles")
      .doc(data.rcnp_level)
      .get()
      .then((doc) => {
        return {
          ...data,
          rncp_exact_title: `${doc.data().rncp_level_label} - ${
            data.rncp_title
          }`,
        };
      });

    let rncpData = [];
    rncpRef.add(formattedData).then(() => {
      enqueueSnackbar("Document enregistré", {
        variant: "success",
      });
      setShow(false);
      rncpRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            rncpData.push({ ...doc.data(), id: doc.id });
          });
        })
        .then(() => {
          setData(rncpData);
        });
    });
  };

  return (
    <Grid container className="admin-view">
      <Grid item xs={12} className={`admin-view-header ${show && "adding"}`}>
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <Typography variant="h4" className="header-title">
            Titres RNCP
          </Typography>
        </div>
        {/* <Button>Ajouter un titre RNPC</Button> */}
        <AddForm
          fields={fields}
          onSubmit={handleAdd}
          show={show}
          setShow={setShow}
          className="add-form"
        />
      </Grid>
      <Grid item xs={12} className="admin-view-table">
        {!!data.length && (
          <section className="admin-view-section">
            <h3 className="section-title">Liste des titres RNCP</h3>
            <CustomTable
              title="Liste des titres RNCP"
              columns={columns}
              data={data}
              actions={actions}
              size={size}
              setSize={setSize}
              page={page}
              setPage={setPage}
              totalElements={totalElements}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filter={false}
              // noSearch={true}
              autoFocus={true}
            />
          </section>
        )}
      </Grid>
    </Grid>
  );
};

export default Rncp;

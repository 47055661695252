const fields = [
  { id: "firstname", label: "Prénom" },
  { id: "lastname", label: "Nom" },
  { id: "function", label: "Fonction" },
  { id: "phone", label: "Téléphone", type: "phone", format: "## ## ## ## ##" },
  { id: "email", label: "Email", type: "email" },
  { id: "picture", label: "Photo" },
];

export { fields };

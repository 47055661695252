import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import NumberFormat from "react-number-format";
import Loader from "../../Services/Loader";
import Form from "./FormStructure";
import fsDatabase from "../../Services/firestoreFunctions";
// import { ContactSupportOutlined } from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import bddOpco from "../../Templates/bddOpco.json";
import { DatabaseContext } from "../../Context/Context";
import { Base64 } from "js-base64";

// import npec from "./NPEC.json";
import { computeIdccCost, fetchSiretApis, updatePriceInfo } from "./utils";

const AddSiret = ({ companies, path, slug }) => {
  const { db } = useContext(DatabaseContext);
  const { enqueueSnackbar } = useSnackbar();
  let bddSiret = companies.general.company_siret;
  let [siret, setSiret] = useState("");
  let [fetchSiret, setFetchSiret] = useState(false);
  let [siretLoading, setSiretLoading] = useState(true);
  let [bddDatas, setDatas] = useState(companies);
  let [collections, setCollections] = useState();
  const [diplomaCode, setDiplomaCode] = useState(null);
  const [formation, setFormation] = useState(null);
  const [supportedCost, setSupportedCost] = useState(0);

  useEffect(() => {
    db.collection("formations")
      .doc(slug)
      .get()
      .then((doc) => {
        setFormation(doc.data());
        setDiplomaCode(doc.data().formation_info.diploma_code.value);
      });
  }, []);

  //
  useEffect(() => {
    updateDatas();
  }, [companies]);

  const updateDatas = () => {
    let tempDatas = {};
    for (const [collection] of Object.entries(bddDatas)) {
      for (const [key, value] of Object.entries(bddDatas[collection])) {
        tempDatas = {
          ...tempDatas,
          [collection]: {
            ...tempDatas[collection],
            [key]: {
              ...companies[collection][key],
              value: companies[collection][key].value,
            },
          },
        };
      }
    }
    setDatas(tempDatas);
  };

  // curl --verbose -k -d "grant_type=client_credentials" \
  //     -H "Authorization: Basic Base64(2mEbL8S5YdVi5caogmwH3jmM_bYa:oJSiaKAxyuN7qBvdsSy_uICykpAa)" \
  //      https://api.insee.fr/token

  // curl -k -d "grant_type=client_credentials" -H "Authorization: Basic Base64(2mEbL8S5YdVi5caogmwH3jmM_bYa:oJSiaKAxyuN7qBvdsSy_uICykpAa)" https://api.insee.fr/token

  const checkSiret = async () => {
    if (siret.length === 14) {
      let siren = siret.slice(0, -5);
      // const dataGouv = await fetchSiretApis(
      //   `https://entreprise.data.gouv.fr/api/sirene/v1/siret/${siret}`
      // );
      const idcc = await fetchSiretApis(
        `https://siret2idcc.fabrique.social.gouv.fr/api/v2/${siret}`
      );

      const siretUrl = "https://api.insee.fr/entreprises/sirene/V3/siret";
      const username = "2mEbL8S5YdVi5caogmwH3jmM_bYa";
      const password = "oJSiaKAxyuN7qBvdsSy_uICykpAa";

      // let headers = new Headers();
      // // headers.append(
      // //   "Authorization",
      // //   "Authorization " + Base64.encode(username + ":" + password)
      // // );
      // // headers.append("Content-Type", "application/json");
      // headers.append("Content-Type", "application/x-www-form-urlencoded");
      // // headers.append("Accept", "application/json");
      // headers.append(
      //   "Authorization",
      //   "Basic " + Base64.encode(username + ":" + password)
      // );
      // headers.append("Origin", "http://localhost:3000");

      const inseeData = await fetch(`${siretUrl}/${siret}`, {
        method: "GET",
        headers: {
          // TODO : Change this to a dynamic token
          Authorization: "Bearer 55f7d26f-3cc2-32cc-a788-42908dbf8fff",
        },
      })
        .then((res) => res.json())
        .then((response) => response)
        .catch((err) => {
          console.error(err);
        });

      const idccData = await fetch(
        `https://europe-west1-re-webapp-v2-dev.cloudfunctions.net/proxyFetch?url=https://www.cfadock.fr/api/opcos?siret=${siret}`
      )
        .then((res) => res.json())
        .then((response) => response)
        .catch((err) => {
          console.error(err);
        });

      console.log("inseeData", inseeData);
      console.log("idccData", idccData);
      if (idccData.searchStatus !== "OK") {
        idccData.idcc = "";
      }

      // const res = await fetch("https://api.insee.fr/token", {
      //   method: "POST",
      //   body:
      //     "grant_type=client_credentials&client_id=" +
      //     username +
      //     "&client_secret=" +
      //     password,
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },

      //   // headers: headers,
      //   // mode: "no-cors",
      // })
      //   .then((response) => response)
      //   // .then((json) => console.log(json))
      //   .catch((err) => console.log("err fetching token siret", err));
      // console.log("res", res);
      // console.log("re jsons", res?.json());

      // FROM POSTMAN
      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      // myHeaders.append(
      //   "Authorization",
      //   "Basic Mm1FYkw4UzVZZFZpNWNhb2dtd0gzam1NX2JZYTpvSlNpYUtBeHl1TjdxQnZkc1N5X3VJQ3lrcEFh"
      // );
      // myHeaders.append(
      //   "Cookie",
      //   "INSEE=2957558538.58148.0000; pdapimgateway=2907226890.22560.0000"
      // );

      // var urlencoded = new URLSearchParams();
      // urlencoded.append("grant_type", "client_credentials");

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   body: urlencoded,
      //   redirect: "follow",
      // };

      // fetch("https://api.insee.fr/token", requestOptions)
      //   .then((response) => response.text())
      //   .then((result) => console.log("result postman", result))
      //   .catch((error) => console.log("error", error));
      // END POSTMAN

      // fetch(siretUrl, {
      //   method: "GET",
      //   headers: headers,
      // })
      //   .then((response) => response.json())
      //   .then((json) => console.log(json));

      // fetch(url, {
      //   method: "GET",
      //   headers: headers,
      //   //credentials: 'user:passwd'
      // })
      //   .then((response) => response.json())
      //   .then((json) => console.log(json));

      // console.log("dataGouv", dataGouv);
      console.log("idcc", idcc);

      // const lastIdcc = idcc[0].conventions.length - 1;
      let apiDatas = {};
      let tempDatas = {};
      let tempCollections = [];
      if (!inseeData.etablissement) {
        enqueueSnackbar(
          "Votre numéro de SIRET ne correspond à aucune entreprise",
          {
            variant: "error",
          }
        );
      } else {
        setFetchSiret(true);
        apiDatas = {
          general: {
            company_siret: siret,
            company_name:
              inseeData.etablissement.uniteLegale.denominationUniteLegale,
            company_naf_code:
              inseeData.etablissement.periodesEtablissement[0].activitePrincipaleEtablissement.replace(
                ".",
                ""
              ),
          },
          company_adress: {
            company_zip_code:
              inseeData.etablissement.adresseEtablissement
                .codePostalEtablissement,
            company_number_street: `${inseeData.etablissement.adresseEtablissement.numeroVoieEtablissement} ${inseeData.etablissement.adresseEtablissement.typeVoieEtablissement} ${inseeData.etablissement.adresseEtablissement.libelleVoieEtablissement}`,
            company_city:
              inseeData.etablissement.adresseEtablissement
                .libelleCommuneEtablissement,
            company_complement:
              inseeData.etablissement.adresseEtablissement
                .complementAdresseEtablissement,
          },
        };
        if (idcc[0].conventions[0]) {
          let idccNum = idcc[0].conventions[0].num;
          apiDatas = {
            ...apiDatas,
            general: {
              ...apiDatas.general,
              company_idcc_code: idccData.idcc,
              company_convention_name:
                idcc[0]?.conventions[0]?.shortTitle || "",
              company_opco_name: bddOpco[idccNum]?.opco_name || "",
            },
          };

          const cost = await computeIdccCost(siret, diplomaCode);
          setSupportedCost(cost);
        }
        for (const [collection] of Object.entries(apiDatas)) {
          tempCollections.push(collection);
          for (const [key, value] of Object.entries(apiDatas[collection])) {
            if (value && key !== "company_siret") {
              tempDatas = {
                ...tempDatas,
                [collection]: {
                  ...tempDatas[collection],
                  [key]: {
                    ...bddDatas[collection][key],
                    value: value,
                    is_valid: String(value).length ? true : false,
                  },
                },
              };
            }
          }
        }

        setDatas(tempDatas);
        setCollections(tempCollections);
        let fsDatas = {
          ...companies,
          ...tempDatas,
        };
        fsDatabase.dbWrite(db, path, slug, fsDatas);
        setTimeout(() => {
          setSiretLoading(false);
        }, 700);
      }
    } else {
      enqueueSnackbar("Votre numéro de SIRET doit comporter 14 chiffres", {
        variant: "error",
      });
    }
  };

  const passSiret = () => {
    let newDatas = {
      ...companies,
      general: {
        ...companies.general,
        company_siret: {
          ...companies.general.company_siret,
          value: "À COMPLÉTER",
        },
      },
    };
    let fsDatas = {
      ...companies,
      ...newDatas,
    };
    fsDatabase.dbWrite(db, path, slug, fsDatas);
  };

  const handleSiretChange = (event) => {
    setFetchSiret(false);
    setSiretLoading(true);
    setDatas(companies);
    // setFetchSiret(false);
    const value = event.target.value;
    setSiret(value);
  };

  const validateSiret = async () => {
    let newDatas = {
      ...companies,
      general: {
        ...companies.general,
        company_siret: {
          ...companies.general.company_siret,
          value: siret,
          is_valid: true,
        },
      },
    };
    let fsDatas = {
      ...companies,
      ...newDatas,
    };
    fsDatabase.dbWrite(db, path, slug, fsDatas);

    let cfa = await db
      .collection("formations")
      .doc(slug)
      .get()
      .then((el) => el.data());

    let gradeRef = await db
      .collection("grades")
      .doc(formation.formation_info.grade.grade_id)
      .get()
      .then((el) => el.data());

    updatePriceInfo(supportedCost, gradeRef, cfa, slug, db);
  };

  return (
    <div className="ctn-add-siret">
      <Paper>
        <div className="cte-add-siret">
          <h2 className="check-info-title first-title">Rentrez votre siret</h2>
          <p className="check-info-subtitle">
            Vous pouvez rentrer maintenant votre SIRET pour pré-remplir une
            partie des informations requises et gagner du temps
          </p>
          <NumberFormat
            customInput={TextField}
            inputProps={{ maxLength: 14 }}
            key={"bddSiret"}
            placeholder={bddSiret.placeholder}
            label={bddSiret.label}
            autoComplete="no"
            value={siret}
            variant="outlined"
            fullWidth={true}
            allowLeadingZeros
            // format="### ### ### #####"
            onChange={handleSiretChange}
          />
          {!fetchSiret && (
            <div className="ctn-button">
              <Button
                size="large"
                color="primary"
                className="button button-pass"
                onClick={passSiret}
              >
                Pré-remplir plus tard
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className="button button-add"
                onClick={checkSiret}
              >
                Valider
              </Button>
            </div>
          )}
          {fetchSiret && (
            <div>
              {siretLoading ? (
                <Loader />
              ) : (
                <div className="ctn-check-info">
                  <h2 className="check-info-title">
                    Vérifiez vos informations
                  </h2>
                  {collections.map((item) => (
                    <Form
                      key={item}
                      newDatas={bddDatas[item]}
                      path={path}
                      objectName={item}
                      slug={slug}
                      singleInput={true}
                      formEdit={true}
                    ></Form>
                  ))}
                  <Button
                    className="button-validate"
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={validateSiret}
                  >
                    les informations sont correctes
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};
export default AddSiret;

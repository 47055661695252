import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomTable from "../../Components/CustomTable";
import Edit from "@material-ui/icons/EditRounded";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddForm from "../../Components/Admin/AddForm";
import { useSnackbar } from "notistack";
import { DatabaseContext } from "../../Context/Context";
import { Chip } from "@material-ui/core";
import firebase from "../../firebase";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";

const fields = [
  { label: "Nom du CFA", id: "cfa_name" },
  {
    label: "Numéro Siret",
    id: "cfa_siret",
    type: "siret",
    format: "### ### ### ### ##",
  },
  {
    label: "Numéro NDA",
    id: "cfa_nda_code",
    type: "nda",
    format: "## ### ### ###",
  },
  {
    label: "Numéro UAI",
    id: "cfa_uai_code",
  },
  {
    label: "École(s)",
    id: "schools_short_name",
    type: "multiselect",
    remote: "/schools",
    endpoint: "school_short_name",
  },
  {
    label: "Titre(s) RNCP rattaché(s)",
    id: "cfa_rncp",
    type: "multiselect",
    remote: "/rncp",
    endpoint: "rncp_exact_title",
  },
];

const columns = [
  {
    title: "Nom",
    field: "cfa_name",
    filtering: false,
    cellStyle: {
      width: "100%",
      minWidth: "200px",
    },
  },
  {
    title: "SIRET",
    field: "cfa_siret",
    filtering: false,
    cellStyle: {
      width: "100%",
      minWidth: "200px",
    },
  },
  {
    title: "Code NDA",
    field: "cfa_nda_code",
    filtering: false,
    align: "center",
  },
  {
    title: "Code UAI",
    field: "cfa_uai_code",
    filtering: false,
    align: "center",
  },
  {
    title: "Écoles",
    field: "schools_short_name",
    filtering: false,
    align: "center",
  },
  {
    title: "Titre RNCP",
    field: "cfa_rncp",
    filtering: false,
    align: "center",
  },
  {
    title: "Statut",
    field: "completed",
    filtering: false,
    align: "center",
  },
];

const formatData = (result) => {
  if (!result) return [];
  return result.map((cfa) => {
    let output = { ...cfa };

    output.cfa_rncp = output.cfa_rncp.length;
    output.schools_short_name = output.schools_short_name.length;

    output.completed = cfa.completed ? (
      <Chip label="Complété" variant="outlined" className="chip complete md" />
    ) : (
      <Chip label="À compléter" variant="outlined" className="chip to-complete md" />
    );

    return output;
  });
};

const breadcrumbPath = {
  path: [{ name: "Administration" }],
};

const Cfa = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { db } = useContext(DatabaseContext);
  const cfaRef = db.collection("cfa");
  const actions = [
    {
      icon: Edit,
      tooltip: "Voir les détails du CFA",
      onClick: (_event, rowData) => history.push(`/admindetails/cfa/${rowData.id}/false`),
    },
    // {
    //   icon: DeleteOutline,
    //   tooltip: "Supprimer le CFA",
    //   onClick: (_event, rowData) => history.push(`/admindetails/cfa/${rowData.id}/false/true`),
    // },
  ];

  useEffect(() => {
    let cfaData = [];

    cfaRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          cfaData.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      })
      .then(() => {
        console.log();
        setData(formatData(cfaData));
      });
  }, []);

  const updateCfaInSchools = async (schoolsSelected, cfaId) => {
    try {
      let batch = firebase.firestore().batch();

      for (const schoolId of schoolsSelected) {
        let schoolRef = db.collection("schools").doc(schoolId);
        await batch.update(schoolRef, {
          cfa_associated: firebase.firestore.FieldValue.arrayUnion(cfaId),
        });
      }

      batch.commit().then(() => {
        console.info("CFA ajouté aux écoles associées");
      });
    } catch (err) {
      console.log("Error while updating CFA in schools", err);
    }
  };

  const handleAdd = (data) => {
    console.log("data", data);

    let cfaData = [];
    cfaRef.add({ ...data, cfa_siren: data.cfa_siret.slice(0, 9), completed: false }).then((cfa) => {
      enqueueSnackbar("Document enregistré", {
        variant: "success",
      });
      updateCfaInSchools(data.schools_short_name, cfa.id);
      setShow(false);
      cfaRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            cfaData.push({ ...doc.data(), id: doc.id });
          });
        })
        .then(() => {
          setData(formatData(cfaData));
        });
    });
  };

  return (
    <Grid container className="admin-view">
      <Grid item xs={12} className={`admin-view-header ${show && "adding"}`}>
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <Typography variant="h4" className="header-title">
            CFA
          </Typography>
        </div>
        <AddForm
          fields={fields}
          onSubmit={handleAdd}
          show={show}
          setShow={setShow}
          className="add-form"
        />
      </Grid>
      <Grid item xs={12} className="admin-view-table">
        {!!data.length && (
          <section className="admin-view-section">
            <h3 className="section-title">Liste des CFA</h3>
            <CustomTable
              title="Liste des CFA"
              columns={columns}
              data={data}
              actions={actions}
              size={size}
              setSize={setSize}
              page={page}
              setPage={setPage}
              totalElements={totalElements}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filter={false}
              // noSearch={true}
              autoFocus={true}
            />
          </section>
        )}
      </Grid>
    </Grid>
  );
};

export default Cfa;

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomTable from "../../Components/CustomTable";
import Edit from "@material-ui/icons/EditRounded";
// import AddForm from "../../Components/Admin/AddForm";
// import { useSnackbar } from "notistack";
import { DatabaseContext } from "../../Context/Context";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
// import firebase from "../../firebase";

// const fields = [
//   {
//     label: "Nom",
//     id: "label",
//   },
//   {
//     id: "abbreviation",
//     label: "Abréviation",
//   },
// ];

const columns = [
  {
    title: "Logo",
    render: (rowData) => (
      <img
        alt={`Logo ${rowData.school_short_name}`}
        className="logo-img"
        src={`${rowData.school_logo}`}
      />
    ),
    align: "center",
    cellStyle: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  {
    title: "Sigle",
    field: "school_short_name",
    filtering: false,
  },
  {
    title: "Nom de l'école",
    field: "school_full_name",
    filtering: false,
    cellStyle: {
      width: "100%",
      minWidth: "200px",
    },
  },
  {
    title: "Campus",
    field: "school_city",
    filtering: false,
    align: "center",
  },
  {
    title: "Formations",
    field: "nbr_formations",
    filtering: false,
    align: "center",
  },
];

const breadcrumbPath = {
  path: [{ name: "Administration" }],
};

const Schools = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  // const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [cities, setCities] = useState([]);

  const { db } = useContext(DatabaseContext);
  const schoolRef = db.collection("schools");
  // const campusRef = db.collection("campus");
  const actions = [
    {
      icon: Edit,
      tooltip: "Voir les détails de l'école",
      onClick: (_event, rowData) => history.push(`/schoolDetails/${rowData.id}`),
    },
    // {
    //   icon: DeleteOutline,
    //   tooltip: "Supprimer l'école",
    //   onClick: (_event, rowData) => history.push(`/brandDetails/${rowData.id}`),
    // },
  ];

  useEffect(() => {
    const citiesData = [];
    const citiesRef = db.collection("cities");
    citiesRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          citiesData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setCities(citiesData));
  }, []);

  useEffect(() => {
    if (!cities.length) return;
    const getData = async () => {
      let schoolsData = [];
      let ref = await schoolRef.get();

      for (let brand of ref.docs) {
        await schoolsData.push({
          ...brand.data(),
          id: brand.id,
          // formations: formationsCount,
          // Turn cities id into readable cities
          school_city: brand.data().school_city?.length || 0,

          // DISPLAY CITIES
          // brand
          //   .data()
          //   .school_city?.map((city) => cities.find((el) => el.id === city)?.label)
          //   .join(", ") || "",

          // .brand_city.map(
          //   (city) => cities.find((el) => el.id === city)?.label
          // )
          // .join(", "),
        });
      }
      setData(schoolsData);

      return;
    };
    // if (data.length) return;
    // if (!cities || data.length) return;
    try {
      getData();
    } catch (err) {
      console.log("err fetching data", err);
    }
  }, [cities]);

  // const createCampus = async (schoolData, school_id) => {
  //   try {
  //     let batch = firebase.firestore().batch();
  //     let schoolLocalRef;
  //     for (let city of cities) {
  //       schoolLocalRef = db.collection("campus").doc();
  //       await batch.set(schoolLocalRef, {
  //         city: city,
  //         school_name: schoolData.label,
  //         school_abbreviation: schoolData.abbreviation,
  //         school_id: school_id,
  //         is_active: false,
  //       });
  //     }
  //     batch.commit().then(() => {
  //       console.info("tous les campus ont été créés");
  //     });
  //   } catch (err) {
  //     console.log("error creating campus", err);
  //   }
  // };

  // const handleAdd = async (data) => {
  //   let schoolsData = [];
  //   schoolRef.add({ ...data, nbr_formations: 0 }).then((docRef) => {
  //     enqueueSnackbar("Document enregistré", {
  //       variant: "success",
  //     });
  //     createCampus(data, docRef.id);
  //     setShow(false);
  //     schoolRef
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           schoolsData.push({ ...doc.data(), id: doc.id });
  //         });
  //       })
  //       .then(() => {
  //         setData(schoolsData);
  //       });
  //   });
  // };

  return (
    <Grid container className="admin-view">
      <Grid item xs={12} className="admin-view-header">
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <Typography variant="h4" className="header-title">
            Écoles
          </Typography>
        </div>
        {/* <Button>Ajouter un titre RNPC</Button> */}
        {/* <AddForm
          fields={fields}
          onSubmit={handleAdd}
          show={show}
          setShow={setShow}
        /> */}
      </Grid>
      <Grid item xs={12} className="admin-view-table">
        {!!data.length && (
          <section className="admin-view-section">
            <h3 className="section-title">Liste des écoles</h3>
            <CustomTable
              title="Liste des écoles"
              columns={columns}
              data={data}
              actions={actions}
              size={size}
              setSize={setSize}
              page={page}
              setPage={setPage}
              totalElements={totalElements}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filter={false}
              // noSearch={true}
              autoFocus={true}
            />
          </section>
        )}
      </Grid>
    </Grid>
  );
};

export default Schools;

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  LinearProgress,
  Select,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
// import DetailsContent from "../../Components/Admin/Dashboard/DetailsContent";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DetailsContent from "../../Components/Admin/DetailsContent";
import CityManager from "./CityManager";
import { useSnackbar } from "notistack";
import Formations from "../../Components/Admin/Formations";
import { DatabaseContext } from "../../Context/Context";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { IconButton } from "@material-ui/core";

const SchoolDetails = ({ match }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [cities, setCities] = useState([]);

  const { db } = useContext(DatabaseContext);
  const schoolDocRef = db.collection("schools").doc(id);

  const breadcrumbPath = {
    path: [
      { name: "Administration" },
      {
        name: "Écoles",
        href: `/schools`,
      },
    ],
  };

  useEffect(() => {
    schoolDocRef.get().then((doc) => {
      if (!doc.exists) {
        console.error("Le document n'existe pas");
      }
      let fetchedData = {
        ...doc.data(),
        id: doc.id,
        title: doc.data().school_full_name,
      };
      setContent(fetchedData);
    });

    setLoading(false);

    const remoteCitiesData = [];
    const remoteRef = db.collection("cities");
    remoteRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          remoteCitiesData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setCities(remoteCitiesData));
  }, [id]);

  const updateSchool = (data, message) => {
    // Update DB
    schoolDocRef.update(data).then(() => {
      enqueueSnackbar(message, {
        variant: "success",
      });
    });
  };

  const switchCampusActivation = (city, setActive) => {
    db.collection("campus")
      .where("city", "==", city)
      .where("school_id", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          db.collection("campus").doc(doc.id).update({ is_active: setActive });
        });
      });
  };

  const handleDeleteCity = (cityId) => {
    const nextCities = content.school_city.filter((city) => city !== cityId);

    switchCampusActivation(cities.find((el) => el.id === cityId).id, false);

    setContent({ ...content, school_city: nextCities });
    updateSchool({ ...content, school_city: nextCities }, "Ville supprimée");
  };

  const handleAddCity = (cityId) => {
    const nextCities = content.school_city || [];
    nextCities.push(cities.find((el) => el.id === cityId).id);

    switchCampusActivation(cities.find((el) => el.id === cityId).id, true);

    setContent({ ...content, school_city: nextCities });
    updateSchool({ ...content, school_city: nextCities }, "Ville ajoutée");
  };

  if (!content || !cities.length) return <>Chargement</>;

  return (
    // <>
    //   AdminDetails {category} - {id}{" "}
    // </>>
    <Grid container className="admin-details">
      {loading && <LinearProgress />}
      <Grid item xs={12} className="admin-view-header">
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <div className="admin-infos-return">
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                history.push(`/schools`);
              }}
              className="ad-stroke-button dark-color icon-button"
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <Typography variant="h4" className="header-title">
              <img
                className="school-logo"
                src={`${content.school_logo}`}
                alt="Logo école"
              />
              {content.title}
            </Typography>
          </div>
        </div>
      </Grid>
      <div className="admin-detail-content">
        <div className="details-content-form">
          <div className="details-content-card">
            <Card className="details-card">
              <CardContent className="details-card-main">
                <CardHeader
                  title={`Campus (${content.school_city?.length || 0})`}
                  className="details-card-header"
                />
                <CardContent className="details-card-content city-card">
                  <CityManager
                    content={content}
                    cities={cities.filter((city) =>
                      content.school_city
                        ? !content.school_city
                            .map((el) => el)
                            ?.includes(city.id)
                        : cities
                    )}
                    handleAddCity={handleAddCity}
                  />

                  <Grid item xs={12}>
                    {content.school_city?.map((city) => {
                      return (
                        <Chip
                          label={cities.find((el) => el.id === city)?.label}
                          // label={cities.find((el) => el.id === city).label}
                          key={city}
                          onDelete={(e) => handleDeleteCity(city)}
                          style={{ marginRight: 10 }}
                          // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                          // className={classes.chip}
                        />
                      );
                    })}
                  </Grid>
                </CardContent>
              </CardContent>
            </Card>
          </div>
          <div className="details-content-card">
            <Card className="details-card">
              <CardContent className="details-card-main">
                <CardHeader
                  title={`Formations (${content.nbr_formations})`}
                  className="details-card-header"
                />
                <CardContent className="details-card-content">
                  {content.id && (
                    <Formations
                      schoolDocRef={schoolDocRef}
                      sigle={content.abbreviation}
                      school={content}
                    />
                  )}
                </CardContent>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default SchoolDetails;

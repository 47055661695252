import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import NumberFormat from "react-number-format";

import { makeStyles } from "@material-ui/core/styles";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { useContext, useEffect, useRef, useState } from "react";
import { DatabaseContext } from "../../../Context/Context";
import firebase from "../../../firebase";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import format from "date-fns/format";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale });
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const CustomField = ({ type, field, value, onChange, error, content }) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [hideItem, setHideItem] = useState(false);
  const { db } = useContext(DatabaseContext);
  const storageRef = firebase.storage();
  const [downloadUrl, setDownloadUrl] = useState([]);

  const ref = useRef();

  useEffect(() => {
    if (!field.remote) return;
    const remoteData = [];
    const remoteRef = field.fromOrigin
      ? firebase.firestore().collection(field.remote)
      : db.collection(field.remote);
    remoteRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          remoteData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setList(remoteData));
  }, [field.remote]);

  useEffect(() => {
    // Handle remote condition (year 1 / year 2 relative)
    if (!field.remoteCondition || !content) return;
    const remoteRef = db
      .collection(field.remoteCondition)
      .doc(content[field.conditionValue]);
    remoteRef.get().then((el) => {
      setHideItem(
        parseInt(el.data()?.[field.remoteConditionEndpoint]) >= 2 ? false : true
      );
    });
  }, [field.remoteCondition, content]);

  // GET DOWLOAD URL
  useEffect(async () => {
    if (type === "upload") {
      setDownloadUrl(await storageRef.ref(value).getDownloadURL());
    }
  }, []);

  const renderField = () => {
    if (hideItem) return <></>;

    switch (type) {
      case "siren":
      case "siret":
      case "nda":
      case "phone":
        return (
          <NumberFormat
            {...field}
            value={value}
            // name={name}
            // mask={mask}
            customInput={TextField}
            variant="outlined"
            fullWidth
            format={field.format}
            type="text"
            error={!!error}
            helperText={error ? error.message : null}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
          />
        );
      case "euros":
        return (
          <NumberFormat
            {...field}
            value={value}
            customInput={TextField}
            variant="outlined"
            fullWidth
            format={field.format}
            suffix="€"
            allowedDecimalSeparators={[",", "."]}
            thousandsGroupStyle="thousand"
            thousandSeparator=" "
            type="text"
            error={!!error}
            helperText={error ? error.message : null}
            onValueChange={({ value: v }) => {
              onChange({ target: { value: v } });
            }}
          />
        );
      case "select":
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id={field.id}>{field.label}</InputLabel>
            <Select
              disabled={field.disabled}
              autoWidth={true}
              labelId={field.id}
              fullWidth
              value={value || []}
              onChange={onChange}
              // input={<Input />}
              renderValue={(selected) => {
                const val = list.length
                  ? list.find((el) => el.id === selected)?.[field.endpoint]
                  : "";
                return <div className={classes.chips}>{val}</div>;
              }}
              MenuProps={MenuProps}
              variant="outlined"
            >
              {field.remote
                ? list.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el?.[field.endpoint]}
                    </MenuItem>
                  ))
                : field.list.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        );
      case "upload":
        const handleChange = (event) => {
          const files = Array.from(event.target.files);
          const [file] = files;
          if (!!onChange) onChange({ target: { value: file } });
        };

        return (
          <>
            <TextField
              // type="file"
              disabled
              label={field.label}
              variant="filled"
              fullWidth
              value={value?.name || value?.split("/").pop() || ""}
              onClick={() => ref.current?.click()}
              onChange={handleChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
            <input
              ref={ref}
              type="file"
              accept="application/pdf"
              // accept="image/*"
              hidden
              onChange={handleChange}
            />
            {
              // GET DOWLOAD URL
              // downloadUrl && console.log(downloadUrl)
            }
          </>
        );
      case "boolean":
        return (
          <FormGroup row style={{ paddingTop: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  error={error}
                  onChange={onChange}
                  name={field.label}
                  // onChange={(e) => handleLocalChange(e, id, "checkbox")}
                  checked={!!value}
                />
              }
              label={field.label}
            />
          </FormGroup>
        );
      case "date":
        value = value?.toDate ? value.toDate().toDateString() : value;

        return (
          <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
            <KeyboardDatePicker
              invalidDateMessage="Date incorrecte"
              fullWidth
              label={field.label}
              // disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={value || null}
              onChange={onChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        );
      case "multiselect":
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id={field.id}>{field.label}</InputLabel>
            <Select
              disabled={field.disabled}
              autoWidth={true}
              labelId={field.id}
              multiple
              fullWidth
              value={value || []}
              onChange={onChange}
              // input={<Input />}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {selected.map((selectedValue) => {
                      return (
                        <Chip
                          key={selectedValue}
                          label={
                            list.find((el) => el.id === selectedValue)?.[
                              field.endpoint
                            ] || selectedValue
                          }
                          className={classes.chip}
                        />
                      );
                    })}
                  </div>
                );
              }}
              MenuProps={MenuProps}
            >
              {list.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el[field.endpoint]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return (
          <TextField
            disabled={field.disabled}
            label={field.label}
            variant="outlined"
            fullWidth
            value={
              field.endpoint
                ? list.find((el) => el.id === value)?.[field.endpoint] || ""
                : value
            }
            // value={field.endpoint ? value[field.endpoint] : value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            type={type === "email" ? "email" : "text"}
          />
        );
    }
  };

  return renderField();
};

export default CustomField;

import { Button, Grid } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import CustomField from "../DetailsContent/CustomField";

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const AddForm = ({ fields, onSubmit, show, setShow }) => {
  const { control, handleSubmit, reset } = useForm();

  return (
    <>
      {show ? (
        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
          <Grid container className="add-grid-form">
            {fields.map((field) => {
              return (
                <Grid key={field.id} className="add-grid-input">
                  <Controller
                    name={field.id}
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const handleChange = (e) => {
                        if (field.label === "Libellé") {
                          e.target.value = capitalize(e.target.value);
                        }
                        onChange(e);
                      };
                      return (
                        <CustomField
                          type={field?.type}
                          field={field}
                          value={value}
                          onChange={handleChange}
                          error={error}
                        />
                      );
                    }}
                    rules={{ required: "Champs requis" }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container className="add-grid-button">
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                setShow(!show);
                reset();
              }}
              className="ad-main-button dark-color lg"
            >
              Annuler
            </Button>
            <Button
              type="submit"
              variant="outlined"
              className="ad-main-button main-color lg"
            >
              Créer
            </Button>
          </Grid>
        </form>
      ) : (
        <Grid container className="show-grid-button">
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setShow(!show);
              reset();
            }}
            className="show-button ad-main-button main-color lg"
          >
            Ajouter
          </Button>
        </Grid>
      )}
    </>
  );
};

export default AddForm;

import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

const Cities = ({ cities, city, setCity, lock }) => {
  // const [city, setCity] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    // setNextComponent(value);
    setCity(value);
  };

  useEffect(() => {
    // setState({ ...state, city: city });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return (
    <Grid item md={3} sm={6} xs={12}>
      <FormControl variant="outlined" disabled={lock} fullWidth={true}>
        <InputLabel id="demo-simple-select-outlined-label">Ville</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={city}
          onChange={handleChange}
          label="Ville"
        >
          {cities.map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {el.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default Cities;

import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import CreateLink from "./CreateLink";
import DisplayLinks from "./DisplayLinks";
import bddFormation from "../../../../../Templates/bddFormation.json";
import Loader from "../../../../../Services/Loader";
import firebase from "../../../../../firebase";

import "./manageContracts.scss";
import { DatabaseContext } from "../../../../../Context/Context";
import localforage from "localforage";

// VARIABLES

// let bdd = {
//   cerfa: bddFormation,
//   links: {},
// };

// FUNCTIONS

const ManageContracts = ({ user, perms, uid }) => {
  const { db } = useContext(DatabaseContext);
  const [fetch, setFetch] = useState({
    links: false,
  });
  const [open, setOpen] = useState(false);
  const [remunerations, setRemunerations] = useState();
  const [users, setUsers] = useState();
  const [bdd, setBdd] = useState({
    cerfa: bddFormation,
    links: {},
  });

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getCacheData = async () => {
      const localBdd = await localforage.getItem("localBdd");
      if (localBdd) setBdd(localBdd);

      const homeFetch = await localforage.getItem("homeFetch");
      if (homeFetch) setFetch(homeFetch);

      const remuns = await localforage.getItem("remuns");
      if (remuns) setRemunerations(remuns);

      const localUsers = await localforage.getItem("localUsers");
      if (localUsers) setUsers(localUsers);
    };

    getCacheData();
  }, []);

  const getLinks = () => {
    let linkRef = db.collection("links");

    // Filtering by perms
    const cp = "city_perms";
    const sp = "school_perms";
    const citiesFilter = [];
    const schoolsFilter = [];
    if (user[cp][0] !== 0) {
      for (const [, value] of user[cp].entries()) {
        citiesFilter.push(perms[cp][value]);
      }
      for (const [, value] of user[sp].entries()) {
        schoolsFilter.push(perms[sp][value]);
      }
      if (user[sp][0] !== 0) {
        linkRef = linkRef.where("infos.school", "in", schoolsFilter);
      }
      // Can't do this : You cannot use more than one 'in' filter
      // .where("infos.city", "in", citiesFilter);
    }

    linkRef.onSnapshot((querySnapshot) => {
      let filteredDatas = [];
      let datas = [];
      querySnapshot.forEach((doc) => {
        datas.push(doc.data());
        // Relative to a bug investigation on 22/06/2022
        // if (doc.data().identity?.apprentice_last_name?.value === "BONJOUR") {
        //   console.warn("BONJOUR", doc.data());
        //   console.warn("ID", doc.id);
        // }
      });

      if (user[cp][0] !== 0) {
        datas = datas.filter((el) => citiesFilter.includes(el.infos.city));
      }

      if (user[sp][0] !== 0) {
        for (const [, value] of user[sp].entries()) {
          filteredDatas = [
            ...filteredDatas,
            ...datas.filter((data) => {
              if (!data.infos) {
                console.warn("problem :", data);
                return;
              }
              return data.infos.school === perms[sp][value];
            }),
          ];
        }
        setBdd({ ...bdd, links: filteredDatas });
        localforage.setItem("localBdd", { ...bdd, links: filteredDatas });
        // bdd.links = filteredDatas;
      } else {
        localforage.setItem("localBdd", { ...bdd, links: datas });
        setBdd({ ...bdd, links: datas });
        // bdd.links = datas;
      }

      localforage.setItem("homeFetch", {
        ...fetch,
        links: true,
      });

      setFetch({
        ...fetch,
        links: true,
      });
    });
  };

  const getRemunerations = async () => {
    let tempDatas = {};
    await db
      .collection("remunerations")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          tempDatas = {
            ...tempDatas,
            [doc.id]: {
              startContract:
                doc.data().contract_date_duration.contract_start_date.value,
            },
          };
        });
      });
    localforage.setItem("remuns", tempDatas);
    await setRemunerations(tempDatas);
  };

  const getUsers = async () => {
    let tempDatas = {};
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          tempDatas = {
            ...tempDatas,
            [doc.id]: {
              firstname: doc.data().firstname,
              lastname: doc.data().lastname,
            },
          };
        });
      });

    localforage.setItem("localUsers", tempDatas);
    await setUsers(tempDatas);
  };

  useEffect(() => {
    getLinks();
    getRemunerations();
    getUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="manage-contracts">
      <div className="sct-header">
        <h1 className="header-title">Dashboard Admin</h1>
        {/* <h2 className='header-subtitle'></h2> */}
      </div>
      <div className="sct-content">
        {Object.values(fetch).every((item) => item) &&
        remunerations &&
        users ? (
          <div className="ctn-contracts">
            <Button
              variant="contained"
              id="btn-create-contract"
              color="primary"
              onClick={modalOpen}
            >
              CRÉER UN FORMULAIRE
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={modalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              className="modal-create-link"
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className="ctn-create-link">
                  <Grid className="cpn-add-link" item xs={12}>
                    <h1>Création d'une fiche</h1>
                    <CreateLink
                      datas={bdd.cerfa}
                      links={bdd.links}
                      user={user}
                      onClose={modalClose}
                      getLinks={getLinks}
                      uid={uid}
                    />
                  </Grid>
                </div>
              </Fade>
            </Modal>
            <Grid className="cpn-links" item xs={12}>
              <DisplayLinks
                bddLinks={bdd.links}
                // loadFilter={open}
                mainPerm={parseInt(user.main_perm, 10)}
                remunerations={remunerations}
                users={users}
              />
            </Grid>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

ManageContracts.propTypes = {
  user: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.number),
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    main_perm: PropTypes.number,
    picture: PropTypes.string,
    school_perms: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  perms: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.string),
    main_perm: PropTypes.arrayOf(PropTypes.string),
    school_perms: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  uid: PropTypes.string.isRequired,
};

export default ManageContracts;

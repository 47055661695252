import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";

const ResponsiveDataPie = ({ data, title }) => {
  return (
    <>
      <h3 style={{ marginBottom: -70, marginLeft: 30 }}>{title}</h3>
      <ResponsivePie
        data={data}
        margin={{ top: 90, right: 90, bottom: 90, left: 90 }}
        innerRadius={0.6}
        //   2 => label outside chart
        enableArcLabels={false}
        arcLinkLabel={(d) => `${d.id} (${d.formattedValue})`}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        colors={{ scheme: "set2" }}
        arcLinkLabelsStraightLength={0}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        legends={[
          {
            anchor: "left",
            direction: "column",
            justify: false,
            translateX: -55,
            translateY: 0,
            itemsSpacing: 25,
            itemWidth: 10,
            itemHeight: 15,
            itemTextColor: "#999",
            itemDirection: "bottom-to-top",
            itemOpacity: 1,
            symbolSize: 10,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

ResponsiveDataPie.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string,
    })
  ).isRequired,
};

export default ResponsiveDataPie;

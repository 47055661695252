import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
} from "@material-ui/core";
import { useEffect, useState } from "react";

// forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const FormationsCheckboxes = ({
  campus_id,
  control,
  handleSubmit,
  setValue,
  selectedFormations,
  setSelectedFormations,
  formations,
}) => {
  // const [formations, setFormations] = useState([]);
  const forceUpdate = useForceUpdate();

  const handleChange = (value, id) => {
    const previous = selectedFormations;
    if (value) {
      previous.push(id);
      setSelectedFormations(previous);
    } else {
      setSelectedFormations(previous.filter((el) => el !== id));
    }
    forceUpdate();

    return;
  };

  return (
    <Grid item xs={12} className="formations-checkboxes-main details-content-card">
      <Card className="details-card">
        <CardContent className="details-card-main">
          <CardHeader title="Formations" className="details-card-header" />
          <CardContent className="details-card-content">
            <Grid container>
              <Grid item xs={12}>
                <FormControl component="fieldset" className="formations-list">
                  <FormLabel component="legend" className="formations-list-legend">
                    Sélectionner les formations de cette école
                  </FormLabel>
                  <FormGroup>
                    {formations.map((formation) => {
                      const val = selectedFormations?.includes(formation.id) ? true : false;
                      return (
                        <FormControlLabel
                          key={formation.id}
                          className="form-switch"
                          control={
                            <Switch
                              checked={val}
                              // checked={formation.is_active}
                              // checked={selectedFormations?.includes(
                              //   formation.id
                              // )}
                              onChange={(_event, value) => handleChange(value, formation.id)}
                              name={formation.id}
                            />
                          }
                          label={formation.academic_exact_title}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FormationsCheckboxes;

import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import firestoreFunctions from "../../../../../Services/firestoreFunctions";
import Loader from "../../../../../Services/Loader";
import firebase from "../../../../../firebase";

import "./displaylinks.scss";
import FormTable from "../../../../FormTable";
import CustomDialog from "../../../../CustomDialog";
import { DatabaseContext } from "../../../../../Context/Context";
import { CircularProgress } from "@material-ui/core";

let selectedRowsIds = [];
let downloadLoading = false;

const DisplayLinks = ({ users, mainPerm, remunerations, bddLinks }) => {
  const { db } = useContext(DatabaseContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cerfaLoading, setCerfaLoading] = useState([]);
  const [conventionLoading, setConventionLoading] = useState([]);
  const [anchorCopyEl, setAnchorCopyEl] = useState(null);
  const [selectedRowId, setRowId] = useState("");
  const [selectedRowIdApprentice, setRowIdApprentice] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [openLink, setOpenLink] = useState(false);
  const [currentDlUrl, setCurrentDlUrl] = useState("");
  const [rowId, setSelectedRow] = useState(null);
  const statusList = [
    "À compléter",
    "À envoyer",
    "Envoyé",
    "Signé",
    "PEC reçue",
    "PEC en attente",
    "Rupture",
    "À vérifier",
    "À modifier",
    "À supprimer",
  ];

  const handleLinkClose = () => {
    setOpenLink(false);
  };

  const isProd = process.env.REACT_APP_ENV === "production";

  const changeStatus = (event) => {
    const { value } = event.target;
    const slug = event.target.name;
    event.stopPropagation();
    const fsDatas = {
      infos: {
        status: value,
      },
    };
    firestoreFunctions.dbWrite(db, "links", slug, fsDatas);
  };

  const openRow = () => {
    // eslint-disable-next-line no-unused-expressions
    rowId && window.open(`/form/${rowId}`);
  };

  useEffect(() => {
    openRow();
    // eslint-disable-next-line
  }, [rowId]);

  const handleSelection = (selectedRows) => {
    const tempSelectedIds = [];
    selectedRows.forEach((e) => {
      tempSelectedIds.push(e.id);
    });
    selectedRowsIds = tempSelectedIds;
  };

  const deleteClick = () => {
    setOpenDelete(true);
  };

  const deleteClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDelete(false);
  };

  const getPath = (
    links
  ) => `documents/${links.infos.school}/${links.infos.city}/
    ${links.identity.apprentice_last_name.value}_
    ${links.identity.apprentice_first_name.value}_${links.infos.id_company}`;

  const deleteRows = async () => {
    const collectionToDelete = [
      "links",
      "apprentices",
      "companies",
      "contracts",
      "formations",
      "progress",
      "remunerations",
    ];
    selectedRowsIds.forEach(async (id) => {
      // Construct path before deletion
      const links = await firestoreFunctions.dbGet(db, "links", id);
      const folderName = getPath(links);
      // Delete corresponding folder
      const deleteFolder = firebase.functions().httpsCallable("deleteFolder");
      deleteFolder({ folderName })
        .then((result) => {
          // Read result of the Cloud Function
          console.log("result", result);
        })
        .catch((err) => console.log("error deleting :", err));

      collectionToDelete.forEach((collection) => {
        firestoreFunctions.dbDeleteDoc(db, collection, id);
      });
    });

    setOpenDelete(false);
  };

  const downloadHandleClick = (event) => {
    event.stopPropagation();
    setRowId(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const copyHandleClick = (event) => {
    const splitId = event.currentTarget.id.split(" ");
    event.stopPropagation();
    setRowId(splitId[0]);
    setRowIdApprentice(splitId[1]);
    setAnchorCopyEl(event.currentTarget);
  };

  const handleLocalCopy = (id) => {
    if (id === "company") {
      enqueueSnackbar(
        `L'url du formulaire entreprise à bien été copié : ${selectedRowId}`,
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(
        `L'url du formulaire étudiant à bien été copié : ${selectedRowIdApprentice}`,
        {
          variant: "success",
        }
      );
    }
    setAnchorCopyEl(null);
  };

  const getFileName = (links, fileType) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    `${fileType === "cerfa" ? "Cerfa" : "Conv"}App_${links.infos.school}_${
      links.infos.city
    }_${links.infos.grade}_${links.infos.level}_${
      links.identity.apprentice_last_name.value
    }_${links.identity.apprentice_first_name.value}_${links.infos.id_company}.${
      fileType === "conv-pdf" || fileType === "cerfa" ? "pdf" : "docx"
    }`;

  const handleLocalDownload = async (fileType) => {
    const links = await firestoreFunctions.dbGet(db, "links", selectedRowId);
    const path = getPath(links);
    // "CerfaApp_ESP_Bordeaux_M1M2_CORPO_Jeremy_Di Meglio_7ybcu4syjg9.pdf"
    const fileName = getFileName(links, fileType);
    const storage = firebase.storage();
    const pathRef = storage.ref(`${path}/${fileName}`);
    pathRef
      .getDownloadURL()
      .then((url) => {
        setCurrentDlUrl(url);
        setOpenLink(true);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            enqueueSnackbar("Le document n'existe pas.", {
              variant: "warning",
            });
            break;
          case "storage/unauthorized":
            enqueueSnackbar(
              "Vous n'avez pas la permission de télécharger ce document.",
              {
                variant: "warning",
              }
            );
            break;
          default:
            enqueueSnackbar(
              "Une erreur est survenue lors de la récupération du lien de téléchargement.",
              {
                variant: "error",
              }
            );
        }
      });
  };

  const downloadHandleClose = (file) => {
    setAnchorEl(null);
    const loadingValue = [...cerfaLoading];
    loadingValue.push(selectedRowId);
    setCerfaLoading(loadingValue);
    downloadLoading = true;
    const year = sessionStorage.getItem("academic_year");

    if (!selectedRowId || !selectedRowId.length) {
      setCerfaLoading(cerfaLoading.filter((el) => el !== selectedRowId));
      enqueueSnackbar(`Le contrat n'a pas été correctement sélectionné`, {
        variant: "error",
      });
      return;
    }
    fetch(
      `https://europe-west1-re-webapp-v2-dev.cloudfunctions.net/${
        file === "cerfa" ? "generateCerfa" : "generateConvention"
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          id: selectedRowId,
          year: year,
          isProd: Boolean(isProd),
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        downloadLoading = false;
        setRowId("");
        if (res.success) {
          enqueueSnackbar("Nouveau document généré avec succès", {
            variant: "success",
          });
          // Download document
          const link = document.createElement("a");
          link.href = res.url;
          link.setAttribute("download", "ok.pdf");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          // End Download document
          // const typeFile = file === "generate-cerfa" ? "cerfa" : "conv-word";
          // handleLocalDownload(typeFile);
        } else {
          enqueueSnackbar(`Erreur : ${res.message}`, {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        downloadLoading = false;
        setRowId("");
        enqueueSnackbar(
          "Un problème de communication entre l'application et le serveur est survenu",
          {
            variant: "error",
          }
        );
      })
      .finally(() => {
        setCerfaLoading(cerfaLoading.filter((el) => el !== selectedRowId));
      });
    // }
  };

  const closeStatus = (event) => {
    event.stopPropagation();
  };

  // let rows = [];
  useEffect(() => {
    const tempRows = [];
    bddLinks.forEach((e) => {
      const i = e.infos;
      if (!i) {
        console.warn("Error spotted:", e);
        return;
      }
      tempRows.push({
        id: i.id_company,
        firstname: e.identity.apprentice_first_name.value,
        lastname: e.identity.apprentice_last_name.value,
        name: [
          e.identity.apprentice_last_name.value,
          e.identity.apprentice_first_name.value,
        ],
        school: i.school,
        city: i.city,
        establishment: [i.school, i.city],
        grade: i.grade,
        level: i.level,
        class: [i.grade, i.level],
        state: i.form_edit,
        startcontract: remunerations[i.id_company]
          ? remunerations[i.id_company].startContract
          : "",
        date: i.date,
        uid: i.created_by ? i.created_by : null,
        status: i.status || i.status === 0 ? i.status : statusList.length - 1,
        download: [
          <div key={`${i.id_company} ${i.id_apprentice}`}>
            <Tooltip title="Copier">
              <IconButton
                id={`${i.id_company} ${i.id_apprentice}`}
                className="btn-download"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={copyHandleClick}
              >
                <FileCopyRoundedIcon />
              </IconButton>
            </Tooltip>
            {
              // TEMPORARILY HIDE DONWLOAD BTN
              <Tooltip title="Télécharger un document">
                <IconButton
                  id={i.id_company}
                  className="btn-download"
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => {
                    if (!cerfaLoading.includes(i.id_company))
                      downloadHandleClick(event);
                    else event.stopPropagation();
                  }}
                >
                  {cerfaLoading.includes(i.id_company) ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CloudDownloadRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            }
          </div>,
        ],
      });
    });

    setRows(tempRows);
  }, [bddLinks, cerfaLoading]);

  return (
    <div className="display-links">
      {downloadLoading && <Loader />}
      <FormTable
        users={users}
        statusList={statusList}
        changeStatus={changeStatus}
        closeStatus={closeStatus}
        rows={rows}
        deleteClick={deleteClick}
        // setActiveFilter={setActiveFilter}
        // activeFilterClass={activeFilterClass}
        handleSelection={handleSelection}
        bddLinks={bddLinks}
      />

      {/* // Dialog delete row */}
      <CustomDialog
        open={openDelete}
        onClose={deleteClose}
        title={
          mainPerm === 0 || mainPerm === 2
            ? "Voulez vous supprimer les formulaires ?"
            : "Vous n'avez pas l'autorisation de supprimer les fomulaires"
        }
        mainText={
          mainPerm === 0 || mainPerm === 2
            ? "Attention cette action est irréversible et entrainera la suppression de l'ensemble des formulaires sélectionnés"
            : "Veuillez vous rapprocher d'un administrateur pour supprimer des données."
        }
        buttons={
          mainPerm === 0 || mainPerm === 2 ? (
            <>
              <Button onClick={deleteClose} color="primary">
                Annuler
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={deleteRows}
                autoFocus
              >
                Supprimer
              </Button>
            </>
          ) : (
            <Button onClick={deleteClose} color="primary">
              Revenir en arrière
            </Button>
          )
        }
        icons={<WarningRoundedIcon />}
      />

      {/* Dialog to display download link */}
      <CustomDialog
        open={openLink}
        onClose={handleLinkClose}
        title="Lien de téléchargement du fichier demandé"
        mainText={
          <a href={currentDlUrl} download>
            Cliquez ici pour télécharger le fichier
          </a>
        }
        buttons={
          <Button onClick={handleLinkClose} color="primary" autoFocus>
            Fermer
          </Button>
        }
      />
      {/* <Dialog
        open={openLink}
        onClose={handleLinkClose}
        aria-labelledby="dialog-download-link-title"
        aria-describedby="dialog-download-link-content"
      >
        <DialogTitle id="dialog-download-link-title">
          Lien de téléchargement du fichier demandé
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-download-link-content">
            <a href={currentDlUrl} download>
              Cliquez ici pour télécharger le fichier
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLinkClose} color="primary" autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* Generate menu */}
      <Menu
        id="download-actions"
        className="shadow"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          className="cerfa"
          onClick={() => downloadHandleClose("cerfa")}
        >
          <ListItemIcon className="cerfa-icon">
            <GetAppRoundedIcon fontSize="small" />
          </ListItemIcon>
          CERFA
        </MenuItem>
        <MenuItem
          className="convention"
          onClick={() => downloadHandleClose("convention")}
        >
          <ListItemIcon className="convention-icon">
            <GetAppRoundedIcon fontSize="small" />
          </ListItemIcon>
          Convention
        </MenuItem>
      </Menu>
      {/* Copy menu */}
      <Menu
        anchorEl={anchorCopyEl}
        keepMounted
        open={Boolean(anchorCopyEl)}
        onClose={() => setAnchorCopyEl(null)}
      >
        <CopyToClipboard
          text={
            isProd
              ? `https://alternance.ad-education.com/form/${selectedRowId}`
              : `https://re-webapp-v2-dev.web.app/form/${selectedRowId}`
          }
        >
          <MenuItem onClick={() => handleLocalCopy("company")}>
            Lien entreprise
          </MenuItem>
        </CopyToClipboard>
        <CopyToClipboard
          text={
            isProd
              ? `https://alternance.ad-education.com/form/${selectedRowIdApprentice}`
              : `https://re-webapp-v2-dev.web.app/form/${selectedRowIdApprentice}`
          }
        >
          <MenuItem onClick={() => handleLocalCopy("apprentice")}>
            Lien étudiant
          </MenuItem>
        </CopyToClipboard>
      </Menu>
      {/* END MENUS */}
    </div>
  );
};

DisplayLinks.propTypes = {
  users: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.number),
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    main_perm: PropTypes.string,
    picture: PropTypes.string,
    school_perms: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  bddLinks: PropTypes.arrayOf(
    PropTypes.shape({
      identity: PropTypes.shape({
        apprentice_first_name: PropTypes.shape({
          value: PropTypes.string,
        }),
        apprentice_last_name: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
      infos: PropTypes.shape({
        city: PropTypes.string,
        created_by: PropTypes.string,
        grade: PropTypes.string,
        id_apprentice: PropTypes.string,
        id_company: PropTypes.string,
        level: PropTypes.string,
        school: PropTypes.string,
        state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        status: PropTypes.number,
      }),
    })
  ).isRequired,
  mainPerm: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  remunerations: PropTypes.object.isRequired,
};

export default DisplayLinks;

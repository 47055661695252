import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

// import MaterialTable, { MTableFilterRow } from "material-table";
import MaterialTable, { MTableFilterRow } from "@material-table/core";
import { FormControl, MenuItem, Select, Tooltip } from "@material-ui/core";

import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Loader from "../../Services/Loader";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

let nameFilters = {};
let establishmentFilters = {};
let classFilters = {};
let statusFilters = {};

const FormTable = ({
  users,
  statusList,
  changeStatus,
  closeStatus,
  rows,
  deleteClick,
  handleSelection,
  bddLinks,
}) => {
  const [filtersFetching, setFiltersFetching] = useState(true);
  const [activeFilterClass, setActiveFilter] = useState("inactive");

  const activeFilter = () => {
    if (activeFilterClass === "inactive") setActiveFilter("");
    else setActiveFilter("inactive");
  };

  const setFilters = () => {
    setFiltersFetching(false);

    bddLinks.forEach((e) => {
      const i = e.infos;
      if (!i) {
        console.warn("Error spotted:", e);
        return;
      }

      const newName = {
        ...nameFilters,
        [[
          e.identity.apprentice_last_name.value,
          e.identity.apprentice_first_name.value,
        ]]: `${e.identity.apprentice_first_name.value} ${e.identity.apprentice_last_name.value}`,
      };
      nameFilters = newName;

      const newEstablishment = {
        ...establishmentFilters,
        [[i.school, i.city]]: `${i.school} ${i.city}`,
      };
      establishmentFilters = newEstablishment;

      const newClass = {
        ...classFilters,
        [[i.grade, i.level]]: `${i.grade} ${i.level}`,
      };
      classFilters = newClass;
      const statusNumber =
        i.status || i.status === 0 ? i.status : statusList.length - 1;
      const newStatus = {
        ...statusFilters,
        [statusNumber]: `${statusList[statusNumber]}`,
      };
      statusFilters = newStatus;
    });
  };

  useEffect(() => {
    setFilters();
    // eslint-disable-next-line
  }, []);

  if (filtersFetching) return <Loader />;

  return (
    <div className="material-links">
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: "Date",
            field: "date",
            defaultSort: "desc",
            customSort: (a, b) => {
              const aDate = a.date.split("/");
              const bDate = b.date.split("/");

              // Extract day
              const aDay = aDate.splice(0, 1)[0];
              const bDay = bDate.splice(0, 1)[0];

              // Format the date the right way
              aDate.splice(1, 0, aDay);
              bDate.splice(1, 0, bDay);

              return (
                new Date(aDate.join("/")).getTime() -
                new Date(bDate.join("/")).getTime()
              );
            },
            hidden: true,
          },
          {
            title: "Étudiant",
            field: "name",
            filtering: false,
            render: (rowData) => {
              return (
                <Tooltip
                  title={
                    rowData.uid != null && users[rowData.uid]
                      ? `Gestionnaire : ${users[rowData.uid]?.firstname} ${
                          users[rowData.uid]?.lastname
                        }`
                      : "Gestionnaire inconnu"
                  }
                  aria-label="aide"
                >
                  <div>
                    <p className="info-main">{rowData.lastname}</p>
                    <p className="info-secondary">{rowData.firstname}</p>
                  </div>
                </Tooltip>
              );
            },
            lookup: nameFilters,
          },
          {
            title: "Campus",
            field: "establishment",
            render: (rowData) => (
              <div>
                <p className="info-main">{rowData.school}</p>
                <p className="info-secondary">{rowData.city}</p>
              </div>
            ),
            lookup: establishmentFilters,
          },
          {
            title: "Classe",
            field: "class",
            render: (rowData) => (
              <div>
                <p className="info-main">{rowData.grade}</p>
                <p className="info-secondary">{rowData.level}</p>
              </div>
            ),
            lookup: classFilters,
          },
          {
            title: "Début du contrat",
            field: "startcontract",
            // filtering: false,
            customSort: (a, b) => {
              const splitA = a.startcontract.split("/");
              const splitB = b.startcontract.split("/");
              const resultA = new Date(
                `${splitA[1]}/${splitA[0]}/${splitA[2]}`
              );
              const resultB = new Date(
                `${splitB[1]}/${splitB[0]}/${splitB[2]}`
              );
              return (
                (resultB.getTime() || -Infinity) -
                (resultA.getTime() || -Infinity)
              );
            },
            render: (rowData) => (
              <div>
                <p className="info-secondary">{rowData.startcontract}</p>
              </div>
            ),
          },
          {
            title: "État",
            field: "state",
            filtering: false,
            render: (rowData) => (
              <Tooltip
                title={
                  rowData.state
                    ? "Le formulaire est en cours de modification"
                    : "Le formulaire a été validé par l'entreprise"
                }
                aria-label="aide"
              >
                {/* Ajouter remunerations.contract_date_duration.contract_start_date.value
                    dans le tableau */}
                <div className={`info-state state-${rowData.state}`} />
              </Tooltip>
            ),
          },
          {
            title: "Statut",
            field: "status",
            defaultSort: "desc",
            render: (rowData) => (
              <FormControl
                title={statusList[rowData.status]}
                className="ctn-status"
                variant="outlined"
                value={rowData.status}
              >
                <Select
                  className={`status-select status-${rowData.status}`}
                  name={rowData.id}
                  value={rowData.status}
                  onChange={changeStatus}
                  onClose={closeStatus}
                >
                  <MenuItem className="incomplete status-0" value={0}>
                    {statusList[0]}
                  </MenuItem>
                  <MenuItem value={7}>{statusList[7]}</MenuItem>
                  <MenuItem value={8}>{statusList[8]}</MenuItem>
                  <MenuItem className="to-send status-1" value={1}>
                    {statusList[1]}
                  </MenuItem>
                  <MenuItem className="send status-2" value={2}>
                    {statusList[2]}
                  </MenuItem>
                  <MenuItem className="signed status-3" value={3}>
                    {statusList[3]}
                  </MenuItem>
                  <MenuItem className="pec-received status-4" value={4}>
                    {statusList[4]}
                  </MenuItem>
                  <MenuItem className="pec-waiting status-5" value={5}>
                    {statusList[5]}
                  </MenuItem>
                  <MenuItem className="break status-6" value={6}>
                    {statusList[6]}
                  </MenuItem>
                  <MenuItem className="break status-9" value={9}>
                    {statusList[9]}
                  </MenuItem>
                </Select>
              </FormControl>
            ),
            lookup: statusFilters,
          },
          {
            title: "Actions",
            field: "download",
            filtering: false,
            sorting: false,
          },
        ]}
        data={rows}
        onRowClick={(evt, selectedRow) => {
          window.open(`/form/${selectedRow.id}`, "_blank");
          evt.stopPropagation();
        }}
        title="Liste des formulaires"
        options={{
          pageSize: 20,
          filtering: true,
          selection: true,
          search: true,
        }}
        actions={[
          {
            tooltip: "Supprimer",
            icon: DeleteOutline,
            onClick: () => deleteClick(),
          },
          {
            icon: FilterList,
            tooltip: "Filtres",
            isFreeAction: true,
            onClick: activeFilter,
          },
        ]}
        components={{
          FilterRow: (rowProps) => (
            <>
              <MTableFilterRow {...rowProps} />
            </>
          ),
        }}
        onSelectionChange={(selectedRows) => handleSelection(selectedRows)}
      />
    </div>
  );
};

FormTable.propTypes = {
  users: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.number),
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    main_perm: PropTypes.string,
    picture: PropTypes.string,
    school_perms: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  bddLinks: PropTypes.arrayOf(
    PropTypes.shape({
      identity: PropTypes.shape({
        apprentice_first_name: PropTypes.shape({
          value: PropTypes.string,
        }),
        apprentice_last_name: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
      infos: PropTypes.shape({
        city: PropTypes.string,
        created_by: PropTypes.string,
        grade: PropTypes.string,
        id_apprentice: PropTypes.string,
        id_company: PropTypes.string,
        level: PropTypes.string,
        school: PropTypes.string,
        state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        status: PropTypes.number,
      }),
    })
  ).isRequired,
  statusList: PropTypes.arrayOf(
    PropTypes.oneOf([
      "À compléter",
      "À envoyer",
      "Envoyé",
      "Signé",
      "PEC reçue",
      "PEC en attente",
      "Rupture",
      "À vérifier",
      "À modifier",
      "À supprimer",
    ])
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteClick: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  closeStatus: PropTypes.func.isRequired,
};

export default FormTable;

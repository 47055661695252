import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CustomDialog = ({ open, onClose, title, mainText, buttons, icon }) => (
  <Dialog open={open} id="delete-modal" onClose={onClose}>
    <DialogTitle id="alert-dialog-title">
      {icon && icon}
      <span className="modal-title">{title}</span>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{mainText}</DialogContentText>
    </DialogContent>
    <DialogActions>{buttons}</DialogActions>
  </Dialog>
);

CustomDialog.defaultProps = {
  icon: false,
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default CustomDialog;

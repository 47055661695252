const detailsTableRef = {
  cities: {
    title: "Villes",
    subtitle: "Liste des villes",
    fields: [
      {
        label: "Nom de la ville",
        id: "label",
      },
      {
        label: "Code",
        id: "city_code",
      },
    ],
    columns: [
      {
        title: "Nom de la ville",
        field: "label",
      },
      {
        title: "Code",
        field: "city_code",
      },
    ],
  },
  grades: {
    title: "Niveaux",
    subtitle: "Liste des niveaux",
    fields: [
      {
        label: "Libellé",
        id: "grade_name",
      },
      {
        label: "Code",
        id: "grade_code",
      },
      {
        label: "Nombre d'années",
        id: "nbr_year",
      },
    ],
    columns: [
      {
        title: "Libellé",
        field: "grade_name",
      },
      {
        title: "Code",
        field: "grade_code",
      },
      {
        title: "Nombre d'années",
        field: "nbr_year",
      },
    ],
  },
  rncp_titles: {
    title: "Niveau RNCP",
    subtitle: "Liste des niveaux RNCP",
    fields: [
      {
        label: "Libellé",
        id: "rncp_level_label",
      },
      {
        label: "Code",
        id: "rncp_level_code",
      },
    ],
    columns: [
      {
        title: "Libellé",
        field: "rncp_level_label",
      },
      {
        title: "Code",
        field: "rncp_level_code",
      },
    ],
  },
  rncp_goals: {
    title: "Codes RNCP",
    subtitle: "Liste des codes RNCP",
    fields: [
      {
        label: "Code",
        id: "rncp_goal",
      },
      {
        label: "Libellé",
        id: "rncp_goal_label",
      },
    ],
    columns: [
      {
        title: "Code",
        field: "rncp_goal",
      },
      {
        title: "Libellé",
        field: "rncp_goal_label",
      },
      {
        title: "Libellé long",
        field: "rncp_goal_full",
      },
    ],
  },
  schools: {
    title: "Écoles",
    subtitle: "Liste des écoles",
    fields: [
      {
        label: "Libellé court",
        id: "school_short_name",
      },
      {
        label: "Nom de l'école",
        id: "school_full_name",
      },
      {
        label: "Logo",
        id: "school_logo",
      },
    ],
    columns: [
      // {
      //   title: "Logo de l'école",
      //   field: "school_logo",
      // },
      {
        title: "Libellé court",
        field: "school_short_name",
      },
      {
        title: "Nom de l'école",
        field: "school_full_name",
      },
    ],
  },
};

export { detailsTableRef };

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

const AccountAdminDialog = ({
  openAccountAdmin,
  setOpenAccountAdmin,
  enqueueSnackbar,
}) => {
  const [pwdError, setPwdError] = useState(false);
  const [errorReason, setErrorReason] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const handleClose = () => {
    setOpenAccountAdmin(false);
  };

  const handleChangePwd = () => {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
    );

    if (password !== passwordRepeat) {
      setPwdError(true);
      setErrorReason('Veuillez entrer 2 fois le même mot de passe');
      return;
    }
    if (!strongRegex.test(password)) {
      setPwdError(true);
      setErrorReason(
        'Le mot de passe doit contenir plus de 7 charactères et au moins 1 majuscule, minuscule et chiffre',
      );
      return;
    }

    setPwdError(false);
    setErrorReason('');
    const user = firebase.auth().currentUser;
    user
      .updatePassword(password)
      .then(() => {
        enqueueSnackbar('Mot de passe mis à jour avec succès', {
          variant: 'success',
        });
        setPassword('');
        setPasswordRepeat('');
      })
      .catch((error) => {
        enqueueSnackbar(
          `Erreur lors du changement de mot de passe : ${error}`,
          {
            variant: 'error',
          },
        );
      });
  };

  return (
    <Dialog
      open={openAccountAdmin}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Gestion du compte</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous pouvez changer votre mot de passe ici :
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Nouveau mot de passe"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={pwdError}
          helperText={errorReason}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Répéter le nouveau mot de passe"
          type="password"
          fullWidth
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          error={pwdError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fermer
        </Button>
        <Button onClick={handleChangePwd} color="primary">
          Changer le mot de passe
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountAdminDialog.propTypes = {
  openAccountAdmin: PropTypes.bool.isRequired,
  setOpenAccountAdmin: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default AccountAdminDialog;

import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../Services/Loader";
import Schools from "./Inputs/Schools";
import Cities from "./Inputs/Cities";
import Grades from "./Inputs/Grades";
import Levels from "./Inputs/Levels";
import Student from "./Inputs/Student";
import firestoreFunctions from "../../../../../Services/firestoreFunctions";
import apprentice from "../../../../../Templates/apprentice.json";
import company from "../../../../../Templates/company.json";
import contract from "../../../../../Templates/contract.json";
import link from "../../../../../Templates/link.json";
import formation from "../../../../../Templates/formation.json";
import remuneration from "../../../../../Templates/renumeration.json";
// import progress from '../../../Templates/progress.json'
import Grid from "@material-ui/core/Grid";
import { DatabaseContext } from "../../../../../Context/Context";
import AutoCompleteUsers from "./Inputs/AutoCompleteUsers";
import { LinearProgress, Typography } from "@material-ui/core";
import firebase from "../../../../../firebase";

const CreateLink = (props) => {
  const { db } = useContext(DatabaseContext);
  const [perms, setPerms] = useState(null);
  const [students, setStudents] = useState([]);
  const [disableForm, setDisableForm] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allCities, setAllCities] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [associatedFormations, setAssociatedFormations] = useState([]);
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState(null);
  const [selectedFormation, setSelectedFormation] = useState([]);
  const [level, setLevel] = useState(null);
  const [apiWaiting, setApiWaiting] = useState(false);

  const [state, setState] = useState({
    isFetching: false,
    inputLock: 0,
    links: props.links,
    datas: props.datas,
    schools: [],
    school: "",
    cities: [],
    city: "",
    grades: [],
    grade: "",
    levels: [],
    level: "",
    lastname: "",
    firstname: "",
    status: 0,
    cerfa: {},
    apprentice: apprentice,
    company: company,
    contract: contract,
    link: link,
    remuneration: remuneration,
    formation: formation,
    disableButton: true,
  });

  useEffect(() => {
    const getPerms = async () => {
      const globalDb = firebase.firestore();
      const response = await firestoreFunctions.dbGet(
        globalDb,
        "perms",
        "perms"
      );
      setPerms(response);
    };

    getPerms();
  }, []);

  useEffect(() => {
    if (!perms) return;
    const schoolsData = [];
    db.collection("schools")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          schoolsData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(async () => {
        const schoolPerms = props.user.school_perms;
        let allowedSchools = [];
        if (schoolPerms[0] === 0) {
          allowedSchools = schoolsData;
        } else {
          allowedSchools = schoolsData.filter((el) =>
            schoolPerms
              .map((perm) => perms.school_perms[perm])
              .includes(el.school_short_name)
          );
        }

        setSchools(allowedSchools);
      });

    const allCitiesData = [];
    db.collection("cities")
      .get()
      .then((query) => {
        query.forEach((doc) => {
          allCitiesData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setAllCities(allCitiesData));

    const allGradesData = [];
    db.collection("grades")
      .get()
      .then((query) => {
        query.forEach((doc) => {
          allGradesData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setAllGrades(allGradesData));
  }, [perms]);

  // useEffect(() => {
  //   // Load userList from intranet or from local cache
  //   const localUsersList = localStorage.getItem("usersList");
  //   if (localUsersList) {
  //     setUsersList(JSON.parse(localUsersList));
  //   } else {
  //     fetch(
  //       "https://intranet.ad-education.com/externalData.php?uauth=jH2AAQx4q8FbH3ZTvdU4ZepfW7Zd3ZRqChwFw3gXqNv8t73q&f=csv_exportinscriptions&aa=2022-2023&json"
  //     )
  //       .then((res) => res.json())
  //       .then((res) => {
  //         const toObject = Object.values(res);
  //         res = setUsersList(toObject);
  //         console.log("gonna save this", JSON.stringify(toObject));
  //         localStorage.setItem("usersList", JSON.stringify(toObject));
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (!school) {
      setCity(null);
      return;
    }
    const selectedSchool = schools.find((el) => el.id === school);
    let citiesData = allCities.filter((el) =>
      selectedSchool.school_city?.includes(el.id)
    );

    const citiesPerms = props.user.city_perms;
    let allowedCities = [];
    if (citiesPerms[0] === 0) {
      allowedCities = citiesData;
    } else {
      allowedCities = citiesData.filter((el) =>
        citiesPerms.map((perm) => perms.city_perms[perm]).includes(el.label)
      );
    }

    setCities(allowedCities);
    setCity(null);
  }, [school]);

  useEffect(() => {
    setGrade(null);
    setAssociatedFormations([]);
    if (!city) {
      return;
    }
    const fetchedGrades = [];
    db.collection("campus")
      .where("city", "==", city)
      .where("school_id", "==", school)
      .get()
      .then((querySnapshot) => {
        let campusId;
        querySnapshot.forEach((doc) => {
          campusId = doc.data().id;
        });
        return campusId;
      })
      .then((campusId) => {
        db.collection("campus")
          .doc(campusId)
          .collection("formations")
          .get()
          .then((querySnapshot) => {
            let associatedFormations = [];
            querySnapshot.forEach((formation) => {
              // rncpData.push({ ...doc.data(), id: doc.id });
              fetchedGrades.push(formation.data().academic_grade);
              associatedFormations.push(formation.data());
            });
            setGrades(allGrades.filter((el) => fetchedGrades.includes(el.id)));
            setAssociatedFormations(associatedFormations);
          });
      });
  }, [city]);

  useEffect(() => {
    if (!grade) {
      setLevel(null);
      setSelectedFormation([]);
      return;
    }

    setSelectedFormation(
      associatedFormations
        .filter((el) => el.academic_grade === grade)
        .filter((el) => el.completed)
    );
  }, [grade]);

  useEffect(() => {
    if (!level) {
      setUsersList([]);
      return;
    }
    setStudents([]);

    const getClassStudents = async () => {
      const cityName = cities.find((el) => el.id === city).label;
      const classStudenstIds = await db
        .collection("links")
        .where("infos.linked_formation", "==", level)
        .where("infos.city", "==", cityName)
        .get()
        .then((snap) => {
          const result = [];
          snap.forEach((doc) => {
            result.push(doc.id);
          });
          return result;
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      const classStudents = [];
      for (const studentId of classStudenstIds) {
        await db
          .collection("apprentices")
          .doc(studentId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              classStudents.push(doc.data());
            } else {
              console.log("Student doesnt exists :", studentId);
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
      setApiWaiting(false);
      setStudents(classStudents);
    };

    const academicYear = sessionStorage.getItem("academic_year");
    const selectedSchool = schools.find((el) => el.id === school);
    const selectedCampus = `${selectedSchool.school_short_name.toUpperCase()}_${allCities
      .find((el) => el.id === city)
      .city_code.toUpperCase()}`;
    const academic_code = selectedFormation.find(
      (el) => el.id === level
    )?.academic_code;

    let filtres = "";

    if (selectedSchool.school_short_name === "ECV") {
      if (selectedCampus === "ECV_PA") {
        filtres =
          "filtres[campus][]=ECV_BU&filtres[campus][]=ECV_DA&filtres[campus][]=ECV_PE";
      } else if (selectedCampus === "ECV_BO") {
        filtres = "filtres[campus][]=ECV_BAF";
      } else if (selectedCampus === "ECV_LI") {
        filtres = "filtres[campus][]=ECV_MO";
      } else {
        filtres = `filtres[campus][]=${selectedCampus}`;
      }
    } else {
      filtres = `filtres[campus][]=${selectedCampus}`;
    }

    setApiWaiting(true);
    getClassStudents();
    const baseUrl = `https://intranet.ad-education.com/externalData.php?uauth=BSdMBKkquDKZmLsyeqRRjpjXxkmaJVrHZuqpYAuAkwaEkFwV&f=csv_exportinscriptions&filtres[statuts][]=INSCRIT&filtres[niveaux][]=${academic_code
      .split("_")
      .pop()}
    `;

    fetch(`${baseUrl}&aa=${academicYear}&${filtres}&json`)
      .then((res) => res.json())
      .then((res) => {
        // setApiWaiting(false);
        const toObject = Object.values(res);
        // res = setUsersList(toObject);
        // console.log("gonna save this", JSON.stringify(toObject));
        // localStorage.setItem("usersList", JSON.stringify(toObject));

        // console.log("gonna filter on :", filters);
        // const filteredUsers = usersList.filter(function (item) {
        //   for (var key in filters) {
        //     if (item[key] === undefined || item[key] !== filters[key])
        //       return false;
        //   }
        //   return true;
        // });

        const result = toObject
          // .filter((el) => el["Statut Candidature"] === "INSCRIT")
          .filter(
            (el) =>
              el["Formation"] ===
              academic_code.substr(0, academic_code.lastIndexOf("_"))
          );
        // .filter((el) => el["Niveau"] === academic_code.split("_").pop());

        if (result) setSelectedUsers(result);
        else setSelectedUsers([]);
      })
      .catch((err) => {
        console.error("Error fetching intranet ", err);
        setApiWaiting(false);
      });
  }, [level]);

  useEffect(() => {
    if (
      school &&
      city &&
      grade &&
      level &&
      ((state.lastname.length && state.firstname.length) ||
        selectedUser?.["Prénom"])
    ) {
      setDisableForm(false);
    } else {
      setDisableForm(true);
    }
  }, [
    school,
    city,
    grade,
    level,
    state.lastname,
    state.firstname,
    selectedUser,
  ]);
  // useEffect(() => {
  //   if (
  //     state.school !== "" &&
  //     state.city !== "" &&
  //     state.grade !== "" &&
  //     state.level !== "" &&
  //     state.lastname.length > 0 &&
  //     state.firstname.length > 0
  //   ) {
  //     setState({ ...state, disableButton: false });
  //   } else {
  //     setState({ ...state, disableButton: true });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   state.school,
  //   state.city,
  //   state.grade,
  //   state.level,
  //   state.firstname,
  //   state.lastname,
  // ]);

  useEffect(() => {
    const fetchSchools = () => {
      var array = state.datas.map((data) => data.school);
      var newArray = [...new Set(array)];

      let userPerms = [];

      props.user.school_perms.forEach((el, index) => {
        if (el !== 0) {
          userPerms.push(perms.school_perms[el]);
        }
      });

      let allowedSchools = [];

      if (props.user.school_perms[0] === 0) {
        allowedSchools = newArray.filter((el) =>
          perms.school_perms.includes(el)
        );
      } else {
        allowedSchools = newArray.filter((el) => userPerms.includes(el));
      }

      setState({ ...state, schools: allowedSchools, isFetching: false });
    };

    if (perms) fetchSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.datas, perms]);

  // const setCities = (school) => {
  //   const dataFilter = state.datas.filter((data) => data.school === school);
  //   var array = dataFilter.map((data) => data.city);
  //   var newArray = [...new Set(array)];

  //   let userPerms = [];

  //   props.user.city_perms.forEach((el, index) => {
  //     if (el !== 0) {
  //       userPerms.push(perms.city_perms[el]);
  //     }
  //   });

  //   let allowedCities = [];

  //   if (props.user.city_perms[0] === 0) {
  //     allowedCities = newArray.filter((el) => perms.city_perms.includes(el));
  //   } else {
  //     allowedCities = newArray.filter((el) => userPerms.includes(el));
  //   }
  //   setState({ ...state, cities: allowedCities });
  // };

  // const setGrades = (city) => {
  //   const dataFilterSchool = state.datas.filter(
  //     (data) => data.school === state.school
  //   );
  //   const dataFilterCity = dataFilterSchool.filter(
  //     (data) => data.city === city
  //   );
  //   var array = dataFilterCity.map((data) => data.grade);
  //   var newArray = [...new Set(array)];
  //   setState({ ...state, grades: newArray });
  //   // setState({ ...state, cerfa: {} });
  // };

  // const setLevels = (grade) => {
  //   const dataFilterSchool = state.datas.filter(
  //     (data) => data.school === state.school
  //   );
  //   const dataFilterCity = dataFilterSchool.filter(
  //     (data) => data.city === state.city
  //   );
  //   const dataFilterGrade = dataFilterCity.filter(
  //     (data) => data.grade === grade
  //   );
  //   var array = dataFilterGrade.map((data) => data.level);
  //   var newArray = [...new Set(array)];
  //   setState({ ...state, levels: newArray });
  //   // setState({ ...state, cerfa: {} });
  // };

  // const getCerfaData = (level) => {
  //   const dataFilterSchool = state.datas.filter(
  //     (data) => data.school === state.school
  //   );
  //   const dataFilterCity = dataFilterSchool.filter(
  //     (data) => data.city === state.city
  //   );
  //   const dataFilterGrade = dataFilterCity.filter(
  //     (data) => data.grade === state.grade
  //   );
  //   const cerfaData = dataFilterGrade.filter((data) => data.level === level);
  //   setState({ ...state, cerfa: cerfaData });
  // };

  // const valueUpdate = (key, value) => {
  //   setState({ ...state, [key]: value });
  //   // setState({ ...state, inputLock: newInputLock });
  // };

  const inputUpdate = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const createLink = async () => {
    let randomIdCompany = Math.random().toString(36).substr(2, 30);
    let randomIdApprentice = Math.random().toString(36).substr(2, 30);
    let idsList = [];

    for (const element of state.links) {
      idsList.push(element.id_company);
    }
    const checkId = state.links.filter((link) => link === randomIdCompany);

    const selectedSchool = schools.find((el) => el.id === school);
    const selectedGrade = allGrades.find((el) => el.id === grade).grade_code;
    // const selectedLevel = all.find((el) => el.id === grade).grade_code;
    const validatedFormation = selectedFormation.find(
      (el) => el.formation_id === level
    );
    // const validatedFormation = selectedFormation.find((el) => el.id === level);
    // return;
    if (checkId.length === 0) {
      // link.infos = {
      //   city: state.city,
      //   school: state.school,
      //   grade: state.grade,
      //   level: state.level,
      //   status: state.status,
      //   created_by: props.uid,
      //   id_company: randomIdCompany,
      //   id_apprentice: randomIdApprentice,
      //   form_edit: true,
      //   date: new Date().toLocaleString("fr"),
      // };

      let cfa = await db
        .collection("schools")
        .doc(validatedFormation.school_id)
        .get()
        .then(async (doc) => {
          return await db
            .collection("cfa")
            .doc(doc.data().cfa_associated[0])
            .get()
            .then((el) => el.data());
          // doc.data();
        });

      let rncp = await db
        .collection("rncp")
        .doc(validatedFormation.linked_rncp)
        .get()
        .then((doc) => doc.data());

      // (state.lastname.length && state.firstname.length))
      const studentInfos = !selectedUser
        ? { firstname: state.firstname, lastname: state.lastname }
        : {
            firstname: selectedUser["Prénom"],
            lastname: selectedUser["Nom"],
            email: selectedUser["Email"],
            phone: selectedUser["Téléphone"],
            city: selectedUser["Ville"],
            adress: selectedUser["Adresse"],
            cp: selectedUser["CP"],
            biel_id: selectedUser["ID_BIEL_ETU"],
            ssNumber: selectedUser["Numéro SS"],
            birthCountry: selectedUser["Lieu de naissance Pays"],
            birthCity: selectedUser["Lieu de naissance Ville"],
            birthCp: selectedUser["Lieu de naissance CP"],
            birthdate: selectedUser["Date de naissance"],
            sexe: selectedUser["Sexe"],
          };

      if (selectedUser && studentInfos.birthCp.length) {
        await fetch(
          `https://geo.api.gouv.fr/communes?codePostal=${studentInfos.birthCp}`
        )
          .then((res) => res.json())
          .then((res) => {
            studentInfos.birthState = res[0].codeDepartement;
          })
          .catch(() => {
            studentInfos.birthState = "099";
          });
      }

      let selectedCampusId;
      const selectedCampus = await db
        .collection("campus")
        .where("city", "==", city)
        .where("school_id", "==", school)
        .get()
        .then((snapshot) => {
          const campusList = [];
          snapshot.forEach((el) => {
            selectedCampusId = el.id;
            campusList.push(el.data());
          });
          return campusList[0];
        });

      let selectedApprentice = {
        ...apprentice,
        general: {
          ...apprentice.general,
          apprentice_last_name: {
            ...apprentice.general.apprentice_last_name,
            value: studentInfos.lastname,
            is_valid: true,
            is_locked: true,
          },
          apprentice_first_name: {
            ...apprentice.general.apprentice_first_name,
            value: studentInfos.firstname,
            is_valid: true,
            is_locked: true,
          },
          apprentice_nir_code: {
            ...apprentice.general.apprentice_nir_code,
            value: studentInfos.ssNumber || "",
            is_valid: studentInfos?.ssNumber?.length ? true : false,
          },
          apprentice_birth_city: {
            ...apprentice.general.apprentice_birth_city,
            value: studentInfos.birthCity || "",
            is_valid: studentInfos?.birthCity?.length ? true : false,
          },
          apprentice_birth_state: {
            ...apprentice.general.apprentice_birth_state,
            value: studentInfos.birthState || "",
            is_valid: studentInfos?.birthState?.length ? true : false,
          },
          apprentice_birth_date: {
            ...apprentice.general.apprentice_birth_date,
            value: studentInfos?.birthdate?.length
              ? new Date(studentInfos.birthdate).toLocaleDateString("fr")
              : "",
            is_valid: studentInfos?.birthdate?.length ? true : false,
          },
          apprentice_sexe: {
            ...apprentice.general.apprentice_sexe,
            value: studentInfos.sexe || "",
            is_valid: studentInfos?.sexe?.length ? true : false,
          },
        },
        apprentice_adress: {
          ...apprentice.apprentice_adress,
          apprentice_mail: {
            ...apprentice.apprentice_adress.apprentice_mail,
            value: studentInfos.email || "",
            is_valid: studentInfos?.email?.length ? true : false,
          },
          apprentice_phone: {
            ...apprentice.apprentice_adress.apprentice_phone,
            value: studentInfos.phone || "",
            is_valid: studentInfos?.phone?.length ? true : false,
          },
          apprentice_city: {
            ...apprentice.apprentice_adress.apprentice_city,
            value: studentInfos.city || "",
            is_valid: studentInfos?.city?.length ? true : false,
          },
          apprentice_number_street: {
            ...apprentice.apprentice_adress.apprentice_number_street,
            value: studentInfos.adress || "",
            is_valid: studentInfos?.adress?.length ? true : false,
          },
          apprentice_zip_code: {
            ...apprentice.apprentice_adress.apprentice_zip_code,
            value: studentInfos.cp || "",
            is_valid: studentInfos?.cp?.length ? true : false,
          },
        },
      };

      if (studentInfos.biel_id)
        selectedApprentice.biel_id = studentInfos.biel_id;

      const formationInfos = selectedFormation.find(
        (el) => el.formation_id === level
      );
      // const formationInfos = selectedFormation[0];
      const rncp_goal = await db
        .collection("rncp_goals")
        .doc(rncp.rncp_goal)
        .get()
        .then((goal) => goal.data());

      link.identity.apprentice_last_name.value = studentInfos.lastname;
      link.identity.apprentice_first_name.value = studentInfos.firstname;
      // link.identity.apprentice_last_name.value = selectedUser["Nom"];
      // link.identity.apprentice_first_name.value = selectedUser["Prénom"];
      link.infos = {
        city: allCities.find((el) => el.id === city).label,
        school: selectedSchool.school_short_name,
        grade: selectedGrade,
        level: validatedFormation.academic_short_title,
        status: 0,
        linked_formation: validatedFormation.formation_id,
        linked_campus: selectedCampusId,
        created_by: props.uid,
        id_company: randomIdCompany,
        id_apprentice: randomIdApprentice,
        form_edit: true,
        date: new Date().toLocaleString("fr"),
      };

      let formattedFormation = {
        ...formation,
        adress_cfa: {
          ...formation.adress_cfa,
          cfa_adress: {
            ...formation.adress_cfa.cfa_adress,
            value: cfa.cfa_adress,
          },
          cfa_city: {
            ...formation.adress_cfa.cfa_city,
            value: cfa.cfa_city,
          },
          cfa_director: {
            ...formation.adress_cfa.cfa_director,
            value: cfa.cfa_director_name,
          },
          cfa_director_function: {
            ...formation.adress_cfa.cfa_director_function,
            value: cfa.cfa_director_function,
          },
          cfa_state: {
            ...formation.adress_cfa.cfa_state,
            value: cfa.cfa_state,
          },
          cfa_zip_code: {
            ...formation.adress_cfa.cfa_zip_code,
            value: cfa.cfa_zip_code,
          },
          mail_cfa: {
            ...formation.adress_cfa.mail_cfa,
            value: cfa.cfa_mail,
          },
          tel_cfa: {
            ...formation.adress_cfa.tel_cfa,
            value: cfa.cfa_tel,
          },
        },
        cfa: {
          ...formation.cfa,
          cfa_name: {
            ...formation.cfa.cfa_name,
            value: cfa.cfa_name,
            cfa_id: cfa.id,
          },
          cfa_rcs_city: {
            ...formation.cfa.cfa_rcs_city,
            value: cfa.cfa_city,
          },
          cfa_siren: {
            ...formation.cfa.cfa_siren,
            value: cfa.cfa_siren,
          },
          cfa_siret: {
            ...formation.cfa.cfa_siret,
            value: cfa.cfa_siret,
          },
          cfa_type: {
            ...formation.cfa.cfa_type,
            value: cfa.cfa_type ? "Oui" : "Non",
          },
          nda_number: {
            ...formation.cfa.nda_number,
            value: cfa.cfa_nda_code,
          },
          uai_number: {
            ...formation.cfa.uai_number,
            value: cfa.cfa_uai_code,
          },
        },
        cost_info: {
          ...formation.cost_info,
          cost_year_1: {
            ...formation.cost_info.cost_year_1,
            value: formationInfos.cost_year_1,
          },
          cost_year_2: {
            ...formation.cost_info.cost_year_2,
            value: formationInfos.cost_year_2 || 0,
            hide:
              parseInt(allGrades.find((el) => el.id === grade)?.nbr_year) >= 2
                ? false
                : true,
          },
        },
        formation_info: {
          ...formation.formation_info,
          diploma_code: {
            ...formation.formation_info.diploma_code,
            value: rncp.rncp_diploma_code,
          },
          end_date: {
            ...formation.formation_info.end_date,
            value: new Date(
              formationInfos.academic_end_date?.toDate()
            ).toLocaleDateString("fr"),
          },
          start_date: {
            ...formation.formation_info.start_date,
            value: new Date(
              formationInfos.academic_start_date?.toDate()
            ).toLocaleDateString("fr"),
          },
          exact_title_level: {
            ...formation.formation_info.exact_title_level,
            value: formationInfos.academic_exact_title,
          },
          grade: {
            ...formation.formation_info.grade,
            value: allGrades.find((el) => el.id === grade).grade_name,
            grade_id: grade,
          },
          hours: {
            ...formation.formation_info.hours,
            value: formationInfos.academic_hours,
          },
          level: {
            ...formation.formation_info.level,
            value: formationInfos.academic_short_title,
          },
          rncp_exact_title: {
            ...formation.formation_info.rncp_exact_title,
            value: rncp.rncp_exact_title,
          },
          rncp_number: {
            ...formation.formation_info.rncp_number,
            value: rncp.rncp_number,
          },
          rythm: {
            ...formation.formation_info.rythm,
            value: formationInfos.academic_rythm,
          },
          title_goal: {
            ...formation.formation_info.title_goal,
            value: rncp_goal.rncp_goal,
          },
        },
        rgpd_info: {
          ...formation.rgpd_info,
          mail_rgpd: {
            ...formation.rgpd_info.mail_rgpd,
            value: cfa.cfa_dpo_mail,
          },
          tel_rgpd: {
            ...formation.rgpd_info.tel_rgpd,
            value: cfa.cfa_dpo_tel,
          },
          rgpd_manager: {
            ...formation.rgpd_info.rgpd_manager,
            value: cfa.cfa_dpo_name,
          },
        },
        school_info: {
          ...formation.school_info,
          city: {
            ...formation.school_info.city,
            value: allCities.find((el) => el.id === city).label,
          },
          director_function: {
            ...formation.school_info.director_function,
            value: selectedCampus.school_director_function,
          },
          school: {
            ...formation.school_info.school,
            value: selectedCampus.school_name,
          },
          school_adress: {
            ...formation.school_info.school_adress,
            value: selectedCampus.school_adress,
          },
          school_director: {
            ...formation.school_info.school_director,
            value: selectedCampus.school_director_name,
          },
          school_mail: {
            ...formation.school_info.school_mail,
            value: selectedCampus.school_mail,
          },
          school_tel: {
            ...formation.school_info.school_tel,
            value: selectedCampus.school_tel,
          },
        },
      };

      // for (const section in state.formation) {
      //   for (const [key, value] of Object.entries(state.cerfa[0])) {
      //     if (typeof state.formation[section][key] != "undefined") {
      //       let tempDatas = {
      //         ...state.formation,
      //         [section]: {
      //           ...state.formation[section],
      //           [key]: {
      //             ...state.formation[section][key],
      //             value: value,
      //           },
      //         },
      //       };
      //       state.formation = tempDatas;
      //     }
      //   }
      // }
      // return;
      saveToDb(randomIdCompany, link, selectedApprentice, formattedFormation);
      // props.getLinks();
    } else {
      createLink();
    }
  };

  const saveToDb = async (
    randomIdCompany,
    link,
    selectedApprentice,
    formattedFormation
  ) => {
    await firestoreFunctions.dbWrite(
      db,
      "apprentices",
      randomIdCompany,
      selectedApprentice
    );
    await firestoreFunctions.dbWrite(db, "companies", randomIdCompany, company);
    await firestoreFunctions.dbWrite(
      db,
      "contracts",
      randomIdCompany,
      contract
    );
    await firestoreFunctions.dbWrite(
      db,
      "remunerations",
      randomIdCompany,
      remuneration
    );
    await firestoreFunctions.dbWrite(db, "links", randomIdCompany, link);
    await firestoreFunctions.dbWrite(
      db,
      "formations",
      randomIdCompany,
      formattedFormation
    );
  };

  return (
    <div className="manage-link">
      {state.isFetching ? (
        <Loader />
      ) : (
        <div className="add-container">
          <Grid container spacing={3}>
            <Schools
              schools={schools}
              school={school}
              setSchool={setSchool}
            ></Schools>
            <Cities
              cities={cities}
              city={city}
              setCity={setCity}
              lock={school === null}
            ></Cities>
            <Grades
              grade={grade}
              setGrade={setGrade}
              grades={grades}
              lock={city === null}
            ></Grades>
            <Levels
              levels={selectedFormation}
              level={level}
              setLevel={setLevel}
              // getData={getCerfaData}
              lock={grade === null}
              // lock={grade === null || !usersList.length}
            ></Levels>
          </Grid>
          {!!selectedUsers.length && !!students.length && level !== null && (
            <>
              <AutoCompleteUsers
                selectedUsers={selectedUsers}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                students={students}
              />
              {!selectedUser && (
                <Typography
                  variant="h5"
                  align="center"
                  style={{ marginBottom: 20 }}
                >
                  OU
                </Typography>
              )}
            </>
          )}
          {apiWaiting && <LinearProgress style={{ margin: "20px 0" }} />}
          {!selectedUser && (
            <Student className="student" onUpdate={inputUpdate}></Student>
          )}
          <div className="ctn-btn">
            <Button
              className="send"
              size="large"
              disabled={disableForm}
              variant="contained"
              color="primary"
              onClick={() => {
                createLink();
                props.onClose();
              }}
            >
              Valider
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateLink;

import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";

// MENU ICON
import School from "@material-ui/icons/SchoolRounded";
import StarRate from "@material-ui/icons/StarRounded";
import HomeWork from "@material-ui/icons/HomeWorkRounded";
import Book from "@material-ui/icons/BookRounded";
import Location from "@material-ui/icons/LocationOnRounded";
import Build from "@material-ui/icons/BuildRounded";
import User from "@material-ui/icons/GroupRounded";
import Contracts from "@material-ui/icons/DescriptionRounded";
import MenuOpen from "@material-ui/icons/MenuOpenRounded";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import firebase from "../../../firebase";
import logo from "../../../Assets/AD_Logo_White.svg";
import AccountAdminDialog from "./AccountAdminDialog";
import SelectYear from "./SelectYear";

// let datas = {};

const AdminMenu = ({ user, perms }) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(true);
  const [openAccountAdmin, setOpenAccountAdmin] = useState(false);
  const pathName = location.pathname.split("/");
  const mainPerm = user.main_perm;

  const actionHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const actionHandleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnect = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        enqueueSnackbar("Déconnecté avec succès", {
          variant: "success",
        });
        history.push("/");
      })
      .catch((error) => {
        enqueueSnackbar(`Erreur lors de la déconnection : ${error}`, {
          variant: "error",
        });
      });

    actionHandleClose();
  };

  // Hide Admin menu outside of admin zone
  if (pathName.includes("form")) return <></>;

  // Active item menu with path parameters
  const checkActivePath = (activePath) => {
    let isActive = false;

    for (const path of activePath) {
      if (pathName.includes(path)) {
        if (pathName.includes("refDetails") && path === "schools") {
          isActive = false;
        } else {
          isActive = !isActive && true;
        }
      }
    }

    return isActive;
  };

  return (
    <div className={`admin-menu ${openMenu ? "open" : "close"}`}>
      <AccountAdminDialog
        openAccountAdmin={openAccountAdmin}
        setOpenAccountAdmin={setOpenAccountAdmin}
        enqueueSnackbar={enqueueSnackbar}
      />
      <div className="admin-top">
        <div className="menu-params">
          {openMenu && <SelectYear />}
          <MenuOpen onClick={() => setOpenMenu(!openMenu)} />
        </div>
        <div className="header">
          <div className="ctn-logo">
            <img className="logo" src={logo} alt="Utilisateur" />
          </div>
        </div>
        <div className="nav">
          {(mainPerm <= 1 || mainPerm === 2) && (
            <div className="ctn-nav sub-menu">
              <div className="cte-sub-menu">
                <div className="nav-head">
                  <div className="nav-head-title">
                    {openMenu ? "Relations Entreprises" : "RE"}
                  </div>
                </div>
              </div>
              <div className="cte-nav">
                <div className="links">
                  <NavLink
                    to="/contracts"
                    activeclasname="active"
                    className="nav-link"
                    title="Liste des contrats"
                  >
                    <Contracts className="nav-icon" />
                    {openMenu && (
                      <div className="nav-item">Liste des contrats</div>
                    )}
                  </NavLink>
                </div>
              </div>
            </div>
          )}
          {(mainPerm === 0 || mainPerm === 1 || mainPerm === 2) && (
            <div className="ctn-nav sub-menu">
              <div className="cte-sub-menu">
                <div className="nav-head">
                  <div className="nav-head-title">
                    {openMenu ? "Administration" : "Admin"}
                  </div>
                </div>
              </div>
              <div className="cte-nav">
                <div className="links">
                  {(mainPerm === 0 || mainPerm === 2) && (
                    <>
                      <NavLink
                        to="/rncp"
                        isActive={() => checkActivePath(["rncp"])}
                        activeclassname="active"
                        className="nav-link"
                        title="Titres RNCP"
                      >
                        <StarRate className="nav-icon" />
                        {openMenu && (
                          <div className="nav-item">Titres RNCP</div>
                        )}
                      </NavLink>
                      <NavLink
                        to="/cfa"
                        isActive={() => checkActivePath(["cfa"])}
                        activeclassname="active"
                        className="nav-link"
                        title="CFA"
                      >
                        <HomeWork className="nav-icon" />
                        {openMenu && <div className="nav-item">CFA</div>}
                      </NavLink>
                      <NavLink
                        to="/schools"
                        isActive={() =>
                          checkActivePath([
                            "schools",
                            "schoolDetails",
                            "formations_canvas",
                          ])
                        }
                        activeclassname="active"
                        className="nav-link"
                        title="Écoles"
                      >
                        <School className="nav-icon" />
                        {openMenu && <div className="nav-item">Écoles</div>}
                      </NavLink>
                      <NavLink
                        to="/campus"
                        isActive={() => checkActivePath(["campus"])}
                        activeclassname="active"
                        className="nav-link"
                        title="Campus"
                      >
                        <Location className="nav-icon" />
                        {openMenu && <div className="nav-item">Campus</div>}
                      </NavLink>
                    </>
                  )}

                  {/* TODO => TO REACTIVATE LATER */}
                  {/* {(mainPerm === 0 || mainPerm === 1 || mainPerm === 2) && ( */}
                  {(mainPerm === 0 || mainPerm === 2) && (
                    <NavLink
                      to="/formations"
                      isActive={() => checkActivePath(["formations"])}
                      activeclassname="active"
                      className="nav-link"
                      title="Formations"
                    >
                      <Book className="nav-icon" />
                      {openMenu && <div className="nav-item">Formations</div>}
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          )}
          {(mainPerm === 2 || mainPerm === 0) && (
            <div className="ctn-nav sub-menu">
              <div className="cte-sub-menu">
                <div className="nav-head">
                  <div className="nav-head-title">
                    {openMenu ? "Paramètres" : "Params"}
                  </div>
                </div>
              </div>
              <div className="cte-nav">
                <div className="links">
                  {mainPerm === 2 && (
                    <NavLink
                      to="/ref-tables"
                      isActive={() =>
                        checkActivePath(["ref-tables", "refDetails"])
                      }
                      activeclassname="active"
                      className="nav-link"
                      title="Tables de références"
                    >
                      <Build className="nav-icon" />
                      {openMenu && (
                        <div className="nav-item">Tables de références</div>
                      )}
                    </NavLink>
                  )}
                  <NavLink
                    to="/users"
                    isActive={() => checkActivePath(["users"])}
                    activeclassname="active"
                    className="nav-link"
                    title="Liste des utilisateurs"
                  >
                    <User className="nav-icon" />
                    {openMenu && (
                      <div className="nav-item">Liste des utilisateurs</div>
                    )}
                  </NavLink>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="ctn-profil">
        <div className="cte-img">
          <img className="picture" src={user.picture} alt="Utilisateur" />
        </div>
        {openMenu && (
          <div className="cte-id">
            <h2 className="name">
              {user.firstname} {user.lastname}
            </h2>
            <h2 className="main-perm">{perms.main_perm[user.main_perm]}</h2>
          </div>
        )}
        {openMenu && (
          <div className="cte-action">
            <IconButton
              className="btn-action"
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={actionHandleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="profil-actions"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={actionHandleClose}
            >
              <MenuItem onClick={() => setOpenAccountAdmin(true)}>
                <ListItemIcon>
                  <SettingsRoundedIcon fontSize="small" />
                </ListItemIcon>
                Gérer le compte
              </MenuItem>
              <MenuItem onClick={handleDisconnect}>
                <ListItemIcon>
                  <ExitToAppRoundedIcon fontSize="small" />
                </ListItemIcon>
                Se déconnecter
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

AdminMenu.propTypes = {
  user: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.number),
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    main_perm: PropTypes.number,
    picture: PropTypes.string,
    school_perms: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  perms: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.string),
    main_perm: PropTypes.arrayOf(PropTypes.string),
    school_perms: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default AdminMenu;

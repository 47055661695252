import { Paper } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const Tile = ({ classes, first, last, stats }) => {
  return (
    <Paper
      className={classes.paper}
      style={{
        marginLeft: first ? 30 : 0,
        marginRight: last ? 10 : 0,
        paddingLeft: 10,
      }}
    >
      <div style={{ margin: 20 }}>
        <div className={classes.title}>{stats?.title}</div>
        <div style={{ fontWeight: 900, fontSize: 28 }}>{stats?.statNumber}</div>
        {!!stats?.variation && (
          <>
            <div className={classes.variationContainer}>
              <ArrowUpwardIcon className={classes.arrow} /> {stats?.variation}%
            </div>
            <div className={classes.subtitle}>sur les 7 derniers jours</div>
          </>
        )}
      </div>
    </Paper>
  );
};

export default Tile;

import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { secondaryApp } from "../../../../../firebase";
import { useSnackbar } from "notistack";
import { DatabaseContext } from "../../../../../Context/Context";
import firebase from "../../../../../firebase";
import AddUser from "./AddUser";
import fsDatabase from "../../../../../Services/firestoreFunctions";
import ModifyUser from "./ModifyUser";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const ManageUsers = ({}) => {
  const globalDb = firebase.firestore();
  const [tabVal, setTabVal] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const { db } = useContext(DatabaseContext);

  const [perms, setPerms] = useState(null);

  useEffect(() => {
    const getPerms = async () => {
      const response = await fsDatabase.dbGet(globalDb, "perms", "perms");
      setPerms(response);
    };

    getPerms();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabVal(newValue);
  };

  return (
    <div className="manage-user">
      <h1>Gestion des utilisateurs</h1>
      <Tabs
        value={tabVal}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Créer un nouvel utilisateur" />
        <Tab label="Modifier un utilisateur" />
      </Tabs>
      <TabPanel value={tabVal} index={0} component="span">
        <AddUser
          secondaryApp={secondaryApp}
          db={db}
          fsDatabase={fsDatabase}
          enqueueSnackbar={enqueueSnackbar}
          perms={perms}
          globalDb={globalDb}
        />
      </TabPanel>
      <TabPanel value={tabVal} index={1}>
        <ModifyUser
          perms={perms}
          globalDb={globalDb}
          enqueueSnackbar={enqueueSnackbar}
        />
      </TabPanel>
    </div>
  );
};

export default ManageUsers;

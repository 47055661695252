import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CustomTable from "../../Components/CustomTable";
import Edit from "@material-ui/icons/EditRounded";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { Chip } from "@material-ui/core";
import { DatabaseContext } from "../../Context/Context";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";

const columns = [
  {
    title: "Logo",
    render: (rowData) => (
      <Link to={`/admindetails/campus/${rowData.id}`} target="_blank">
        <img
          alt={`Logo ${rowData.school_short_name}`}
          className="logo-img"
          src={`${rowData.school_logo}`}
        />
      </Link>
    ),
    align: "center",
    cellStyle: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  {
    title: "Campus",
    field: "campus_name",
    filtering: false,
    cellStyle: {
      width: "100%",
      minWidth: "200px",
    },
  },
  {
    title: "Statut",
    field: "completed",
    filtering: false,
    align: "center",
  },
];

const breadcrumbPath = {
  path: [{ name: "Administration" }],
};

const Campus = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");

  const { db } = useContext(DatabaseContext);
  const campusRef = db.collection("campus");
  const actions = [
    {
      icon: Edit,
      tooltip: "Voir les détails du campus",
      onClick: (_event, rowData) =>
        history.push(`/admindetails/campus/${rowData.id}`),
    },
    // {
    //   icon: DeleteOutline,
    //   tooltip: "Supprimer le campus",
    //   onClick: (_event, rowData) =>
    //     history.push(`/admindetails/campus/${rowData.id}`),
    // },
  ];

  // const getAllActiveFormations = async (result) => {
  //   let activeFormations = [];

  //   await result.map((campus) => {
  //     db;
  //     campusRef
  //       .doc(campus.id)
  //       .collection("formations")
  //       .where("is_active", "==", true)
  //       .get()
  //       .then((querySnapshot) => {
  //         console.log({ id: campus.id, value: querySnapshot.size });
  //         activeFormations.push({ id: campus.id, value: querySnapshot.size });
  //       });
  //   });
  //   console.log(activeFormations);
  //   return activeFormations;
  // };

  const formatData = async (result) => {
    if (!result) return [];

    const allCities = await db
      .collection("cities")
      .get()
      .then((snapshot) => {
        const citiesData = [];
        snapshot.forEach((el) => citiesData.push({ ...el.data(), id: el.id }));
        return citiesData;
      });

    // const allActiveFormations = await getAllActiveFormations(result);

    // const test = await test(result, allActiveFormations)

    return await result.map((campus) => {
      let output = { ...campus, campus_name: "" };
      output.city = allCities.find((el) => el.id === campus.city)?.label;
      output.campus_name = output.school_abbreviation + " " + output.city;

      // console.log(allActiveFormations);
      // console.log(campus.id);
      // console.log(allActiveFormations[0]);
      // output.active_formation = allActiveFormations[campus.id];

      output.completed = campus.completed ? (
        <Chip
          label="Complété"
          variant="outlined"
          className="chip complete md"
        />
      ) : (
        <Chip
          label="À compléter"
          variant="outlined"
          className="chip to-complete md"
        />
      );
      return output;
    });
  };

  useEffect(() => {
    let campusData = [];

    campusRef
      .where("is_active", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          campusData.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      })
      .then(async () => {
        const formattedData = await formatData(campusData);
        setData(formattedData);
      });
  }, []);

  return (
    <Grid container className="admin-view">
      <Grid item xs={12} className="admin-view-header">
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <Typography variant="h4" className="header-title">
            Campus
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className="admin-view-table">
        <section className="admin-view-section">
          <h3 className="section-title">Liste des campus</h3>
          <CustomTable
            title="Liste des campus"
            columns={columns}
            data={data}
            actions={actions}
            size={size}
            setSize={setSize}
            page={page}
            setPage={setPage}
            totalElements={totalElements}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filter={false}
            // noSearch={false}
            autoFocus={true}
          />
        </section>
      </Grid>
    </Grid>
  );
};

export default Campus;

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const CityManager = ({ content, cities, handleAddCity }) => {
  // const { enqueueSnackbar } = useSnackbar();
  // const {
  //   control,
  //   handleSubmit,
  //   setValue,
  //   formState: { errors },
  // } = useForm();

  const [value, setValue] = useState("");

  return (
    <Grid container className="city-manager">
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="brand-cities-label">Ajouter un Campus</InputLabel>
          <Select
            labelId="brand-cities-label"
            id="city-select"
            value={value}
            label="Villes"
            onChange={(e) => setValue(e.target.value)}
          >
            {cities.map((city) => {
              return (
                <MenuItem value={city.id} key={city.id}>
                  {city.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          type="button"
          variant="outlined"
          className="ad-main-button main-color lg"
          onClick={() => {
            handleAddCity(value);
            setValue("");
          }}
        >
          Ajouter
        </Button>
      </Grid>
    </Grid>
  );
};

export default CityManager;

const adminDetailsTitle = {
  rncp: {
    title: "rncp_title",
  },
  cfa: {
    title: "cfa_name",
  },
  campus: {
    title: {
      school: "school_abbreviation",
      city: "city",
    },
  },
  formations: {
    title: "academic_exact_title",
  },
  formations_canvas: {
    title: "academic_exact_title",
  },
};

export { adminDetailsTitle };

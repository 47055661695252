import React, { useContext, useState, useEffect } from "react";
import logo from "../../../Assets/AD_Logo_White.svg";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import PermPhoneMsgRoundedIcon from "@material-ui/icons/PermPhoneMsgRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import "./menu.scss";
import { Link } from "react-router-dom";
import bddFormation from "../../../Templates/bddFormation.json";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import Progress from "../../Form/Progress/ProgressComponents/Progress";
import Button from "@material-ui/core/Button";
import fsDatabase from "../../../Services/firestoreFunctions";
import { AuthContext } from "../../../Context/Context";
import { Tooltip } from "@material-ui/core";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import EditIdentity from "./EditIdentity";
import { DatabaseContext } from "../../../Context/Context";
import firebase from "../../../firebase";

const FormMenu = ({
  identity,
  infos,
  progressDatas,
  progressFinished,
  formEdit,
  slug,
  isLogin,
  height,
}) => {
  const { db } = useContext(DatabaseContext);
  // let dataInfos = {};
  const [dataInfos, setDataInfos] = useState({});
  const [users, setUsers] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [responsive, setResponsive] = useState({
    help: false,
    copy: false,
    download: false,
  });
  const { setAuth } = useContext(AuthContext);
  const isProd = process.env.REACT_APP_ENV === "production" ? true : false;
  const [changeIdentity, setChangeIdentity] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((snapshot) => {
        const usersArr = [];
        snapshot.forEach((el) => {
          usersArr.push({
            ...el.data(),
            id: el.id,
          });
        });
        setUsers(usersArr);
      });
  }, []);

  useEffect(() => {
    if (!users) return;

    const getUrl = async (el) => {
      const storage = await firebase
        .storage()
        .ref(el)
        .getDownloadURL()
        .then((url) => url);
      return storage;
    };

    db.collection("campus")
      .doc(infos.linked_campus)
      .collection("formations")
      .doc(infos.linked_formation)
      .get()
      .then(async (doc) => {
        const data = doc.data();
        const calendarStorage = await getUrl(data.academic_calendar);
        const programStorage = await getUrl(data.academic_programm);
        const info = {
          name: users.find((user) => user.id === data.academic_campus_contact)
            .fullname,
          role: data.academic_campus_contact_function,
          phone: data.academic_campus_contact_tel,
          mail: data.academic_campus_contact_mail,
          dnwl_calendar: calendarStorage,
          dnwl_program: programStorage,
        };
        setDataInfos(info);
      });
  }, [infos, users]);

  useEffect(() => {
    height < 900
      ? setResponsive({
          help: true,
          copy: true,
          download: true,
        })
      : setResponsive({
          help: false,
          copy: false,
          download: false,
        });
  }, [height]);

  // bddFormation.forEach((e) => {
  //   if (
  //     e.school == infos.school &&
  //     e.city == infos.city &&
  //     e.grade == infos.grade &&
  //     e.level == infos.level
  //   ) {
  //     dataInfos = {
  //       name: e.campus_contact,
  //       role: e.function_campus_contact,
  //       phone: e.phone_campus_contact,
  //       mail: e.mail_campus_contact,
  //       dnwl_calendar: e.url_calendar,
  //       dnwl_program: e.url_programm,
  //     };
  //   }
  // });

  const copyClick = (event) => {
    enqueueSnackbar(
      `L'url du formulaire à bien été copié : ${event.currentTarget.id}`,
      {
        variant: "success",
      }
    );
  };

  const closeEdit = () => {
    let fsDatas = {
      infos: {
        form_edit: !formEdit,
      },
    };
    fsDatabase.dbWrite(db, "links", slug, fsDatas);
  };

  const handleResponsive = (section) => {
    let newResponsive = {
      ...responsive,
      [section]: !responsive[section],
    };
    setResponsive(newResponsive);
  };

  const academic_year = sessionStorage.getItem("academic_year");

  return (
    <div className="form-menu">
      <div className="top">
        <div className="header">
          <div className="ctn-logo">
            <Link to="/contracts">
              <img className="logo" src={logo} />
            </Link>
          </div>
        </div>
      </div>
      <div className="body">
        <div className="ctn-title"></div>
        <div className="ctn-name">
          <div className="name-text">
            <h2 className="name">
              <span className="lastname">
                {identity.apprentice_last_name.value}
              </span>{" "}
              {identity.apprentice_first_name.value}
            </h2>
          </div>
          {isLogin && (
            <div
              className="change-link-datas"
              onClick={() => setChangeIdentity(true)}
            >
              <EditRoundedIcon className="icon" />
            </div>
          )}
        </div>
        <div className="ctn-info">
          <div className="cte-info">
            <div className="icon">
              <LocationCityRoundedIcon className="mat-icon" />
            </div>
            <div className="info">{infos.school}</div>
          </div>
          <div className="cte-info">
            <div className="icon">
              <RoomRoundedIcon className="mat-icon" />
            </div>
            <div className="info">{infos.city}</div>
          </div>
          <div className="cte-info">
            <div className="icon">
              <SchoolRoundedIcon className="mat-icon" />
            </div>
            <div className="info">
              {infos.grade} {infos.level}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          (slug == infos.id_apprentice && !isLogin
            ? "ctn-menu-component end"
            : "ctn-menu-component") +
          " responsive-" +
          responsive.download
        }
      >
        <div
          className="sub-header"
          onClick={() => handleResponsive("download")}
        >
          <h3 className="sct-title">Documents à télécharger</h3>{" "}
          {responsive.download ? (
            <KeyboardArrowUpRoundedIcon className="icon" />
          ) : (
            <KeyboardArrowDownRoundedIcon className="icon" />
          )}
        </div>
        <a className="link" target="_blank" href={dataInfos.dnwl_calendar} rel="noreferrer">
          <CloudDownloadRoundedIcon className="link-icon" />
          <p className="link-text">Calendrier de formation</p>
        </a>
        <a className="link" target="_blank" href={dataInfos.dnwl_program} rel="noreferrer">
          <CloudDownloadRoundedIcon className="link-icon" />
          <p className="link-text">Programme de formation</p>
        </a>
      </div>
      {(slug != infos.id_apprentice || isLogin) && (
        <div
          className={
            slug == infos.id_apprentice && !isLogin
              ? "ctn-menu-component end"
              : "ctn-menu-component" + " responsive-" + responsive.copy
          }
        >
          <div className="sub-header" onClick={() => handleResponsive("copy")}>
            <h3 className="sct-title">Liens à partager</h3>{" "}
            {responsive.copy ? (
              <KeyboardArrowUpRoundedIcon className="icon" />
            ) : (
              <KeyboardArrowDownRoundedIcon className="icon" />
            )}
          </div>
          {(slug != infos.id_company || isLogin) && (
            <CopyToClipboard
              key="copy_company"
              text={
                isProd
                  ? `https://alternance.ad-education.com/form/${infos.id_company}?year=${academic_year}`
                  : `https://re-webapp-v2-dev.web.app/form/${infos.id_company}?year=${academic_year}`
              }
            >
              <div
                className="link"
                id={infos.id_company}
                name="entreprise"
                onClick={copyClick}
              >
                <FileCopyRoundedIcon className="link-icon" />
                <p className="link-text">Formulaire entreprise</p>
              </div>
            </CopyToClipboard>
          )}
          <CopyToClipboard
            key="copy_apprentice"
            text={
              isProd
                ? `https://alternance.ad-education.com/form/${infos.id_apprentice}?year=${academic_year}`
                : `https://re-webapp-v2-dev.web.app/form/${infos.id_apprentice}?year=${academic_year}`
            }
          >
            <div
              className="link"
              id={infos.id_apprentice}
              name="apprenti"
              onClick={copyClick}
            >
              <FileCopyRoundedIcon className="link-icon" />
              <p className="link-text">Formulaire étudiant</p>
            </div>
          </CopyToClipboard>
        </div>
      )}
      {(slug == infos.id_company || isLogin) && (
        <div className="ctn-menu-component end">
          <div className="sub-header">
            <h3 className="sct-title">Avancement du formulaire</h3>
          </div>
          {progressDatas && <Progress progress={progressDatas} />}
          <Tooltip
            title={
              progressFinished
                ? "Revenir en arrière pour modifier les champs"
                : "Compléter au moins 90% des informations nécessaire pour le Cerfa et 100% des informations pour la convention pour valider"
            }
            arrow
          >
            <span className="end-btn-ctn">
              {slug === infos.id_company && (
                <Button
                  disabled={!progressFinished}
                  className={
                    "end-btn active-" +
                    progressFinished +
                    " form-edit-" +
                    formEdit
                  }
                  variant="contained"
                  color="primary"
                  onClick={closeEdit}
                >
                  {formEdit ? (
                    <CheckCircleRoundedIcon />
                  ) : (
                    <CreateRoundedIcon />
                  )}
                  {formEdit
                    ? "Valider les informations"
                    : "Modifier les informations"}
                </Button>
              )}
            </span>
          </Tooltip>
        </div>
      )}
      <div className="bottom">
        <div
          className={`ctn-help responsive-${responsive.help}`}
          onClick={() => handleResponsive("help")}
        >
          <div className="cte-title">
            <div className="icon">
              <LiveHelpRoundedIcon className="mat-icon" />
            </div>
            <div className="head">
              <h2 className="title">Vous avez besoin d'aide ?</h2>
            </div>
          </div>
          <div className="cte-contact">
            <h2 className="name">{dataInfos.name}</h2>
            <h3 className="role">{dataInfos.role}</h3>
            <div className="ctn-info">
              <EmailRoundedIcon className="mat-icon" />
              <a className="email" href={"mailto:" + dataInfos.mail}>
                <h3 className="info">{dataInfos.mail}</h3>
              </a>
            </div>
            <div className="ctn-info">
              <PermPhoneMsgRoundedIcon className="mat-icon" />
              <h3 className="phone info">{dataInfos.phone}</h3>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="cpn-modal-change-identity"
        aria-describedby="transition-modal-description"
        open={changeIdentity}
        onClose={() => setChangeIdentity(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={changeIdentity}>
          <EditIdentity
            identity={identity}
            closeModal={setChangeIdentity}
            slug={slug}
          />
        </Fade>
      </Modal>
    </div>
  );
};
export default FormMenu;

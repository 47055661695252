import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/Inbox";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import { Button } from "@material-ui/core";

function ListItemLink(props) {
  return <ListItem button {...props} />;
}

const tables = [
  { label: "Niveaux", to: "grades", icon: <InboxIcon /> },
  { label: "Villes", to: "cities", icon: <InboxIcon /> },
  { label: "Titres RNCP", to: "rncp_titles", icon: <InboxIcon /> },
  { label: "Objectifs RNCP", to: "rncp_goals", icon: <InboxIcon /> },
  { label: "Écoles", to: "schools", icon: <InboxIcon /> },
];

const breadcrumbPath = {
  path: [{ name: "Paramètres" }],
};

// const handleMajCompanies = () => {
//   fetch(
//     `https://europe-west1-re-webapp-v2-dev.cloudfunctions.net/exportToBigQuery`,
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       method: "POST",
//     }
//   )
//     .then((res) => res.json())
//     .then((res) => {
//       console.log("RES", res);
//     });
// };

const RefTables = () => {
  return (
    <Grid container className="admin-view">
      <Grid item xs={12} className="admin-view-header">
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <Typography variant="h4" className="header-title">
            Tables de références
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className="admin-view-table admin-view-ref">
        <List component="nav" className="ref-nav">
          {tables.map((table) => (
            <Link
              to={`refDetails/${table.to}`}
              className="ref-link"
              key={table.label}
            >
              <ListItemLink>
                <ListItemIcon className="ref-link-icon">
                  {table.icon}
                </ListItemIcon>
                <ListItemText
                  className="ref-link-label"
                  primary={table.label}
                />
              </ListItemLink>
            </Link>
          ))}
        </List>
      </Grid>
      {/* Used to prepare data to bigQuery usage */}
      {/* <Grid item xs={3}>
        <Button onClick={handleMajCompanies}>Maj companies</Button>
      </Grid> */}
    </Grid>
  );
};

export default RefTables;

import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { Button, Menu } from "@material-ui/core";
import locale from "date-fns/locale/fr";

defaultStaticRanges[0].label = "Aujourd'hui";
defaultStaticRanges[1].label = "Hier";
defaultStaticRanges[2].label = "Cette semaine";
defaultStaticRanges[3].label = "La semaine dernière";
defaultStaticRanges[4].label = "Ce mois-ci";
defaultStaticRanges[5].label = "Le mois dernier";

defaultInputRanges[0].label = "jours jusqu'à aujourd'hui";
defaultInputRanges[1].label = "jours à partir d'aujourd'hui";

const DateMenu = ({
  anchorEl,
  setAnchorEl,
  dateRange,
  setDateRange,
  initDateRange,
}) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      <DateRangePicker
        editableDateInputs={true}
        onChange={(item) => setDateRange([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        weekStartsOn={1}
        scroll={{ enabled: true }}
        startDatePlaceholder="Début"
        endDatePlaceholder="Fin"
        locale={locale}
        months={2}
        direction="horizontal"
        dateDisplayFormat="dd/MM/yyyy"
        staticRanges={defaultStaticRanges}
        inputRanges={defaultInputRanges}
      />
      <li>
        <Button
          variant="outlined"
          color="secondary"
          style={{
            width: "fit-content",
            marginLeft: "2%",
            marginBottom: 20,
          }}
          onClick={() => {
            setDateRange(initDateRange);
            setAnchorEl(null);
          }}
        >
          Supprimer
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{
            width: "fit-content",
            marginLeft: "2%",
            marginBottom: 20,
          }}
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          Valider
        </Button>
      </li>
    </Menu>
  );
};

export default DateMenu;

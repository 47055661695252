import localforage from "localforage";

const fetchIntranetData = async (
  setIntraLoading,
  setIntra,
  schools,
  forceUpdate
) => {
  setIntraLoading(true);

  const intra = await localforage.getItem("intra");

  if (intra && !forceUpdate) {
    setIntraLoading(false);
    setIntra(intra);
    return;
  }

  if (!schools.length) {
    setIntraLoading(false);
    return;
  }

  const academicYear = sessionStorage.getItem("academic_year");
  const baseUrl = `https://intranet.ad-education.com/externalData.php?uauth=BSdMBKkquDKZmLsyeqRRjpjXxkmaJVrHZuqpYAuAkwaEkFwV&f=csv_exportinscriptions&filtres[statuts][]=INSCRIT&aa=${academicYear}${schools
    .map((school) => `&filtres[marque][]=${school.school_short_name}`)
    .join("")}
  `;

  fetch(`${baseUrl}&json`)
    .then((res) => res.json())
    .then((res) => {
      localforage.setItem(
        "intra",
        Object.values(res).filter((el) => el["Alternance ?"] === "oui")
      );
    })
    .finally(() => {
      setIntraLoading(false);
    });
};

const fetchCollection = (collection, setter, setLoading, db) => {
  setLoading(true);
  const temp = [];
  db.collection(collection)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id });
      });
    })
    .then(() => {
      localStorage.setItem(collection, JSON.stringify(temp));
      setter(temp);
    })
    .finally(() => {
      setLoading(false);
    });
};

const statusList = [
  "Incomplet",
  "À envoyer",
  "Envoyé",
  "Signé",
  "PEC reçue",
  "PEC en attente",
  "Rupture",
];

const initDateRange = [
  {
    startDate: null,
    endDate: new Date(),
    key: "selection",
  },
];

export { fetchIntranetData, statusList, initDateRange, fetchCollection };

import React from 'react';
import PropTypes from 'prop-types';

const Progress = ({ progress }) => {
  // <Form key={item} newDatas={datas[item]} path={path} objectName={item}
  // slug={slug} filter={filter}></Form>;

  // let percentFilled = Math.round((progress.cerfaTotal / progress.cerfaFilled) * 100);
  const filters = [];
  // console.log(progress)

  for (const [key, value] of Object.entries(progress)) {
    filters.push({
      name: key,
      percent: value.percent,
    });
  }

  return (
    <div className="progress">
      {filters.map((item) => (
        <div className="ctn-progress" key={item.name}>
          <div className="progress-title">
            <div className={`pin ${item.name}`} />
            <p className={`title ${item.name}`}>{item.name}</p>
          </div>
          <div className="progress-achievement">
            <div className="progress-visual">
              <div className="progress-bar">
                <div
                  className={`bar ${item.name}`}
                  style={{ width: `${item.percent}%` }}
                />
              </div>
              <div className="progress-percent">
                {item.percent} <span className="percent-symbole">%</span>
              </div>
            </div>
            <div className="progress-number">
              {/* <p className='number'>
                {item.filled} / {item.total}
              </p> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Progress.propTypes = {
  progress: PropTypes.shape({
    cerfa: PropTypes.shape({
      is_valid: PropTypes.number,
      total: PropTypes.number,
      percent: PropTypes.number,
    }),
    convention: PropTypes.shape({
      is_valid: PropTypes.number,
      total: PropTypes.number,
      percent: PropTypes.number,
    }),
  }).isRequired,
};

export default Progress;

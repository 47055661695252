import React, { useState, useEffect, useContext } from "react";
import Form from "../FormStructure";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import mfs from "../../../Services/manageFormSection";
import sectionClear from "../../../Templates/apprentice.json";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { DatabaseContext } from "../../../Context/Context";

const Apprentice = ({ slug, datas, path, filter }) => {
  const { db } = useContext(DatabaseContext);
  const [legalRepresentantState, setLegalRepresentant] = useState(0);
  const collection = ["general", "apprentice_adress"];
  const collectionLegalRepresentant = ["apprentice_tutor"];
  const collectionSalary = ["situation"];

  useEffect(() => {
    getActiveLegalRepresentant();
  }, [datas]);

  const getActiveLegalRepresentant = () => {
    let nbSectionActive = 0;
    for (var key in datas) {
      if (collectionLegalRepresentant.includes(key)) {
        datas[key].section_settings.is_active && nbSectionActive++;
      }
    }
    setLegalRepresentant(nbSectionActive);
  };

  const changeLegalRepresentant = () => {
    if (legalRepresentantState == 0) {
      mfs.sectionDisplayOne(
        db,
        datas,
        collectionLegalRepresentant,
        legalRepresentantState,
        slug,
        path,
        true
      );
    } else {
      mfs.sectionClearOne(
        db,
        datas,
        collectionLegalRepresentant,
        slug,
        path,
        sectionClear
      );
    }
  };

  return (
    <div>
      {collection.map((item) => (
        <Form
          key={item}
          newDatas={datas[item]}
          path={path}
          objectName={item}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      ))}
      <FormControl
        className={
          legalRepresentantState == 0
            ? "display-section close"
            : "display-section open"
        }
      >
        <FormLabel className="display-section-title">
          L'apprenti est mineur non émancipé ?
        </FormLabel>
        <RadioGroup
          className="display-section-radio"
          row
          value={legalRepresentantState}
          onChange={changeLegalRepresentant}
        >
          <FormControlLabel
            className="display-section-radio-label"
            key="yes"
            value={1}
            control={<Radio />}
            label="Oui"
          />
          <FormControlLabel
            className="display-section-radio-label"
            key="no"
            value={0}
            control={<Radio />}
            label="Non"
          />
        </RadioGroup>
      </FormControl>
      {legalRepresentantState >= 1 && (
        <Form
          key={collectionLegalRepresentant[0]}
          newDatas={datas[collectionLegalRepresentant[0]]}
          path={path}
          objectName={collectionLegalRepresentant[0]}
          slug={slug}
          filter={filter}
          formEdit={true}
        ></Form>
      )}
      <Form
        key={collectionSalary[0]}
        newDatas={datas[collectionSalary[0]]}
        path={path}
        objectName={collectionSalary[0]}
        slug={slug}
        filter={filter}
        formEdit={true}
      ></Form>
    </div>
  );
};

export default Apprentice;

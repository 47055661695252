import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { detailsStructure } from "./utils";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import CustomField from "./CustomField";
import FormationsCheckboxes from "./FormationsCheckboxes";
import { useContext, useEffect, useState } from "react";
import { GRADES } from "../utils";
import { DatabaseContext } from "../../../Context/Context";
import firebase from "../../../firebase";
import { useHistory } from "react-router-dom";

const DetailsContent = ({
  category,
  content,
  loading,
  formationId,
  isFormation,
  setForceReload,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const structure = detailsStructure[category];
  const [selectedFormations, setSelectedFormations] = useState(null);
  const [formations, setFormations] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { db } = useContext(DatabaseContext);

  useEffect(() => {
    reset(content);
  }, [content]);

  const onSubmit = async (data) => {
    for (const prop in data) {
      // To avoid undefined value
      if (!data[prop]) data[prop] = "";
    }

    if (data.cfa_siret) {
      data = {
        ...data,
        cfa_siren: data.cfa_siret.slice(0, 9),
      };
    }

    const docRef = isFormation
      ? isFormation
      : db.collection(category).doc(content.id);

    if (category === "rncp" && data.rcnp_level) {
      await db
        .collection("rncp_titles")
        .doc(data.rcnp_level)
        .get()
        .then((doc) => {
          data = {
            ...data,
            rncp_exact_title: `${doc.data().rncp_level_label} - ${
              data.rncp_title
            }`,
          };
        });
    }

    if (["cfa", "campus", "formations", "formations"].includes(category)) {
      let isComplete = true;
      structure.content.forEach((part) => {
        part.fields?.forEach((field) => {
          if (
            typeof data[field.id] !== "object" &&
            !field.unrequired &&
            !field.condition
          ) {
            if (!data[field.id]?.length) {
              isComplete = false;
            }
          }
        });
      });

      data = {
        ...data,
        completed: isComplete,
      };
    }

    if (category === "cfa") {
      data.cfa_type = !!data.cfa_type;
      const updateCfaInSchools = async (
        schoolsToAdd,
        schoolsToRemove,
        cfaId
      ) => {
        try {
          let batch = firebase.firestore().batch();

          for (const schoolId of schoolsToAdd) {
            let schoolRef = db.collection("schools").doc(schoolId);
            await batch.update(schoolRef, {
              cfa_associated: firebase.firestore.FieldValue.arrayUnion(cfaId),
            });
          }
          for (const schoolId of schoolsToRemove) {
            let schoolRef = db.collection("schools").doc(schoolId);
            await batch.update(schoolRef, {
              cfa_associated: firebase.firestore.FieldValue.arrayRemove(cfaId),
            });
          }

          batch.commit().then(() => {
            console.info("CFA mis à jour dans les écoles associées");
          });
        } catch (err) {
          console.log("Error while updating CFA in schools", err);
        }
      };
      const equalsIgnoreOrder = (a, b) => {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
          const aCount = a.filter((e) => e === v).length;
          const bCount = b.filter((e) => e === v).length;
          if (aCount !== bCount) return false;
        }
        return true;
      };
      const getDiff = (a, b) => {
        return b.filter((el) => !a.includes(el));
      };

      const previousSchools = content.schools_short_name;
      const nextSchools = data.schools_short_name;
      if (!equalsIgnoreOrder(previousSchools, nextSchools)) {
        const addedSchools = getDiff(previousSchools, nextSchools);
        const removedSchools = getDiff(nextSchools, previousSchools);
        updateCfaInSchools(addedSchools, removedSchools, content.id);
      }
    }

    if (category === "campus") {
      try {
        let formationsRef = db
          .collection("campus")
          .doc(content.id)
          .collection("formations");
        let batch = firebase.firestore().batch();
        let formationRef;
        // Update is_active for each formation in the actual campus
        formationsRef
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              formationRef = formationsRef.doc(doc.id);

              batch.update(formationRef, {
                is_active: selectedFormations.includes(doc.id),
              });
            });
          })
          .then(() => {
            batch.commit().then(() => {
              console.info("toutes les formations ont été MAJ dans ce campus");
            });
          });
      } catch (err) {
        console.log("error updating formations in campus", err);
      }
    }

    if (category === "formations") {
      // Handle selected user info completion
      const user = await firebase
        .firestore()
        .collection("users")
        .doc(data.academic_campus_contact)
        .get()
        .then((doc) => doc.data());
      data = {
        ...data,
        academic_campus_contact_function: user.function || "",
        academic_campus_contact_tel: user.phone || "",
        academic_campus_contact_mail: user.email || "",
      };

      // Handle file storage
      const storageRef = firebase.storage().ref();
      const uploadsList = [
        "academic_calendar",
        "academic_calendar_year_2",
        "academic_programm",
        "academic_programm_year_2",
      ];
      for (const [key, value] of Object.entries(data)) {
        if (uploadsList.includes(key) && typeof value === "object") {
          const prefix = key.includes("calendar") ? "Calendrier" : "Programme";
          const campusName = await db
            .collection("cities")
            .doc(data.campus_city)
            .get()
            .then((el) => el.data().city_code);
          const gradeCode = await db
            .collection("grades")
            .doc(data.academic_grade)
            .get()
            .then((grade) => grade.data().grade_code);
          const filename = `${prefix}_${data.school_abbreviation}_${campusName}_${gradeCode}_${data.academic_title}`;
          console.log("filename", filename);
          const stringRef = await `academic_docs/${prefix}/${
            data.school_abbreviation
          }/${campusName}/${gradeCode}/${
            data.academic_title
          }/${filename}.${data[key].type.split("/").pop()}`;
          // const stringRef = await `academic_docs/${data.school_id}/${
          //   data.campus_city.id || data.campus_city
          // }/${data.id}/${key.replace("academic_", "")}.${data[key].type
          //   .split("/")
          //   .pop()}`;
          const uploadRef = storageRef.child(stringRef);

          var metadata = {
            contentType: "application/pdf",
          };

          await uploadRef
            .put(data[key], metadata)
            .then(() => {
              console.info("File stored here:", uploadRef.fullPath);
            })
            .catch((err) => {
              console.error("error uploading", err);
            });
          data = {
            ...data,
            [key]: uploadRef.fullPath,
          };
        }
      }

      // Update every contract linked to this formations
      try {
        let batch = firebase.firestore().batch();

        // Group search to retrieve the contract IDs
        const activeContracts = firebase
          .firestore()
          .collectionGroup("links")
          .where("infos.linked_formation", "==", formationId)
          .orderBy(firebase.firestore.FieldPath.documentId())
          .startAt(db.path)
          .endAt(db.path + "\uf8ff");

        const contractList = [];

        await activeContracts.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            contractList.push(doc.data()?.infos?.id_company);
          });
        });

        // Update contracts formation & cost section using this formation
        // Recalculate final price based on opco
        if (contractList.length) {
          const nbrYear = await db
            .collection("grades")
            .doc(data.academic_grade)
            .get()
            .then((doc) => doc.data().nbr_year);

          for (const contractId of contractList) {
            let formationRef = db.collection("formations").doc(contractId);
            // let {
            //   cost_info: { opco_charge_1, opco_charge_2 },
            // } = await formationRef.get().then((doc) => doc.data());
            let exists = true;
            let formationData = await formationRef.get().then((doc) => {
              if (!doc.exists) exists = false;
              doc.data();
            });
            if (!exists) continue;

            let opco_charge_1 = formationData?.cost_info?.opco_charge_1 || 0;
            let opco_charge_2 = formationData?.cost_info?.opco_charge_2 || 0;
            await batch.update(formationRef, {
              "cost_info.cost_year_1.value": data.cost_year_1 || "0",
              "cost_info.price_difference_1.value": Math.max(
                0,
                parseInt(data.cost_year_1) - parseInt(opco_charge_1.value || 0)
              ),
              "cost_info.cost_year_2.value": data.cost_year_2 || "0",
              "cost_info.opco_charge_2.hide":
                parseInt(nbrYear) >= 2 ? false : true,
              "cost_info.price_difference_2.value": Math.max(
                0,
                parseInt(data.cost_year_2) - parseInt(opco_charge_2.value || 0)
              ),
              "cost_info.price_difference_2.hide":
                parseInt(nbrYear) >= 2 ? false : true,
              "formation_info.hours.value": data.academic_hours || "0",
              "formation_info.rythm.value": data.academic_rythm || "",
              "formation_info.start_date.value": data.academic_start_date
                ?.toDate
                ? new Date(
                    data.academic_start_date?.toDate()
                  )?.toLocaleDateString("fr")
                : new Date(data.academic_start_date).toLocaleDateString("fr") ||
                  "",
              "formation_info.end_date.value": data.academic_end_date?.toDate
                ? new Date(
                    data.academic_end_date?.toDate()
                  )?.toLocaleDateString("fr")
                : new Date(data.academic_end_date).toLocaleDateString("fr") ||
                  "",
            });
          }
          batch.commit().then(() => {
            enqueueSnackbar(
              "Données mise à jour dans les contrats utilisant cette formation",
              {
                variant: "success",
              }
            );
            setForceReload(true);
          });
        }
      } catch (err) {
        console.log("error updating formations in campus", err);
      }
    }

    if (category === "formations_canvas") {
      await db
        .collection("grades")
        .doc(data.academic_grade)
        .get()
        .then((grade) => {
          // querySnapshot.forEach((doc) => {
          //   grades.push({ ...doc.data(), id: doc.id });
          data = {
            ...data,
            academic_exact_title: `${grade.data().grade_name} ${
              data.academic_title
            }`,
          };
        });
      // });
      // Update every "instance" of this formation throughout campus
      try {
        let batch = firebase.firestore().batch();

        // Group search only through the selected year
        const activeFormations = firebase
          .firestore()
          .collectionGroup("formations")
          .where("formation_id", "==", content.id)
          .orderBy(firebase.firestore.FieldPath.documentId())
          .startAt(db.path)
          .endAt(db.path + "\uf8ff");

        activeFormations
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              batch.update(doc.ref, {
                ...data,
              });
            });
          })
          .then(() =>
            batch.commit().then(() => {
              console.info("toutes les formations ont été MAJ dans les campus");
              setForceReload(true);
            })
          );
      } catch (err) {
        console.log("error updating formations in campus", err);
      }
    }
    // Update classic of the document
    docRef.update(data).then(() => {
      enqueueSnackbar("Mise à jour réussie", {
        variant: "success",
      });
      // if (category === "rncp")
      setForceReload(true);
    });
  };

  useEffect(() => {
    if (category !== "campus") return;
    const formationsData = [];

    const formationsRef = db
      .collection("campus")
      .doc(content.id)
      .collection("formations");
    formationsRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          formationsData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        setFormations(formationsData);
      });
  }, [category, content.id, db]);

  useEffect(() => {
    if (!formations.length) return;
    setSelectedFormations(
      formations.map((formation) => formation.is_active && formation.id)
    );
  }, [formations]);

  return (
    <Grid container className="details-content">
      <form onSubmit={handleSubmit(onSubmit)} className="details-content-form">
        {structure.content.map((part) => {
          if (part.id === "formations") {
            if (!formations.length && !selectedFormations)
              return <div key={part.id}>Pas de formations</div>;
            return (
              <FormationsCheckboxes
                formations={formations}
                campus_id={content.id}
                selectedFormations={selectedFormations?.filter(Boolean)}
                setSelectedFormations={setSelectedFormations}
                control={control}
                handleSubmit={handleSubmit}
                setValue={setValue}
                key={part.id}
              />
            );
          }
          return (
            <Grid
              key={part.title}
              item
              xs={12}
              className="details-content-card"
            >
              <Card className="details-card">
                <CardContent className="details-card-main">
                  <CardHeader
                    title={part.title}
                    className="details-card-header"
                  />
                  <CardContent className="details-card-content">
                    <Grid container className="details-card-form">
                      {part.fields.map((field) => {
                        if (
                          field.condition &&
                          content[field.condition] !== field.conditionValue
                        ) {
                          return <></>;
                        }

                        return (
                          <Grid key={field.id} item xs={12} md={4} lg={3}>
                            <Controller
                              name={field.id}
                              control={control}
                              defaultValue={content[field.id]}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <CustomField
                                  type={field?.type}
                                  field={field}
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  content={content}
                                />
                              )}
                              rules={{
                                required: !field.unrequired
                                  ? "Champs requis"
                                  : false,
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </CardContent>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        {/* {category === "brands" && <Cfa />} */}
        <Grid container className="details-content-save">
          <Button
            onClick={() => {
              history.push(
                category === "formations_canvas"
                  ? `/schoolDetails/${formationId}`
                  : `/${category}`
              );
            }}
            variant="outlined"
            className="ad-main-button dark-color lg"
          >
            Annuler
          </Button>
          <Button
            type="submit"
            variant="outlined"
            className="ad-main-button main-color lg"
          >
            Enregistrer
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default DetailsContent;

import { MenuItem, Select } from "@material-ui/core";
import { useContext } from "react";
import { DatabaseContext } from "../../../Context/Context";

const SelectYear = () => {
  const { year, setYear } = useContext(DatabaseContext);

  return (
    <Select
      fullWidth
      color="secondary"
      value={year}
      style={{ color: "white" }}
      onClick={(e) => {
        if (e.target.value) setYear(e.target.value);
      }}
    >
      <MenuItem value="2021-2022">2021-2022</MenuItem>
      <MenuItem value="2022-2023">2022-2023</MenuItem>
      <MenuItem value="2023-2024">2023-2024</MenuItem>
    </Select>
  );
};

export default SelectYear;

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useStyles from "./style";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const CustomBreadcrumb = ({ breadcrumbPath }) => {
  const classes = useStyles();

  return (
    <div className={classes.root + " admin-breadcrum"}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
        classes={{ separator: classes.separator }}
      >
        {breadcrumbPath.path.map((el) =>
          el.href ? (
            <NavLink key={el.name} to={el.href} classes={classes.links}>
              {el.name}
            </NavLink>
          ) : (
            <Typography key={el.name} variant="subtitle2">
              {el.name}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </div>
  );
};

CustomBreadcrumb.propTypes = {
  breadcrumbPath: PropTypes.shape({
    currentElement: PropTypes.string,
    path: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default CustomBreadcrumb;

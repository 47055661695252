import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CustomTable from "../../Components/CustomTable";
import Edit from "@material-ui/icons/EditRounded";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { DatabaseContext } from "../../Context/Context";
import firebase from "../../firebase";
import { Chip } from "@material-ui/core";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";

const columns = [
  {
    title: "Logo",
    render: (rowData) => (
      <Link
        to={`/admindetails/formations/${rowData.campusRef}/${rowData.id}`}
        target="_blank"
      >
        <img
          alt={`Logo ${rowData.school_short_name}`}
          className="logo-img"
          src={`${rowData.school_logo}`}
        />
      </Link>
    ),
    align: "center",
    cellStyle: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  {
    title: "Campus",
    field: "campus_name",
    filtering: false,
    cellStyle: {
      minWidth: "180px",
    },
  },
  {
    title: "Niveau",
    field: "campus_academic_full_title",
    filtering: false,
    cellStyle: {
      minWidth: "180px",
    },
  },
  {
    title: "Libellé",
    field: "academic_title",
    filtering: false,
    cellStyle: {
      width: "100%",
      minWidth: "200px",
    },
  },
  {
    title: "Statut",
    field: "completed",
    align: "center",
    render: (rowData) => {
      return rowData.completed ? (
        <Chip
          label="Complété"
          variant="outlined"
          className="chip complete md"
        />
      ) : (
        <Chip
          label="À compléter"
          variant="outlined"
          className="chip to-complete md"
        />
      );
    },
  },
];

const breadcrumbPath = {
  path: [{ name: "Administration" }],
};

const Formations = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  // const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { db } = useContext(DatabaseContext);

  const formatData = async (result) => {
    if (!result) return [];
    const allCities = await db
      .collection("cities")
      .get()
      .then((snapshot) => {
        const citiesData = [];
        snapshot.forEach((el) => citiesData.push({ ...el.data(), id: el.id }));
        return citiesData;
      });
    const grades = [];
    let formattedData = await db
      .collection("grades")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          grades.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        return result.map((formation) => {
          let output = { ...formation };

          output.city = allCities.find(
            (el) => el.id === formation.campus_city
          )?.label;
          output.campus_name = `${output.school_abbreviation} ${output.city}`;

          output.academic_grade = grades.find(
            (grade) => grade.id === formation.academic_grade
          )?.grade_code;

          output.campus_academic_full_title = `${output.academic_grade} ${output.academic_short_title}`;
          return output;
        });
      });
    setData(formattedData);
  };
  // const formationsRef = db.collection("cfa");
  const actions = [
    {
      icon: Edit,
      tooltip: "Voir les détails de la formation",
      onClick: (_event, rowData) => {
        history.push(
          `/admindetails/formations/${rowData.campusRef}/${rowData.id}/`
        );
      },
    },
    // {
    //   icon: DeleteOutline,
    //   tooltip: "Supprimer la formation",
    //   onClick: (_event, rowData) =>
    //     history.push(`/admindetails/formations/${rowData.id}/false/true`),
    // },
  ];

  useEffect(() => {
    let formationsData = [];

    // Group search only through the selected year
    const activeFormations = firebase
      .firestore()
      .collectionGroup("formations")
      .where("is_active", "==", true)
      .orderBy(firebase.firestore.FieldPath.documentId())
      .startAt(db.path)
      .endAt(db.path + "\uf8ff");

    activeFormations
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let schoolLogo;
          const schoolRef = doc.data().school_id;
          const remoteRef = db.collection("schools").doc(schoolRef);
          remoteRef.get().then((school) => {
            if (!doc.exists) {
              console.error("Le document n'existe pas");
            }
            schoolLogo = school.data().school_logo;
            formationsData.push({
              ...doc.data(),
              id: doc.id,
              city: doc.data().campus_city?.label,
              campusRef: doc.ref.parent.parent.id,
              school_logo: schoolLogo,
              campus_name: "",
            });
          });
        });
      })
      .then(() => formatData(formationsData));

    // db.collection("campus").collection

    // db.collection("campus")
    //   .where("is_active", "==", true)
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       console.log(doc.id, " => ", doc.data());
    //       db.collection("campus")
    //         .doc(doc.id)
    //         .where("is_active", "==", true)
    //         .get();
    //     });
    //   });

    //   formationsRef
    //     .get()
    //     .then((querySnapshot) => {
    //       querySnapshot.forEach((doc) => {
    //         formationsData.push({ ...doc.data(), id: doc.id });
    //       });
    //     })
    //     .then(() => {
    //       setData(formationsData);
    //     });
  }, []);

  return (
    <Grid container className="admin-view">
      <Grid item xs={12} className="admin-view-header">
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <Typography variant="h4" className="header-title">
            Formations
          </Typography>
        </div>
        {/* <Button>Ajouter un titre RNPC</Button> */}
      </Grid>
      <Grid item xs={12} className="admin-view-table">
        {!!data.length && (
          <section className="admin-view-section">
            <h3 className="section-title">Liste des formations</h3>
            <CustomTable
              title="Liste des Formations"
              columns={columns}
              data={data}
              actions={actions}
              size={size}
              setSize={setSize}
              page={page}
              setPage={setPage}
              totalElements={totalElements}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filter={false}
              // noSearch={true}
              autoFocus={true}
            />
          </section>
        )}
      </Grid>
    </Grid>
  );
};

export default Formations;

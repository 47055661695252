import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: 10,
    },
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 50,
  },
  breadcrumbs: {
    color: "#0d1425",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.57",
  },
  separator: {
    color: "gray",
  },
  links: {
    textDecoration: "none",
    color: "rgb(55 199 148)",
  },
}));

export default useStyles;

import eachDayOfInterval from "date-fns/eachDayOfInterval";
import compareAsc from "date-fns/compareAsc";
import isSameDay from "date-fns/isSameDay";
import { subDays } from "date-fns";

const buildIntranetStats = (
  schoolSelected,
  intra,
  contracts,
  schools,
  dateRange
) => {
  const intranetResults = [];
  if (schoolSelected) {
    intranetResults.push({
      id: schoolSelected,
      data: [
        {
          x: "Enregistrés",
          y: contracts.filter(
            (contract) => contract.infos.school === schoolSelected
          ).length,
        },
        {
          x: "Intranet",
          y:
            intra.filter((el) => el["Marque"] === schoolSelected).length -
            contracts.filter(
              (contract) => contract.infos.school === schoolSelected
            ).length,
        },
      ],
    });
  } else {
    for (const school of schools) {
      intranetResults.push({
        id: school.school_short_name,
        data: [
          {
            x: "Créé",
            y: contracts
              .filter(
                (contract) => contract.infos.school === school.school_short_name
              )
              // filter by range date
              .filter((contract) => {
                if (!dateRange[0].startDate) {
                  return contract;
                }
                const contractDate = contract.infos.date
                  .split(",")[0]
                  .split("/")
                  .reverse()
                  .join("-");
                if (
                  compareAsc(
                    new Date(contractDate),
                    new Date(dateRange[0].startDate)
                  ) !== -1 &&
                  compareAsc(
                    new Date(contractDate),
                    new Date(dateRange[0].endDate)
                  ) !== 1
                ) {
                  return contract;
                } else {
                  return null;
                }
              }).length,
          },
          {
            x: "Intranet",
            y:
              intra
                .filter((el) => el["Marque"] === school.school_short_name)
                .filter((el) => {
                  const intraDate = el["Date INSCRIT"].split(" ")[0];
                  if (
                    compareAsc(
                      new Date(intraDate),
                      new Date(dateRange[0].startDate)
                    ) !== -1 &&
                    compareAsc(
                      new Date(intraDate),
                      new Date(dateRange[0].endDate)
                    ) !== 1
                  ) {
                    return el;
                  } else {
                    return null;
                  }
                }).length -
              contracts
                .filter(
                  (contract) =>
                    contract.infos.school === school.school_short_name
                )
                .filter((contract) => {
                  if (!dateRange[0].startDate) {
                    return contract;
                  }
                  const contractDate = contract.infos.date
                    .split(",")[0]
                    .split("/")
                    .reverse()
                    .join("-");
                  if (
                    compareAsc(
                      new Date(contractDate),
                      new Date(dateRange[0].startDate)
                    ) !== -1 &&
                    compareAsc(
                      new Date(contractDate),
                      new Date(dateRange[0].endDate)
                    ) !== 1
                  ) {
                    return contract;
                  } else {
                    return null;
                  }
                }).length,
          },
        ],
      });
    }
  }
  return intranetResults;
};

const buildSchoolsStats = (schools, contracts, dateRange) => {
  let schoolsResult = [];
  for (const school of schools) {
    schoolsResult.push({
      id: school.school_short_name,
      label: school.school_short_name,
      value: contracts.filter((contract) => {
        const contractDate = contract.infos.date
          .split(",")[0]
          .split("/")
          .reverse()
          .join("-");
        if (!dateRange[0].startDate) {
          return contract.infos.school === school.school_short_name;
        } else if (
          compareAsc(
            new Date(contractDate),
            new Date(dateRange[0].startDate)
          ) !== -1 &&
          compareAsc(new Date(contractDate), new Date(dateRange[0].endDate)) !==
            1
        ) {
          return contract.infos.school === school.school_short_name;
        }
      }).length,
    });
  }
  return schoolsResult;
};

const buildStatusStats = (statusList, contracts, schoolSelected, dateRange) => {
  const statusResult = [];
  for (let status of statusList) {
    statusResult.push({
      id: status,
      label: status,
      value: contracts
        .filter((el) => {
          if (!schoolSelected) return el;
          else return el.infos.school === schoolSelected;
        })
        // filter by range date
        .filter((contract) => {
          if (!dateRange[0].startDate) {
            return contract;
          }
          const contractDate = contract.infos.date
            .split(",")[0]
            .split("/")
            .reverse()
            .join("-");
          if (
            compareAsc(
              new Date(contractDate),
              new Date(dateRange[0].startDate)
            ) !== -1 &&
            compareAsc(
              new Date(contractDate),
              new Date(dateRange[0].endDate)
            ) !== 1
          ) {
            return contract;
          }
        })
        .filter((el) => el.infos.status === statusList.indexOf(status)).length,
    });
  }
  return statusResult;
};

const buildCalendarStats = (contracts, schoolSelected, dateRange) => {
  const calendarResult = [];
  let currentContracts = contracts;
  if (schoolSelected)
    currentContracts = contracts.filter(
      (el) => el.infos.school === schoolSelected
    );
  for (const contract of currentContracts) {
    const contractDate = contract.infos.date
      .split(",")[0]
      .split("/")
      .reverse()
      .join("-");
    if (
      dateRange[0].startDate &&
      (compareAsc(new Date(contractDate), new Date(dateRange[0].startDate)) ===
        -1 ||
        compareAsc(new Date(contractDate), new Date(dateRange[0].endDate)) ===
          1)
    ) {
      // The range date exclude this date
      continue;
    } else if (calendarResult.find((el) => el.day === contractDate)?.value) {
      const index = calendarResult.findIndex((el) => el.day === contractDate);
      calendarResult[index].value++;
    } else {
      calendarResult.push({
        value: 1,
        day: contractDate,
      });
    }
  }
  return calendarResult;
};

const buildCumulStats = (
  statusList,
  contracts,
  dateRange,
  schools,
  schoolSelected,
  cities
) => {
  const cumulResult = [];

  if (schoolSelected) {
    const citiesSchool =
      schools.find((school) => school.school_short_name === schoolSelected)
        ?.school_city || [];

    const currentCities = cities.filter((city) =>
      citiesSchool?.includes(city.id)
    );

    for (const city of currentCities) {
      const data = {
        cityName: city.label,
      };
      for (let status of statusList) {
        data[status] = contracts
          .filter((el) => el.infos.city === city.label)
          .filter((el) => {
            // if (!schoolSelected) return el;
            // else return el.infos.school === schoolSelected;
            return el.infos.school === schoolSelected;
          }) // filter by range date
          .filter((contract) => {
            if (!dateRange[0].startDate) {
              return contract;
            }
            const contractDate = contract.infos.date
              .split(",")[0]
              .split("/")
              .reverse()
              .join("-");
            if (
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].startDate)
              ) !== -1 &&
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].endDate)
              ) !== 1
            ) {
              return contract;
            }
          })
          .filter(
            (el) => el.infos.status === statusList.indexOf(status)
          ).length;
      }
      cumulResult.push(data);
    }
  } else {
    for (const school of schools) {
      const data = { schoolName: school.school_short_name };
      // const statusResult = [];
      for (let status of statusList) {
        data[status] = contracts
          .filter((el) => {
            // if (!schoolSelected) return el;
            // else return el.infos.school === schoolSelected;
            return el.infos.school === school.school_short_name;
          }) // filter by range date
          .filter((contract) => {
            if (!dateRange[0].startDate) {
              return contract;
            }
            const contractDate = contract.infos.date
              .split(",")[0]
              .split("/")
              .reverse()
              .join("-");
            if (
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].startDate)
              ) !== -1 &&
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].endDate)
              ) !== 1
            ) {
              return contract;
            }
          })
          .filter(
            (el) => el.infos.status === statusList.indexOf(status)
          ).length;
      }
      cumulResult.push(data);
    }
  }
  console.log("cumulResult", cumulResult);
  return cumulResult;
};

const buildCumulIntranetStats = (
  statusList,
  contracts,
  dateRange,
  schools,
  schoolSelected,
  cities,
  intra
) => {
  const cumulResult = [];

  if (schoolSelected) {
    const citiesSchool =
      schools.find((school) => school.school_short_name === schoolSelected)
        ?.school_city || [];

    const currentCities = cities.filter((city) =>
      citiesSchool?.includes(city.id)
    );

    for (const city of currentCities) {
      const data = {
        cityName: city.label,
      };
      let cityCode = [city.city_code];
      if (schoolSelected === "ECV") {
        if (city.city_code === "PA") {
          cityCode.push("BU", "DA", "PE");
        } else if (city.city_code === "BO") {
          cityCode.push("BAF");
        } else if (city.city_code === "LI") {
          cityCode.push("MO");
        }
      }
      data["Créés"] = contracts
        .filter((contract) => contract.infos.school === schoolSelected)
        .filter((contract) => contract.infos.city === city.label)
        // filter by range date
        .filter((contract) => {
          if (!dateRange[0].startDate) {
            return contract;
          }
          const contractDate = contract.infos.date
            .split(",")[0]
            .split("/")
            .reverse()
            .join("-");
          if (
            compareAsc(
              new Date(contractDate),
              new Date(dateRange[0].startDate)
            ) !== -1 &&
            compareAsc(
              new Date(contractDate),
              new Date(dateRange[0].endDate)
            ) !== 1
          ) {
            return contract;
          } else {
            return null;
          }
        }).length;
      data["Intranet"] =
        intra
          .filter((el) => el["Marque"] === schoolSelected)
          .filter((el) =>
            cityCode.includes(
              el["Campus"].split("_")[el["Campus"].split("_").length - 1]
            )
          )
          .filter((el) => {
            const intraDate = el["Date INSCRIT"].split(" ")[0];
            if (
              compareAsc(
                new Date(intraDate),
                new Date(dateRange[0].startDate)
              ) !== -1 &&
              compareAsc(
                new Date(intraDate),
                new Date(dateRange[0].endDate)
              ) !== 1
            ) {
              return el;
            } else {
              return null;
            }
          }).length -
        contracts
          .filter((contract) => contract.infos.school === schoolSelected)
          .filter((contract) => contract.infos.city === city.label)
          .filter((contract) => {
            if (!dateRange[0].startDate) {
              return contract;
            }
            const contractDate = contract.infos.date
              .split(",")[0]
              .split("/")
              .reverse()
              .join("-");
            if (
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].startDate)
              ) !== -1 &&
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].endDate)
              ) !== 1
            ) {
              return contract;
            } else {
              return null;
            }
          }).length;
      cumulResult.push(data);
    }
  } else {
    for (const school of schools) {
      const data = { schoolName: school.school_short_name };
      data["Créés"] = contracts
        .filter(
          (contract) => contract.infos.school === school.school_short_name
        )
        // filter by range date
        .filter((contract) => {
          if (!dateRange[0].startDate) {
            return contract;
          }
          const contractDate = contract.infos.date
            .split(",")[0]
            .split("/")
            .reverse()
            .join("-");
          if (
            compareAsc(
              new Date(contractDate),
              new Date(dateRange[0].startDate)
            ) !== -1 &&
            compareAsc(
              new Date(contractDate),
              new Date(dateRange[0].endDate)
            ) !== 1
          ) {
            return contract;
          } else {
            return null;
          }
        }).length;

      data["Intranet"] =
        intra
          .filter((el) => el["Marque"] === school.school_short_name)
          .filter((el) => {
            const intraDate = el["Date INSCRIT"].split(" ")[0];
            if (
              compareAsc(
                new Date(intraDate),
                new Date(dateRange[0].startDate)
              ) !== -1 &&
              compareAsc(
                new Date(intraDate),
                new Date(dateRange[0].endDate)
              ) !== 1
            ) {
              return el;
            } else {
              return null;
            }
          }).length -
        contracts
          .filter(
            (contract) => contract.infos.school === school.school_short_name
          )
          .filter((contract) => {
            if (!dateRange[0].startDate) {
              return contract;
            }
            const contractDate = contract.infos.date
              .split(",")[0]
              .split("/")
              .reverse()
              .join("-");
            if (
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].startDate)
              ) !== -1 &&
              compareAsc(
                new Date(contractDate),
                new Date(dateRange[0].endDate)
              ) !== 1
            ) {
              return contract;
            } else {
              return null;
            }
          }).length;

      cumulResult.push(data);
    }
  }
  return cumulResult;
};

const buildLineStats = (contracts, dateRange, schools, schoolSelected) => {
  const lineResult = [];
  const interval = {
    start: dateRange[0].startDate
      ? new Date(dateRange[0].startDate)
      : new Date(2022, 4, 1), // 1st of May
    end: dateRange[0].endDate ? new Date(dateRange[0].endDate) : new Date(),
  };
  const eachDay = eachDayOfInterval(interval);

  let currentContracts = contracts;
  if (schoolSelected)
    currentContracts = contracts.filter(
      (el) => el.infos.school === schoolSelected
    );

  for (const school of schools) {
    const data = [];
    for (const [index, day] of eachDay.entries()) {
      data.push({
        x: day.toLocaleDateString("fr"),
        y:
          currentContracts
            .filter(
              (contract) => contract.infos.school === school.school_short_name
            )
            .filter((contract) => {
              const contractDate = contract.infos.date
                .split(",")[0]
                .split("/")
                .reverse()
                .join("-");
              if (isSameDay(new Date(contractDate), day)) {
                return contract;
              } else {
                return false;
              }
            }).length + (index === 0 ? 0 : data[index - 1].y),
      });
    }

    lineResult.push({
      id: school.school_short_name,
      data: data,
    });
  }

  return lineResult;
};

const buildTilesStats = (contracts, intra, schoolSelected) => {
  const data = {};
  if (schoolSelected) {
    contracts = contracts.filter(
      (contract) => contract.infos.school === schoolSelected
    );
    intra = intra.filter((el) => el["Marque"] === schoolSelected);
  }

  data.contracts = {
    title: "Nombre de fiches",
    statNumber: contracts.length,
    variation: parseFloat(
      (contracts.filter((contract) => {
        const contractDate = contract.infos.date
          .split(",")[0]
          .split("/")
          .reverse()
          .join("-");
        const weekAgo = subDays(new Date(), 7);
        if (compareAsc(new Date(contractDate), weekAgo) >= 0) return contract;
      }).length /
        contracts.filter((contract) => {
          const contractDate = contract.infos.date
            .split(",")[0]
            .split("/")
            .reverse()
            .join("-");
          const weekAgo = subDays(new Date(), 7);
          if (compareAsc(new Date(contractDate), weekAgo) === -1)
            return contract;
        }).length) *
        100
    ).toFixed(2),
  };

  data.apprentice = {
    title: "Nombre d'alternants",
    statNumber: intra.length,
    variation: parseFloat(
      (intra.filter((el) => {
        const weekAgo = subDays(new Date(), 7);
        if (compareAsc(new Date(el["Date INSCRIT"]), weekAgo) >= 0) {
          return el;
        }
      }).length /
        intra.filter((el) => {
          const weekAgo = subDays(new Date(), 7);
          if (compareAsc(new Date(el["Date INSCRIT"]), weekAgo) === -1) {
            return el;
          }
        }).length) *
        100
    ).toFixed(2),
  };

  data.signedContracts = {
    title: "Contrats signés",
    statNumber: contracts.filter((el) => el.infos.status === 4).length,
  };

  data.waitingContracts = {
    title: "Fiches en attentes",
    statNumber: contracts.filter((el) => el.infos.status < 3).length,
  };
  return data;
};

export {
  buildIntranetStats,
  buildSchoolsStats,
  buildStatusStats,
  buildCalendarStats,
  buildLineStats,
  buildCumulStats,
  buildCumulIntranetStats,
  buildTilesStats,
};

import PropTypes from "prop-types";
import { ResponsiveCalendar } from "@nivo/calendar";

const Calendar = ({ title, data, fromDate, toDate }) => {
  return (
    <>
      <h3 style={{ marginBottom: -70, marginLeft: 30 }}>{title}</h3>
      <ResponsiveCalendar
        data={data}
        from={fromDate || "2022-01-01"}
        to={toDate || "2022-12-31"}
        emptyColor="#eeeeee"
        colors={["#97e3d5", "#61cdbb", "#e8c1a0", "#f47560"]}
        margin={{ top: 120, right: 40, bottom: 0, left: 40 }}
        yearSpacing={40}
        monthBorderWidth={5}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        monthLegend={(_y, month) => {
          switch (month) {
            case 0:
              return "Jan";
            case 1:
              return "Fév";
            case 2:
              return "Mar";
            case 3:
              return "Avr";
            case 4:
              return "Mai";
            case 5:
              return "Juin";
            case 6:
              return "Juil";
            case 7:
              return "Août";
            case 8:
              return "Sep";
            case 9:
              return "Oct";
            case 10:
              return "Nov";
            case 11:
              return "Déc";

            default:
              return "?";
          }
        }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
      />
    </>
  );
};

Calendar.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      day: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Calendar;

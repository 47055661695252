import React from "react";
import PropTypes from "prop-types";
import {
  // BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AdminMenu from "./Menu/AdminMenu";
import ManageContracts from "./Dashboard/RE/Contracts/ManageContracts";
import ManageUsers from "./Dashboard/Administration/ManageUsers/ManageUsers";

import "./admin.scss";

const Admin = ({ user, perms, uid }) => {
  const mainPerm = user.main_perm;

  return (
    // <div className="admin-interface">
    // {/* <AdminMenu user={user} perms={perms} /> */}
    <div className="admin-dashboard">
      <div className="ctn-dashboard">
        <Switch>
          {(mainPerm <= 1 || mainPerm === 2) && (
            <Route
              exact
              path="/contracts"
              render={(props) => (
                <ManageContracts
                  {...props}
                  user={user}
                  perms={perms}
                  uid={uid}
                />
              )}
            />
          )}
          {mainPerm === 2 && (
            <Route exact path="/users" component={ManageUsers} />
          )}
          {mainPerm <= 0 && <Redirect to="/contracts" />}
        </Switch>
      </div>
    </div>
    // </div>
  );
};

Admin.propTypes = {
  user: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.number),
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    main_perm: PropTypes.number,
    picture: PropTypes.string,
    school_perms: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  perms: PropTypes.shape({
    city_perms: PropTypes.arrayOf(PropTypes.string),
    main_perm: PropTypes.arrayOf(PropTypes.string),
    school_perms: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  uid: PropTypes.string.isRequired,
};
export default Admin;

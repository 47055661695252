import { Grid, Typography, IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AddForm from "../../Components/Admin/AddForm";
import CustomTable from "../../Components/CustomTable";
import { DatabaseContext } from "../../Context/Context";
import { detailsTableRef } from "./utils";
import firebase from "../../firebase";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

const TableRefDetails = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { category } = match.params;
  const { db } = useContext(DatabaseContext);

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [cities, setCities] = useState([]);
  const [schools, setSchools] = useState([]);

  const { title, subtitle, fields, columns } = detailsTableRef[category];

  const collectionRef = db.collection(category);

  useEffect(() => {
    if (
      category === "schools" &&
      !columns.some((col) => col.title === "Logo")
    ) {
      columns.unshift({
        title: "Logo",
        render: (rowData) => (
          <img
            alt={`Logo ${rowData.school_short_name}`}
            className="logo-img"
            src={`${rowData.school_logo}`}
          />
        ),
      });
    }

    let collectionData = [];

    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          collectionData.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      })
      .then(() => {
        // collectionData = {
        //   ...collectionData,
        //   // school_logo: <img src={collectionData.logo} alt="logo" />,
        // };
        setData(collectionData);
      });
  }, [category]);

  useEffect(() => {
    if (category !== "schools") return;
    const citiesData = [];
    const citiesRef = db.collection("cities");
    citiesRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          citiesData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setCities(citiesData));
  }, [category]);

  useEffect(() => {
    if (category !== "cities") return;
    const schoolsData = [];
    const schoolsRef = db.collection("schools");
    schoolsRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          schoolsData.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setSchools(schoolsData));
  }, [category]);

  const createCampusWithNewCity = async (city_id) => {
    try {
      let batch = firebase.firestore().batch();
      let schoolLocalRef;
      for (let school of schools) {
        schoolLocalRef = db.collection("campus").doc();
        await batch.set(
          schoolLocalRef,
          {
            city: city_id,
            school_name: school.school_full_name,
            school_abbreviation: school.school_short_name,
            school_logo: school.school_logo,
            school_id: school.id,
            is_active: false,
          },
          { merge: true }
        );
      }
      batch.commit().then(() => {
        enqueueSnackbar("Les campus ont été créés pour la nouvelle ville", {
          variant: "success",
        });
      });
    } catch (err) {
      console.log("error creating campus", err);
    }
  };

  const createCampus = async (schoolData, school_id) => {
    try {
      let batch = firebase.firestore().batch();
      let schoolLocalRef;
      for (let city of cities) {
        schoolLocalRef = db.collection("campus").doc();
        await batch.set(schoolLocalRef, {
          city: city.id,
          school_name: schoolData.school_full_name,
          school_abbreviation: schoolData.school_short_name,
          school_logo: schoolData.school_logo,
          school_id: school_id,
          is_active: false,
        });
      }
      batch.commit().then(() => {
        enqueueSnackbar("Les campus ont été créés", {
          variant: "success",
        });
      });
    } catch (err) {
      console.log("error creating campus", err);
    }
  };

  const handleAdd = (data) => {
    let collectionData = [];
    if (category === "schools") {
      data = { ...data, nbr_formations: 0 };
    }
    collectionRef.add(data).then((docRef) => {
      enqueueSnackbar("Document enregistré", {
        variant: "success",
      });
      if (category === "schools") {
        createCampus(data, docRef.id);
      }
      if (category === "cities") {
        createCampusWithNewCity(docRef.id);
      }
      setShow(false);
      collectionRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            collectionData.push({ ...doc.data(), id: doc.id });
          });
        })
        .then(() => {
          setData(collectionData);
        });
    });
  };

  const breadcrumbPath = {
    path: [
      { name: "Paramètres" },
      { name: "Tables de références", href: "/ref-tables" },
    ],
    currentElement: "",
  };

  return (
    <Grid container className="admin-view">
      <Grid item xs={12} className={`admin-view-header ${show && "adding"}`}>
        <div className="admin-infos">
          <CustomBreadcrumb breadcrumbPath={breadcrumbPath} />
          <div className="admin-infos-return">
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                history.push("/ref-tables");
              }}
              className="ad-stroke-button dark-color icon-button"
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <Typography variant="h4" className="header-title">
              {title}
            </Typography>
          </div>
        </div>
        <AddForm
          fields={fields}
          onSubmit={handleAdd}
          show={show}
          setShow={setShow}
        />
      </Grid>
      <Grid item xs={12} className="admin-view-table">
        {!!data.length && (
          <section className="admin-view-section">
            <h3 className="section-title">{subtitle}</h3>
            <CustomTable
              title="Liste"
              columns={columns}
              data={data}
              size={size}
              setSize={setSize}
              page={page}
              setPage={setPage}
              totalElements={totalElements}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filter={false}
              // noSearch={true}
              autoFocus={true}
              editable
              collectionRef={collectionRef}
              setData={setData}
            />
          </section>
        )}
      </Grid>
    </Grid>
  );
};

export default TableRefDetails;

import { ResponsiveBar } from "@nivo/bar";
import { statusList } from "./utils";

const CumulativeLine = ({
  data,
  schools,
  contracts,
  schoolSelected,
  isIntra,
  title,
}) => {
  return (
    <>
      <h3 style={{ marginBottom: -70, marginLeft: 30 }}>{title}</h3>
      <ResponsiveBar
        data={data}
        keys={isIntra ? ["Créés", "Intranet"] : statusList}
        indexBy={schoolSelected ? "cityName" : "schoolName"}
        margin={{ top: 100, right: 130, bottom: 50, left: 100 }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={
          isIntra
            ? { scheme: "pastel1" }
            : [
                "#c5c7df",
                "hsl(29, 90%, 88%)",
                "#d5defd",
                "#f3c6fc",
                "#beffd7",
                "#fff0c7",
                "#ffd3d3",
              ]
        }
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Contrats",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          //   legend: schoolSelected ? "Villes" : "Écoles",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        // label={(d) => {
        //   console.log("d ?", d);
        //   return `${d.id}: ${d.value}`;
        // }}
        enableGridX
        enableGridY={false}
        tooltip={(e) => {
          const numberContracts = schoolSelected
            ? contracts
                .filter((el) => el.infos.city === e.indexValue)
                .filter((el) => el.infos.school === schoolSelected).length
            : contracts.filter((el) => el.infos.school === e.indexValue).length;
          let result = `${parseFloat((e.value / numberContracts) * 100).toFixed(
            2
          )}% des contrats ${e.indexValue}`;

          if (isIntra) {
            result = `
              ${parseFloat(
                (e.value /
                  parseFloat(
                    (e.data["Créés"] || 0) + parseInt(e.data["Intranet"] || 0)
                  ).toFixed(2)) *
                  100
              ).toFixed(2)}% ${e.id === "Intranet" ? "restant" : "traité"}
              `;
          }

          return (
            <div
              style={{
                backgroundColor: "white",
                padding: 10,
                border: `2px solid ${e.color}`,
                borderRadius: "3px",
              }}
            >
              <div>
                {e.label} ({e.value})
              </div>
              <div>{result}</div>
              {/* <div>
                {parseInt(
                  (e.value /
                    contracts.filter(
                      (el) => el.infos.status === statusList.indexOf(e.id)
                    ).length) *
                    100
                )}
                % des contrats {e.id}
              </div>
              <div>
                {parseInt((e.value / contracts.length) * 100)}% des contrats
                totaux
              </div> */}
            </div>
          );
        }}
      />
    </>
  );
};

export default CumulativeLine;

import { TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutoCompleteUsers = ({
  selectedUsers,
  selectedUser,
  setSelectedUser,
  students,
}) => {
  const getColor = (option) => {
    if (students.find((el) => el.biel_id === option["ID_BIEL_ETU"]))
      return "green";
    else if (
      students.find(
        (el) =>
          el.general.apprentice_first_name.value === option["Prénom"] &&
          el.general.apprentice_last_name.value === option["Nom"]
      )
    ) {
      return "orange";
    } else {
      return "red";
    }
  };

  return (
    <Autocomplete
      fullWidth
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
      options={selectedUsers}
      value={selectedUser}
      onChange={(event, newValue) => {
        console.log(newValue);
        setSelectedUser(newValue);
      }}
      renderOption={(option) => (
        <Typography
          style={
            {
              // color: option["Statut Candidature"] === "INSCRIT" ? "green" : "red",
            }
          }
        >
          <span
            style={{
              height: "15px",
              width: "15px",
              // marginBottom: 5,
              marginRight: 10,
              marginTop: 5,
              backgroundColor: getColor(option),
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          {`${option["Prénom"]} ${option["Nom"]} - ${option["Email"]}`}
        </Typography>
      )}
      getOptionLabel={(option) =>
        option
          ? `${option["Prénom"]} ${option["Nom"]} - ${option["Email"]}`
          : ""
      }
      renderInput={(params) => (
        <TextField {...params} label="Élèves enregistrés" variant="outlined" />
      )}
    />
  );
};

export default AutoCompleteUsers;

import npec from "./NPEC.json";

const fetchSiretApis = async (url) => {
  try {
    const response = await fetch(url);
    const json = await response.json();
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return json;
  } catch (error) {
    console.log(error);
  }
};

const computeIdccCost = async (siret, diplomaCode, idccCode) => {
  let idccNum;
  if (!idccCode) {
    const idcc = await fetchSiretApis(
      `https://siret2idcc.fabrique.social.gouv.fr/api/v2/${siret}`
    );
    const lastIdcc = idcc[0].conventions.length - 1;
    if (!idcc[0].conventions[lastIdcc]) return;

    idccNum = idcc[0].conventions[lastIdcc].num;
  }
  // Get supportedCost value from diploma code and idcc number
  let diplomaMatch = npec.filter((el) => el.diploma_code === diplomaCode);
  let supportedCost = 0;
  diplomaMatch.forEach((el) => {
    if (el.IDCC?.includes(parseInt(idccCode) || parseInt(idccNum) || 0)) {
      supportedCost = el.final_cost;
    }
  });

  console.log("diploma", diplomaCode);
  console.log("supportedCost before", supportedCost);
  // Apply default diploma value if nothing else matches
  if (supportedCost === 0) {
    supportedCost = diplomaMatch.find((el) => el.default).final_cost;
  }

  console.log("supportedCost", supportedCost);

  return supportedCost;
};

const updatePriceInfo = async (supportedCost, gradeRef, cfa, slug, db) => {
  const updatedFormation = {
    "cost_info.opco_charge_1.value": supportedCost,
    "cost_info.opco_charge_2.hide":
      parseInt(gradeRef?.nbr_year) >= 2 ? false : true,
    "cost_info.opco_charge_2.value":
      parseInt(gradeRef?.nbr_year) >= 2 ? supportedCost : 0,
    "cost_info.price_difference_1.value":
      parseInt(cfa.cost_info.cost_year_1.value) - supportedCost >= 0
        ? parseInt(cfa.cost_info.cost_year_1.value) - supportedCost
        : 0,
    "cost_info.price_difference_2.hide":
      parseInt(gradeRef?.nbr_year) >= 2 ? false : true,
    "cost_info.price_difference_2.value":
      parseInt(gradeRef?.nbr_year) >= 2
        ? parseInt(cfa.cost_info.cost_year_2.value) - supportedCost >= 0
          ? parseInt(cfa.cost_info.cost_year_2.value) - supportedCost
          : 0
        : 0,
  };

  await db.collection("formations").doc(slug).update(updatedFormation);
};

export { computeIdccCost, fetchSiretApis, updatePriceInfo };

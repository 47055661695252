import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { buildTilesStats } from "./statsBuilder";
import Tile from "./Tile";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    marginLeft: -2,
  },
  root: {
    flexGrow: 1,
    display: "flex",
  },
  paper: {
    height: 140,
    // margin: 10,
    width: "100%",
  },
  title: {
    fontWeight: 600,
    fontSize: 15,
    color: "#00000073",
  },
  arrow: {
    width: 15,
    paddingTop: 10,
    fontWeight: 800,
  },
  subtitle: {
    fontSize: 10,
  },
  variationContainer: {
    fontWeight: 800,
    fontSize: 15,
    marginTop: -7,
    color: "#45c445",
  },
}));

const InfoTiles = ({ contracts, intra, schoolSelected }) => {
  const classes = useStyles();
  const [stats, setStats] = useState({});

  useEffect(() => {
    if (!contracts || !intra) return;
    setStats(buildTilesStats(contracts, intra, schoolSelected));
  }, [contracts, intra, schoolSelected]);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} sm={6} md={3} className={classes.root}>
        <Tile classes={classes} stats={stats.contracts} />
      </Grid>
      <Grid item xs={12} sm={6} md={3} className={classes.root}>
        <Tile classes={classes} stats={stats.apprentice} />
      </Grid>
      <Grid item xs={12} sm={6} md={3} className={classes.root}>
        <Tile classes={classes} stats={stats.signedContracts} />
      </Grid>
      <Grid item xs={12} sm={6} md={3} className={classes.root}>
        <Tile classes={classes} last stats={stats.waitingContracts} />
      </Grid>
    </Grid>
  );
};

export default InfoTiles;
